import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import {fetchFaq,createEditFAQ} from "../../redux/faq/faq-action";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import { green } from "@material-ui/core/colors";
import { Capitalize} from "../../util/helper";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import CreateQuestion from './create-question';
import CreateVideo from './samle-video';
let data = {
  skip: 0,
  limit: 10,
  keyword: "",
};
let updateStatusFaq ={
  questionId: "",
  status: "",
};
const FaqQuestion=()=>{
  const [page, setPage] = useState(1);
  const [rowCount, setRowCount] = useState(10);
  const [questionModal, setQuestionModal] = useState(false);
  const [editQuestion, setEditQuestion] = useState(false);
  const [editQuestionData, setEditQuestionData] = useState({});
  const [dialogue, setOpen] = useState({ open: false, type: "" });
  const questionData = useSelector((state) => state.createEditFaqReducer.data);
  const [videoModal, setVideoModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editData, setEditData] = useState({});
  const { open, type } = dialogue;
  const dispatch = useDispatch();
  const faq = useSelector((state) => state.createEditFaqVideoReducer.data);
  const handleRowsPerPage=(event)=>{
  setRowCount(event.target.value);
    setPage(1);
    data.limit = event.target.value;
    data.skip = 0;
    dispatch(fetchFaq(data));
  }
  const handlePageChange=(event,newPage)=>{
  setPage(newPage);
  data.skip = (newPage - 1) * rowCount;
  dispatch(fetchFaq(data));
  }
  const searchFaq=(event)=>{
    event.persist();
    if (event.target.value === "") {
      return;
    }
    data.skip = 0;
    data.keyword = event.target.value;
    dispatch(fetchFaq(data));
  }
  const handleEditFaq = (row) => {
    setEditQuestion(true);
    setEditQuestionData(row);
    setQuestionModal(true);
  };
  const updateStatus=(data,type)=>{
    updateStatusFaq.questionId = data._id;
    if(type === 'published'){
      updateStatusFaq.status = 'published';
    }
    else{
      updateStatusFaq.status = 'draft';
    }
    setOpen({open:true,type:type});
  }
  const handleAgree=(e)=>{
    e.preventDefault();
    setOpen({open:false});
    dispatch(createEditFAQ(updateStatusFaq));
    dispatch(fetchFaq(data));
  }
  const handleClose=(e)=>{
    e.preventDefault();
    setOpen({open:false});
  }
  const closeQuestionModalHandler = () => {
    setQuestionModal(false);
  }
  const createQuestionHandler = () => {
    setEditQuestion(false);
    setQuestionModal(true);
  }
  useEffect(()=>{
  dispatch(fetchFaq(data));
  },[fetchFaq, questionData])// eslint-disable-line react-hooks/exhaustive-deps
  const closeVideoHandler = () => {
    setVideoModal(false);
  }
  const createSampleVideo = (faqData) => {
    if(faqData){
      setEdit(true);
    } else {
      setEdit(false);
    }
    if(faqData){
      setEditData(faqData);
    }
    setVideoModal(true);
  }
  useEffect(()=>{
  dispatch(fetchFaq(data));
  }, [fetchFaq, faq])// eslint-disable-line react-hooks/exhaustive-deps
  const faqData = useSelector((state) => state.faqReducer.data);
  const createEditFaq = useSelector((state)=> state.createEditFaqReducer.data);
  useEffect(()=>{
  dispatch(fetchFaq(data));
  },[createEditFaq])// eslint-disable-line react-hooks/exhaustive-deps
  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>FAQ questions</h1>
            <div className="separator mb-5"></div>
            <div className="file-upload mb-3 ml-2">
              <div className="row">
                <div className="col">
                  {
                    faqData.vimeoId &&
                    (
                      <button
                        className="btn btn-outline-primary mr-3"
                        type="button"
                        onClick={createQuestionHandler}
                      >
                        CREATE QUESTION
                      </button>
                    )
                  }
                  {
                    questionModal ? (
                      <CreateQuestion
                        open={questionModal}
                        edit={editQuestion}
                        data={editQuestionData}
                        onClose={closeQuestionModalHandler}
                      />
                    ) : null
                  }
                  <button
                    className="btn btn-outline-primary"
                    type="button"
                    onClick={() => createSampleVideo(faqData)}
                  >
                    CREATE FAQ VIDEO
                </button>
                  {
                    videoModal ? (
                      <CreateVideo
                        open={videoModal}
                        edit={edit}
                        data={editData}
                        onClose={closeVideoHandler}
                      />
                    ) : null
                  }
                </div>
              </div>
            </div>
            <div className="separator mb-5"></div>
            <div className="tableBody">
              <div className="table-filter-div">
                <Grid item xs={6}>
                  <div className="search-div">
                    <input
                      type="text"
                      className="search"
                      onKeyUp={searchFaq}
                      placeholder="Search faqs.."
                    ></input>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="rowCount-div">
                    <label>FAQ per page : </label>
                    <select className="selection" onChange={handleRowsPerPage}>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </Grid>
              </div>
              <Paper className="paperBody">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="tableHead" align="center">
                          {" "}
                          Sl.No{" "}
                        </TableCell>
                        <TableCell className="tableHead">Questions</TableCell>
                        <TableCell className="tableHead">Answers</TableCell>
                        <TableCell className="tableHead">Status</TableCell>
                        <TableCell className="tableHead">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {faqData &&
                        faqData.questions &&
                        faqData.questions.map((row, index) => {
                          return (
                            <TableRow hover key={row._id}>
                              <TableCell
                                className="tableColData"
                                align="center"
                              >
                                {rowCount * (page - 1) + index + 1}
                              </TableCell>
                              <TableCell className="tableColData">
                                {row.title}
                              </TableCell>
                              <TableCell className="tableColData">
                                {row.answer}
                              </TableCell>
                              <TableCell className="tableColData">
                                {Capitalize(row.status)}
                              </TableCell>
                              <TableCell className="tableColData">
                                <span className="mr-1">
                                  <EditIcon
                                    titleAccess="Edit"
                                    aria-label="edit"
                                    fontSize="small"
                                    onClick={() => handleEditFaq(row)}
                                  />
                                </span>
                                {row.status === "draft" ? (
                                  <span>
                                    <DoneIcon
                                      titleAccess="Publish"
                                      onClick={() =>
                                        updateStatus(row, "published")
                                      }
                                      style={{ color: green[500] }}
                                      aria-label="publish"
                                      fontSize="small"
                                    />
                                  </span>
                                ) : (
                                  <span>
                                    <CloseIcon
                                      className="text-danger"
                                      titleAccess="Draft"
                                      onClick={() => updateStatus(row, "draft")}
                                      aria-label="draft"
                                      fontSize="small"
                                    />
                                  </span>
                                )}
                                <span className="mr-1"></span>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className="paginationLabel">
                  Showing {(page - 1) * rowCount + 1} to{" "}
                  {(page - 1) * rowCount +
                    (faqData && faqData.questions && faqData.questions.length
                      ? faqData.questions.length
                      : 0)}{" "}
                  of{" "}
                  {faqData && faqData.totalQuestions
                    ? faqData.totalQuestions
                    : 0}{" "}
                  entries
                </div>
                <div className="root">
                  <Pagination
                    count={
                      faqData && faqData.totalQuestions
                        ? Math.ceil(faqData.totalQuestions / rowCount)
                        : 0
                    }
                    page={page}
                    onChange={handlePageChange}
                    variant="outlined"
                  />
                </div>
              </Paper>
            </div>
            <Dialog open={open}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
             <DialogTitle id="alert-dialog-title">{`Do you want to ${type} this FAQ question ?`}</DialogTitle>
              <DialogActions>
                <Button onClick={(e)=>handleClose(e)} color="secondary">
                  Close
                </Button>
                <Button onClick={(e)=>handleAgree(e)} color="primary">
                                    {type === "published" ? "PUBLISH" : "DRAFT"}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    </main>
  );

}
export default FaqQuestion;
