import React, { useEffect, useState } from "react";
import {
    fetchMentorships,
    createMentorship
} from "../../redux/mentorship/mentorship-action";
import propTypes from "prop-types";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Pagination from "@material-ui/lab/Pagination";
import { Capitalize } from "../../util/helper";
import CreateMentorship from "./create-mentorship";
import { green } from "@material-ui/core/colors";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
let data = {
    skip: 0,
    limit: 10,
    keyword: "",
};
let updateReqData = {
    _id: "",
    status: "",
  };
const Mentorship = ({ 
    mentorshipResult, 
    fetchMentorships, 
    createResult,
    updateMentorshipStatus
    }) => {
    const [page, setPage] = useState(1);
    const [rowCount, setRowCount] = useState(10);
    const [mentorshipModal, setMentorshipModal] = useState(false);
    const [editData, setEditData] = useState({});
    const [edit, setEdit] = useState(false);
    const [dialogue, setOpen] = useState({ open: false, type: "" });
    const { open, type } = dialogue;
    useEffect(() => {
        fetchMentorships(data);
    }, [createResult]); // eslint-disable-line react-hooks/exhaustive-deps
    const handleRowsPerPage = (event) => {
        setRowCount(event.target.value);
        setPage(1);
        data.limit = event.target.value;
        data.skip = 0;
        fetchMentorships(data);
    };
    const searchMentorship = (event) => {
        data.skip = 0;
        data.keyword = event.target.value;
        setPage(1);
        fetchMentorships(data);
    };
    const handlePageChange = (event, newPage) => {
        setPage(newPage);
        data.skip = (newPage - 1) * rowCount;
        fetchMentorships(data);
    };
    const createMentorshipProgram =() => {
        setEdit(false);
        setMentorshipModal(true)
    }
    const handleEditMentorship = (row) => {
        setEdit(true);
        setEditData(row);
        setMentorshipModal(true);
    }
    const closeMentorshipHandler = () => {
        setMentorshipModal(false);
    }
    const handleClose = () => {
        setOpen({ open: false });
      };
    
    const handleAgree = () => {
    setOpen({ open: false });
        updateMentorshipStatus(updateReqData);
    };
    const handleUpdateMentorship = (row, type) => {
        updateReqData._id = row._id;
        if (type === "draft") {
                updateReqData.status = "draft";
            }
        else{
            updateReqData.status = "published"
        }
        setOpen({ open: true, type: type });
    };
    return (
        <main>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <h1>Mentorships</h1>
                        <nav
                            className="breadcrumb-container d-none d-sm-block d-lg-inline-block"
                            aria-label="breadcrumb"
                        >
                            <ol className="breadcrumb pt-0">
                                <li className="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                            </ol>
                        </nav>
                        <div className="separator mb-5"></div>
                        <div className="file-upload mb-3 ml-2">
                            <button
                                className="btn btn-outline-primary"
                                type="button"
                                onClick={createMentorshipProgram}
                            >
                                CREATE MENTORSHIP
              </button>
                            {
                                mentorshipModal ? (
                                    <CreateMentorship
                                    open={mentorshipModal}
                                    edit={edit}
                                    data={editData}
                                    onClose={closeMentorshipHandler}
                                    />
                                ) : null
                            }

                        </div>
                        <div className="tableBody">
                            <div className="table-filter-div">
                                <Grid item xs={6}>
                                    <div className="search-div">
                                        <input
                                            type="text"
                                            className="search"
                                            onKeyUp={searchMentorship}
                                            placeholder="Search mentorship..."
                                        ></input>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="rowCount-div">
                                        <label>Mentorships per page : </label>
                                        <select className="selection" onChange={handleRowsPerPage}>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </Grid>
                            </div>
                            <Paper className="paperBody">
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="tableHead" align="center">Sl.No</TableCell>
                                                <TableCell className="tableHead">Name</TableCell>
                                                <TableCell className="tableHead">Description</TableCell>
                                                <TableCell className="tableHead">Duration</TableCell>
                                                <TableCell className="tableHead">Actual price</TableCell>
                                                <TableCell className="tableHead">Offer Price</TableCell>
                                                <TableCell className="tableHead">Vimeo Id</TableCell>
                                                <TableCell className="tableHead">Status</TableCell>
                                                <TableCell className="tableHead">Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                mentorshipResult &&
                                                mentorshipResult.mentorships &&
                                                mentorshipResult.mentorships.map((row, index) => {
                                                    return (
                                                        <TableRow hover key={row._id}>
                                                            <TableCell
                                                                className="tableColData"
                                                                align="center"
                                                            >
                                                                {rowCount * (page - 1) + index + 1}                                                               
                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                {Capitalize(row.name)}
                                                            </TableCell>
                                                            <TableCell style={{maxWidth:300}} className="tableColData">
                                                                {row.description}
                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                {row.duration}{" "}days
                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                {row.actualPrice}
                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                {row.offerPrice}
                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                {row.vimeoId}
                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                {row.status}
                                                            </TableCell>
                                                            <TableCell className="tableColDate">
                                                            <EditIcon
                                                                    aria-label="edit"
                                                                    titleAccess="Edit"
                                                                    fontSize="small"
                                                                    data={row}
                                                                    edit="true"
                                                                    onClick={() => handleEditMentorship(row)}
                                                                />
                                    <span className="ml-3">
                                    {row.status === "published" ? (
                                                                        <CloseIcon
                                        titleAccess="Deactivate"
                                        color="secondary"
                                        aria-label="deactivate"
                                        fontSize="small"
                                        onClick={() =>
                                            handleUpdateMentorship(row, "draft")
                                        }
                                      />
                                    ) : (
                                      <DoneIcon
                                        titleAccess="Publish"
                                        style={{ color: green[500] }}
                                        aria-label="publish"
                                        fontSize="small"
                                        onClick={() =>
                                            handleUpdateMentorship(row, "publish")
                                        }
                                      />
                                    )}
                                  </span>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <div className="paginationLabel">
                                    Showing {(page - 1) * rowCount + 1} to{" "}
                                    {(page - 1) * rowCount +
                                        (mentorshipResult && mentorshipResult.mentorships
                                            ? mentorshipResult.mentorships.length
                                            : 0)}{" "}
                  of{" "}
                                    {mentorshipResult && mentorshipResult.totalMentorshipPrograms
                                        ? mentorshipResult.totalMentorshipPrograms
                                        : 0}{" "}
                  entries
                </div>
                                <div className="root">
                                    <Pagination
                                        count={
                                            mentorshipResult && mentorshipResult.totalMentorshipPrograms
                                                ? Math.ceil(mentorshipResult.totalMentorshipPrograms / rowCount)
                                                : 0
                                        }
                                        page={page}
                                        onChange={handlePageChange}
                                        variant="outlined"
                                    />
                                </div>
                            </Paper>
                        </div>
                        <Dialog
              open={open}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
                            <DialogTitle id="alert-dialog-title">{`Do you want to ${type} this mentorship ?`}</DialogTitle>
              <DialogActions>
                <Button onClick={handleClose} color="secondary">
                  Close
                </Button>
                <Button onClick={handleAgree} color="primary">
                                    {type === "publish" ? "PUBLISH" : "DRAFT"}
                </Button>
              </DialogActions>
            </Dialog>
                    </div>
                </div>
            </div>
        </main>
    );
};

Mentorship.propTypes = {
    fetchMentorships: propTypes.func,
    mentorshipResult: propTypes.object,
    createResult: propTypes.object,
    updateMentorshipStatus: propTypes.func,
};

const mapStateToProps = (state) => {
  return {
    mentorshipResult: state.availableMentorshipsReducer.mentorship,
    createResult: state.createMentorshipReducer
  };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchMentorships: () => dispatch(fetchMentorships(data)),
        updateMentorshipStatus: () => dispatch(createMentorship(updateReqData)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Mentorship);
