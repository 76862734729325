import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import propTypes from "prop-types";
import { getSubjects } from "../../redux/course/course-action";
import { createArticleImage } from "../../redux/article/article-action";
import { connect } from "react-redux";
import { convertBase64 } from "../../util/helper";

const initialArticle = {
  image: "",
};
let initialErrors = {
  image: "",
  status: "",
};
const CreateArticleImage = ({ open, onClose, createImage }) => {
  const [form, setForm] = useState(initialArticle);
  const [file, setFile] = useState(null);
  const [errors, setError] = useState(initialErrors);

  const handleFormInput = (event) => {
    event.persist();
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
    if (form[name] != "") {
      setError({ ...errors, [name]: "" });
    }
  };

  const saveArticle = async () => {
    if (validateArticle()) {
      const base64 = await convertBase64(file);
      const data = {
        ...form,
        image: base64,
      };
      createImage(data);
      onClose();
    }
  };

  const validateArticle = () => {
    let isValid = true;
    // Validation for image
    if (form.image && !file) {
      initialErrors = {
        ...initialErrors,
        image: "Image cannot be empty",
      };
      isValid = false;
    } else {
      initialErrors = { ...initialErrors, image: "" };
      isValid = true;
    }

    setError(initialErrors);
    return isValid;
  };

  const reset = () => {
    setError({ ...initialErrors, image: "" });
  };

  const handleFileUpload = (e) => {
    setFile(e.target.files[0]);
    errors.image = "";
  };

  useEffect(() => {
    getSubjects();
    reset();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal open={open} className="modal">
      <div
        style={{
          maxHeight: "95%",
          overflow: "auto",
          overflowX: "hidden",
          scrollbarWidth: "thin",
          scrollBehavior: "smooth",
        }}
        className="paper"
      >
        <h2>Create Article Image</h2>
        <label className="form-group has-float-label mb-4">
          <input
            type="file"
            className="form-control"
            name="image"
            value={form.image}
            onChange={(e) => {
              handleFileUpload(e);
              handleFormInput(e);
            }}
          />
          <span className="error">{errors.image}</span>
          <span>Image</span>
        </label>

        <div className="align-items-right text-right mt-4">
          <button
            type="button"
            onClick={onClose}
            className="btn btn-danger btn-lg btn-shadow mr-3"
          >
            CANCEL
          </button>
          <button
            type="button"
            onClick={saveArticle}
            className="btn btn-success btn-lg btn-shadow"
          >
            SAVE
          </button>
        </div>
      </div>
    </Modal>
  );
};

CreateArticleImage.propTypes = {
  open: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  createImage: propTypes.func,
};

const mapDispatchToProps = (dispatch) => {
  return {
    createImage: (data) => dispatch(createArticleImage(data)),
  };
};
export default connect(null, mapDispatchToProps)(CreateArticleImage);
