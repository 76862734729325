import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { addGuidanceNote } from "../../redux/questions/questions-action";
import "../../pages/Question/approved-question.css";
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';

const GuidanceNote = (props) => {
    const { question } = props;
    const dispatch = useDispatch();
    const [editorState, setEditorState] = useState("");
    const [editorError, setEditorError] = useState("");
    useEffect(() => {
        if (question.note) {
            setEditorState(question.note)
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const useStyles = makeStyles((theme) => ({
        root: {
            height: 300,
            flexGrow: 1,
            minWidth: 300,
            transform: "translateZ(0)",
            "@media all and (-ms-high-contrast: none)": {
                display: "none",
            },
        },
        modal: {
            display: "flex",
            alignItems: "center",
            padding: theme.spacing(2),
            justifyContent: "center",
        },
        paper: {
            width: "65%",
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            overflow: "auto",
            minHeight: "65%",
            maxHeight: "90%"
        },
        error: {
            color: "crimson"
        },
        title: {
            margin: "10px",
            fontSize: "20px",
        }
    }));

    const classes = useStyles();
    const submitHandler = (e) => {
        e.preventDefault();
        if (editorState != "" && editorState != null) {
            const args = {
                _id: question.questionId,
                notes: editorState
            }
            dispatch(addGuidanceNote(args));
            handleClose(false);
        } else {
            setEditorError("Guidance note is required.")
        }
    };
    const handleClose = (value) => {
        props.onClose(value);
    };
    const handleChange = (value) => {
        setEditorError("");
        setEditorState(value);
    }
    return (
        <React.Fragment>
            <Modal open={question.show} className={classes.modal}>
                <div className={classes.paper}>
                    <p className={classes.title}><b>GUIDANCE NOTE</b></p>
                    <ReactQuill value={editorState}
                        onChange={handleChange} />
                    <p className={classes.error}>{editorError}</p>
                    <div className="align-items-right text-right mt-3">
                        <button
                            className="btn btn-danger mr-2"
                            type="button"
                            onClick={() => handleClose("close")}>
                            Cancel
                        </button>
                        <button
                            className="btn btn-success"
                            type="button"
                            onClick={submitHandler}>
                            Submit
                        </button>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    );
};
GuidanceNote.propTypes = {
    onClose: PropTypes.func.isRequired,
    question: PropTypes.object.isRequired,
};
export default GuidanceNote;