import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { logout } from "../../util/auth"
import PropTypes from "prop-types";
const ProfilePopup = (props) => {
  const wrapperRef = useRef(null);
  props.alert(wrapperRef);
  const signoutHandler = () => {
    logout();
  }
  return (
    <div
      className="dropdown-menu dropdown-menu-right mt-3"
      ref={wrapperRef}
      id="iconProfile"
    >
      <div className="dropdown-item">
        <Link to="#" onClick={signoutHandler}>Sign Out</Link>
      </div>
    </div>
  );
};
ProfilePopup.propTypes = {
  alert: PropTypes.func.isRequired,
};
export default ProfilePopup;
