import { Grid } from "@material-ui/core";
import React from "react";

const PageNotFound = () => (
  <Grid
    container
    justify="center"
    alignItems="center"
    direction="column"
    style={{ minHeight: "80vh" }}
  >
    <h1>404 Page Not Found !!</h1>
    <h5>
      <a href="/"> Go back to Home Page</a>
    </h5>
  </Grid>
);

export default PageNotFound;
