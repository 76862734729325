export const FETCH_APPROVED_QUESTIONS_REQUEST =
  "FETCH_APPROVED_QUESTIONS_REQUEST";
export const FETCH_APPROVED_QUESTIONS_SUCCESS =
  "FETCH_APPROVED_QUESTIONS_SUCCESS";
export const FETCH_APPROVED_QUESTIONS_FAILURE =
  "FETCH_APPROVED_QUESTIONS_FAILURE";
export const FETCH_CATEGORIZED_QUESTIONS_REQUEST =
  "FETCH_CATEGORIZED_QUESTIONS_REQUEST";
export const FETCH_CATEGORIZED_QUESTIONS_SUCCESS =
  "FETCH_CATEGORIZED_QUESTIONS_SUCCESS";
export const FETCH_CATEGORIZED_QUESTIONS_FAILURE =
  "FETCH_CATEGORIZED_QUESTIONS_FAILURE";
export const APPROVE_CATEGORIZED_QUESTION_REQUEST =
  "APPROVE_CATEGORIZED_QUESTION_REQUEST";
export const APPROVE_CATEGORIZED_QUESTION_SUCCESS =
  "APPROVE_CATEGORIZED_QUESTION_SUCCESS";
export const APPROVE_CATEGORIZED_QUESTION_FAILURE =
  "APPROVE_CATEGORIZED_QUESTION_FAILURE";
export const UPLOAD_QUESTIONS_REQUEST = "UPLOAD_QUESTIONS_REQUEST";
export const UPLOAD_QUESTIONS_SUCCESS = "UPLOAD_QUESTIONS_SUCCESS";
export const UPLOAD_QUESTIONS_FAILURE = "UPLOAD_QUESTIONS_FAILURE";

export const FETCH_MAINS_QUESTIONS_REQUEST = "FETCH_MAINS_QUESTIONS_REQUEST";
export const FETCH_MAINS_QUESTIONS_SUCCESS = "FETCH_MAINS_QUESTIONS_SUCCESS";
export const FETCH_MAINS_QUESTIONS_FAILURE = "FETCH_MAINS_QUESTIONS_FAILURE";

export const CREATE_QUESTIONS_REQUEST = "CREATE_QUESTIONS_REQUEST";
export const CREATE_QUESTIONS_SUCCESS = "CREATE_QUESTIONS_SUCCESS";
export const CREATE_QUESTIONS_FAILURE = "CREATE_QUESTIONS_FAILURE";

export const UPDATE_QUESTIONS_REQUEST = "UPDATE_QUESTIONS_REQUEST";
export const UPDATE_QUESTIONS_SUCCESS = "UPDATE_QUESTIONS_SUCCESS";
export const UPDATE_QUESTIONS_FAILURE = "UPDATE_QUESTIONS_FAILURE";

export const CREATE_SAMPLE_ANSWER_REQUEST = "CREATE_SAMPLE_ANSWER_REQUEST";
export const CREATE_SAMPLE_ANSWER_SUCCESS = "CREATE_SAMPLE_ANSWER_SUCCESS";
export const CREATE_SAMPLE_ANSWER_FAILURE = "CREATE_SAMPLE_ANSWER_FAILURE";

export const EDIT_QUESTION_REQUEST = "EDIT_QUESTION_REQUEST";
export const EDIT_QUESTION_SUCCESS = "EDIT_QUESTION_SUCCESS";
export const EDIT_QUESTION_FAILURE = "EDIT_QUESTION_FAILURE";


export const DELETE_APPROVED_QUESTIONS_REQUEST =
  "DELETE_APPROVED_QUESTIONS_REQUEST";
export const DELETE_APPROVED_QUESTIONS_SUCCESS =
  "DELETE_APPROVED_QUESTIONS_SUCCESS";
export const DELETE_APPROVED_QUESTIONS_FAILURE =
  "DELETE_APPROVED_QUESTIONS_FAILURE";
