import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import propTypes from "prop-types";
import { createSubject } from "../../redux/subject/subject-action";
import { connect } from "react-redux";
import { convertBase64 } from "../../util/helper";
import config from '../../config';
import { Checkbox } from "@material-ui/core";

let initializeSubject = {
    name: "",
    type: "",
    imageUpdate:'',
};
let initializeSubjectErrors = {
    name: "",
    type: "",
    image: ""
};

const CreateSubject = ({
    open,
    onClose,
    createSubject,
    editSubjectData,
    isEditSubject
}) => {
    const [subjectForm, setForm] = useState(initializeSubject);
    const [subjectErrors, setError] = useState(initializeSubjectErrors);
    const [file, setfile] = useState(null)
    useEffect(() => {
        reset();
        if(isEditSubject){
            reset();
            setForm(editSubjectData)
        }else{
            reset();
            setForm({
                name: "",
                type: "",
                imageUpdate: ''
            });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // For resetting form error values.
    const reset = () => {
        initializeSubjectErrors = {
            name: "",
            type: "",
            image: ""
        };
        setError(initializeSubjectErrors);
    };

    // Handle when user inputs are received.
    const handleFormInputChange = (event) => {
        event.persist();
        const { name, value, checked } = event.target;
        if (name === "type") {
            setForm({
                ...subjectForm,
                type: value
            })
            subjectErrors.type="";
        } else if (name === "name") {
            setForm({
                ...subjectForm,
                name: value
            })
            subjectErrors.name="";
        } else if(name === 'imageUpdate'){
            setForm({
                ...subjectForm,
                imageUpdate: checked
            })
        }
    };

    // Create and edit subject api handling.
    async function saveSubject() {
        if(isEditSubject){
            const {name: subjectName, _id} = subjectForm

            if(validateSubjectForm()){
                let updateParams = {_id, subjectName}
                if(subjectForm.imageUpdate){
                    const base64 = await convertBase64(file);
                    updateParams = {...updateParams, image: base64}
                }
                createSubject(updateParams)
                onClose()
            }
        }else{
            if (validateSubjectForm()) {
                const base64 = await convertBase64(file);
                let data = {
                    _id: subjectForm._id && subjectForm._id,
                    subjectName: subjectForm.name,
                    type: subjectForm.type,
                    image: base64
                }
                createSubject(data);
                onClose();
            } 
        }

    }

    // Form validation.
    const validateSubjectForm = () => {
        let isValid = true;
        let inputs = subjectForm;
        const reg = /^[a-zA-Z][a-zA-Z ]+$/;
        if (inputs["name"] === "" ) {
            setError({
                ...subjectErrors,
                name: "Subject name should not be empty"
            })
            isValid = false;
        }
        if (inputs["type"] === "" || !reg.test(inputs["type"])) {
            setError({
                ...subjectErrors,
                type: "Select one subject type"
            })
            isValid = false;
        }
        if(subjectForm.imageUpdate && !file || !isEditSubject && !file){
            setError({
                ...subjectErrors,
                image: "Select Subject image"
            })
            isValid = false;
        }
        return isValid;
    };
    const handleFileUpload = (e) => {
        setfile(e.target.files[0]);
        subjectErrors.image="";
    }
    return (
        <Modal open={open} className="modal">
            <div className="paper">
                <h2>{isEditSubject ? 'Edit' : 'Create'} Subject</h2>
                <label className="form-group has-float-label mb-4">
                    <input
                        className="form-control"
                        name="name"
                        value={subjectForm.name}
                        onChange={(e) => handleFormInputChange(e)}
                    />
                    <span className="error">{subjectErrors.name}</span>
                    <span>Name</span>
                </label>
                {isEditSubject && 
                <React.Fragment>
                <label className="form-group has-float-label mb-4">
                    <p>Current Image: </p>
                    {isEditSubject && <img className="thumbnail" 
                    src={`${config.SPACE_URL}${editSubjectData.imageKey}`} alt="thumbnail"></img> }
                </label>
                <label className="">
                    <span>Update Image: 
                    <Checkbox value={subjectForm.imageUpdate} name="imageUpdate"
                    onChange={(e) => handleFormInputChange(e)}
                    />
                    </span>
                </label>
                </React.Fragment>
                }
                {subjectForm.imageUpdate === true || !isEditSubject ? 
                <label className="form-group has-float-label mb-4">
                    <input
                        type="file"
                        className="form-control"
                        name="image"
                        value={subjectForm.image}
                        onChange={(e) => handleFileUpload(e)}
                    />
                    {
                        subjectErrors.image ?
                            (<span className="error">{subjectErrors.image}</span>)
                            : null
                    }
                    <span>Image</span>
                </label>: null}

                <label className="form-group has-float-label mb-4">
                    <select
                        data-width="100%"
                        className="form-control select2-single"
                        value={subjectForm.type}
                        onChange={(e) => handleFormInputChange(e)}
                        name="type"
                        disabled={isEditSubject}
                    >
                        <option disabled value="">
                            Select subject type
            </option>
                        <option value="prelim">Prelim</option>
                        <option value="course">Course</option>
                        <option value="mains">Mains</option>
                    </select>
                    <span className="error">{subjectErrors.type}</span>
                    <span>Subject Type</span>
                </label>

                <div className="align-items-right text-right mt-4">
                    <button
                        type="button"
                        onClick={onClose}
                        className="btn btn-danger btn-lg btn-shadow mr-3"
                    >
                        CANCEL
          </button>
                    <button
                        type="button"
                        onClick={saveSubject}
                        className="btn btn-success btn-lg btn-shadow"
                    >
                        SAVE
          </button>
                </div>
            </div>
        </Modal>
    );
};

CreateSubject.propTypes = {
    open: propTypes.bool.isRequired,
    onClose: propTypes.func.isRequired,
    createSubject: propTypes.func,
    editSubjectData: propTypes.object,
    isEditSubject: propTypes.bool,
};

const mapDispatchToProps = (dispatch) => {
    return {
        createSubject: (data) => dispatch(createSubject(data)),
    };
};


export default connect(null, mapDispatchToProps)(CreateSubject);