const initialState = {
    loading: true,
    videoList: {},
    error: ''
  }

const initialStateOfCommonResponse = {
    loading: true,
    status: {},
    error: ''
 }

 const topicResponse = {
    loading: true,
    topics: [],
    error: ''
 }

 const deleteVideo = {
  loading: true,
  data: {},
  error: "",
};

const fetchVideoReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'FETCH_VIDEO_REQUEST':
        return {
          ...state,
          loading: true
        }
      case 'FETCH_VIDEO_SUCCESS':
        return {
          loading: false,
          videoList: action.payload,
          error: ''
        }
      case 'FETCH_VIDEO_FAILURE':
        return {
          loading: false,
          videoList: {},
          error: action.payload
        }
      default:
      return state
    }
  }

  const linkVideoReducer = (state = initialStateOfCommonResponse, action) => {
    switch (action.type) {
      case 'LINK_VIDEO_REQUEST':
        return {
          ...state,
          loading: true
        }
      case 'LINK_VIDEO_SUCCESS':
        return {
          loading: false,
          status: action.payload,
          error: ''
        }
      case 'LINK_VIDEO_FAILURE':
        return {
          loading: false,
          status: {},
          error: action.payload
        }
      default:
      return state
    }
  }

  const topicReducer = (state = topicResponse, action) => {
    switch (action.type) {
      case 'FETCH_TOPIC_REQUEST':
        return {
          ...state,
          loading: true
        }
      case 'FETCH_TOPIC_SUCCESS':
        return {
          loading: false,
          topics: action.payload,
          error: ''
        }
      case 'FETCH_TOPIC_FAILURE':
        return {
          loading: false,
          topics: [],
          error: action.payload
        }
      default:
      return state
    }
  }

  const videoNoteReducer = (state = initialStateOfCommonResponse, action) => {
    switch (action.type) {
      case 'ADD_VIDEO_NOTE_REQUEST':
        return {
          ...state,
          loading: true
        }
      case 'ADD_VIDEO_NOTE_SUCCESS':
        return {
          loading: false,
          status: action.payload,
          error: ''
        }
      case 'ADD_VIDEO_NOTE_FAILURE':
        return {
          loading: false,
          status: {},
          error: action.payload
        }
      default:
      return state
    }
  }

  const createRecommendedVideoReducer = (state = initialStateOfCommonResponse, action) => {
    switch (action.type) {
      case 'CREATE_RECOMMENDED_VIDEO_REQUEST':
        return {
          ...state,
          loading: true
        }
      case 'CREATE_RECOMMENDED_VIDEO_SUCCESS':
        return {
          loading: false,
          status: action.payload,
          error: ''
        }
      case 'CREATE_RECOMMENDED_VIDEO_FAILURE':
        return {
          loading: false,
          status: {},
          error: action.payload
        }
      default:
      return state
    }
  }

  const recommendedVideoReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'FETCH_RECOMMENDED_VIDEO_REQUEST':
        return {
          ...state,
          loading: true
        }
      case 'FETCH_RECOMMENDED_VIDEO_SUCCESS':
        return {
          loading: false,
          videoList: action.payload,
          error: ''
        }
      case 'FETCH_RECOMMENDED_VIDEO_FAILURE':
        return {
          loading: false,
          videoList: {},
          error: action.payload
        }
      default:
      return state
    }
  }

  const deleteRecommendedVideoReducer = (state = initialStateOfCommonResponse, action) => {
    switch (action.type) {
      case 'DELETE_RECOMMENDED_VIDEO_REQUEST':
        return {
          ...state,
          loading: true
        }
      case 'DELETE_RECOMMENDED_VIDEO_SUCCESS':
        return {
          loading: false,
          status: action.payload,
          error: ''
        }
      case 'DELETE_RECOMMENDED_VIDEO_FAILURE':
        return {
          loading: false,
          status: {},
          error: action.payload
        }
      default:
      return state
    }
  }

  const deleteVideoReducer=(state=deleteVideo,action)=>{
  switch (action.type) {
    case "DELETE_VIDEO_REQUEST":
    return{
      ...state,
      loading:true
    }
    case "DELETE_VIDEO_SUCCESS":
    return{
      loading:false,
      data:action.payload,
      error:''
    }
    case "DELETE_VIDEO_FAILURE":
      return{
        loading:false,
        data:{},
        error:action.payload
      }
    default:
      return state;
  }
  }

  const statusUpdateVideoReducer=(state=initialStateOfCommonResponse,action)=>{
    switch (action.type) {
      case "STATUS_UPDATE_VIDEO_REQUEST":
      return{
        ...state,
        loading:true
      }
      case "STATUS_UPDATE_VIDEO_SUCCESS":
      return{
        loading:false,
        status:action.payload,
        error:''
      }
      case "STATUS_UPDATE_VIDEO_FAILURE":
        return{
          loading:false,
          error:action.payload
        }
      default:
        return state;
    }
    }

const videoQuizReducer = (state = deleteVideo, action) => {
  switch (action.type) {
    case "VIDEO_QUIZ_REQUEST":
      return {
        ...state,
        loading: true
      }
    case "VIDEO_QUIZ_SUCCESS":
      return {
        loading: false,
        data: action.payload,
        error: ''
      }
    case "VIDEO_QUIZ_FAILURE":
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state;
  }
}

export {fetchVideoReducer,
        linkVideoReducer,
        topicReducer,
        videoNoteReducer,
        createRecommendedVideoReducer,
        recommendedVideoReducer,
        deleteVideoReducer,
        statusUpdateVideoReducer,
        deleteRecommendedVideoReducer,
        videoQuizReducer};
