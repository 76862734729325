import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import propTypes from "prop-types";
import { updateVideoNote } from '../../redux/video/video-action';
import { connect } from "react-redux";
import { convertBase64 } from "../../util/helper";
import config from '../../config';
import "../../pages/Question/approved-question.css";

let videoData = {
    videoId: '',
    note: ''
}

const VideoNote = ({ onClose, video, updateVideoNote }) => {
    const [createdNote, setNote] = useState(null);
    const [error, seterror] = useState("")

    const saveVideoNote = async() => {
        if(createdNote){
            videoData.videoId = video.videoId;
            const base64 = await convertBase64(createdNote);
            videoData.note = base64;
            updateVideoNote(videoData);
            onClose();
        }else{
            seterror("Please select a file to upload");
        }
       
    }

    return (
        <Modal open={video.open} className="modal">
            <div className="paper test-modal">
                <div className="mb-5">
                    <h3>VIDEO NOTES</h3>
                </div>
                {video.note ? <div className="mb-2"><a href={config.SPACE_URL+video.note} target="_blank" rel="noopener noreferrer">Click here</a> to see the uploaded video note</div>  : null} 
                    <p className="text-danger" id="server-modal-description">
                        *Please upload only pdf files
                     </p>
                    <input
                        type="file"
                        className="form-control"
                        id="syllabus"
                        onChange={(e) => setNote(e.target.files[0])}
                        accept=".pdf"
                    />
                    {
                        error && !createdNote ? (<span className="error">{error}</span>) : null
                    }
                <div className="align-items-right text-right mt-4">
                    <button
                        type="button"
                        onClick={onClose}
                        className="btn btn-danger btn-lg btn-shadow mr-3">
                        CANCEL
                    </button>
                    <button disabled={createdNote === ""}
                        type="button"
                        onClick={saveVideoNote}
                        className="btn btn-success btn-lg btn-shadow">
                        SAVE
                    </button>
                </div>
            </div>
        </Modal>
    );
}

VideoNote.propTypes = {
    onClose: propTypes.func.isRequired,
    video: propTypes.object.isRequired,
    updateVideoNote: propTypes.func,
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateVideoNote: () => dispatch(updateVideoNote(videoData))
    };
};

export default connect(null, mapDispatchToProps)(VideoNote)
