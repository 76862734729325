import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import propTypes from "prop-types";
import { createEditFaqVideo } from "../../redux/faq/faq-action";
import { connect } from "react-redux";

let initializeVideo = {
    vimeoId: "",
    _id: ""
};
let initializeVideoErrors = {
    vimeoId: "",
    _id: ""
};

const CreateVideo = ({
    open,
    onClose,
    createVideo,
    edit,
    data
}) => {
    const [videoForm, setForm] = useState(initializeVideo);
    const [videoErrors, setError] = useState(initializeVideoErrors);
    useEffect(() => {
        reset();
        if (edit) {
            setForm({
                vimeoId: data.vimeoId,
                _id: data._id
            });
        } else {
            setForm({
                vimeoId: "",
            });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // For reseting form error values.
    const reset = () => {
        initializeVideoErrors = {
            vimeoId: "",
            _id: ""
        };
        setError(initializeVideoErrors);
    };

    // Handle when user inputs are received.
    const handleFormInputChange = (event) => {
        event.persist();
        const { value } = event.target;
        setForm({
            ...videoForm,
            vimeoId: value
        })
        videoErrors.vimeoId = "";
    };
    const saveVideo = () => {
        if (validateVideoForm()) {
            if (edit) {
                createVideo(videoForm);
            } else {
                createVideo({ vimeoId: videoForm.vimeoId });
            }
        onClose();
        }
    }
    // Form validation.
    const validateVideoForm = () => {
        let isValid = true;
        let inputs = videoForm;
        if (inputs["vimeoId"] === "") {
            setError({
                ...videoErrors,
                vimeoId: "vimeoId should not be empty"
            })
            isValid = false;
        }
        return isValid;
    };
    return (
        <Modal open={open} className="modal">
            <div className="paper">
                <label className="form-group has-float-label mb-4">
                    <input
                        className="form-control"
                        name="vimeoId"
                        value={videoForm.vimeoId}
                        onChange={(e) => handleFormInputChange(e)}
                    />
                    <span className="error">{videoErrors.vimeoId}</span>
                    <span>Vimeo Id</span>
                </label>
                <div className="align-items-right text-right mt-4">
                    <button
                        type="button"
                        onClick={onClose}
                        className="btn btn-danger btn-lg btn-shadow mr-3"
                    >
                        CANCEL
          </button>
                    <button
                        type="button"
                        onClick={saveVideo}
                        className="btn btn-success btn-lg btn-shadow"
                    >
                        SAVE
          </button>
                </div>
            </div>
        </Modal>
    );
};

CreateVideo.propTypes = {
    open: propTypes.bool.isRequired,
    onClose: propTypes.func.isRequired,
    createVideo: propTypes.func,
    edit: propTypes.bool,
    data: propTypes.object
};

const mapDispatchToProps = (dispatch) => {
    return {
        createVideo: (data) => dispatch(createEditFaqVideo(data)),
    };
};


export default connect(null, mapDispatchToProps)(CreateVideo);
