const initialState = {
    loading: true,
    data: {},
    error: "",
  };
  
  export const fetchUserReducer = (state = initialState, action) => {
    switch (action.type) {
      case "FETCH_USER_REQUEST":
        return {
          ...state,
          loading: true,
        };
      case "FETCH_USER_SUCCESS":
        return {
          loading: false,
          data: action.payload,
          error: "",
        };
       case "FETCH_USER_FAILURE":
        return {
          loading: false,
          data: {},
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export const createUserReducer=(state = initialState,action)=>{
    switch (action.type) {
      case "CREATE_USER_REQUEST":
        return{
          ...state,
          loading:true,
        }
      case "CREATE_USER_SUCCESS":
        return{
          loading:false,
          data:action.payload,
          error:""
        }
      case "CREATE_USER_FAILURE":
        return{
          loading:false,
          data:{},
          error:action.payload
        }
      default:
        return state
    }
  }

export const updatePaymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_PAYMENT_REQUEST":
      return {
        ...state,
        loading: true,
      }
    case "UPDATE_PAYMENT_SUCCESS":
      return {
        loading: false,
        data: action.payload,
        error: ""
      }
    case "UPDATE_PAYMENT_FAILURE":
      return {
        loading: false,
        data: {},
        error: action.payload
      }
    default:
      return state
  }
}
