import React, { useEffect, useState } from "react";
import {
    fetchAvailableAlerts,
} from "../../redux/mains/mains-action";
import propTypes from "prop-types";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Pagination from "@material-ui/lab/Pagination";
import { Capitalize } from "../../util/helper";
import moment from 'moment';
let data = {
    skip: 0,
    limit: 10,
    keyword: "",
};
const Alert = ({ alertResult, fetchAlert }) => {
    const [page, setPage] = useState(1);
    const [rowCount, setRowCount] = useState(10);
    useEffect(() => {
        fetchAlert(data);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleRowsPerPage = (event) => {
        setRowCount(event.target.value);
        setPage(1);
        data.limit = event.target.value;
        data.skip = 0;
        fetchAlert(data);
    };
    const searchAlert = (event) => {
        data.skip = 0;
        data.keyword = event.target.value;
        setPage(1);
        fetchAlert(data);
    };
    const handlePageChange = (event, newPage) => {
        setPage(newPage);
        data.skip = (newPage - 1) * rowCount;
        fetchAlert(data);
    };
    return (
        <main>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <h1>Alerts</h1>
                        <nav
                            className="breadcrumb-container d-none d-sm-block d-lg-inline-block"
                            aria-label="breadcrumb"
                        >
                            <ol className="breadcrumb pt-0">
                                <li className="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                            </ol>
                        </nav>
                        <div className="separator mb-5"></div>
                        <div className="tableBody">
                            <div className="table-filter-div">
                                <Grid item xs={6}>
                                    <div className="search-div">
                                        <input
                                            type="text"
                                            className="search"
                                            onKeyUp={searchAlert}
                                            placeholder="Search aspirant or evaluator..."
                                        ></input>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="rowCount-div">
                                        <label>Alerts per page : </label>
                                        <select className="selection" onChange={handleRowsPerPage}>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </Grid>
                            </div>
                            <Paper className="paperBody">
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="tableHead" align="center">Sl.No</TableCell>
                                                <TableCell className="tableHead">Question</TableCell>
                                                <TableCell className="tableHead">Aspirant</TableCell>
                                                <TableCell className="tableHead">Evaluator</TableCell>
                                                <TableCell className="tableHead">Assigned On</TableCell>
                                                <TableCell className="tableHead">Uploaded On</TableCell>
                                                <TableCell className="tableHead">Alert Date</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                alertResult &&
                                                alertResult.notifications &&
                                                alertResult.notifications.map((row, index) => {
                                                    return (
                                                        <TableRow hover key={row._id}>
                                                            <TableCell
                                                                className="tableColData"
                                                                align="center"
                                                            >
                                                                {rowCount * (page - 1) + index + 1}
                                                            </TableCell>
                                                            <TableCell style={{maxWidth:450}} className="tableColData">
                                                                {row.question}
                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                {row.aspirant ? Capitalize(row.aspirant) : null}
                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                {row.evaluator ? Capitalize(row.evaluator) : null}

                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                {row.assignedOn ? moment(row.assignedOn).format("DD/MM/YYYY") : null}
                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                {moment(row.uploadedOn).format("DD/MM/YYYY")}
                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                {moment(row.alertDate).format("DD/MM/YYYY")}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <div className="paginationLabel">
                                    Showing {(page - 1) * rowCount + 1} to{" "}
                                    {(page - 1) * rowCount +
                                        (alertResult && alertResult.notifications
                                            ? alertResult.notifications.length
                                            : 0)}{" "}
                  of{" "}
                                    {alertResult && alertResult.totalNotifications
                                        ? alertResult.totalNotifications
                                        : 0}{" "}
                  entries
                </div>
                                <div className="root">
                                    <Pagination
                                        count={
                                            alertResult && alertResult.totalNotifications
                                                ? Math.ceil(alertResult.totalNotifications / rowCount)
                                                : 0
                                        }
                                        page={page}
                                        onChange={handlePageChange}
                                        variant="outlined"
                                    />
                                </div>
                            </Paper>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

Alert.propTypes = {
    fetchAlert: propTypes.func,
    alertResult: propTypes.object,
};

const mapStateToProps = (state) => {
    return {
        alertResult: state.availableAlertsReducer.alerts,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchAlert: () => dispatch(fetchAvailableAlerts(data)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Alert);
