import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { useDispatch } from "react-redux";
import { createGame } from '../../redux/game/game-action'

const GameStatusPopup = (props) => {
  const dispatch = useDispatch();
  const { row } = props;
  const handleClose = (e, action) => {
    e.stopPropagation();
    if (action === "published" || action === "draft") {
      let param = {
        status: action,
        _id: row._id
      };
      dispatch(createGame( param));
    }
    props.onClose();
  };
  return (
    <React.Fragment>
      {row.status === "draft" && props.action === "published" ? (
        <Dialog
          open={props.open && row.status === "draft"}
          onClose={props.onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Do you want to publish this game?"}
          </DialogTitle>
          <DialogActions>
            <Button
              variant="text"
              onClick={(e) => handleClose(e, "close")}
              id="close"
              color="secondary"
            >
              Close
            </Button>
            <Button
              variant="text"
              id="approve"
              onClick={(e) => handleClose(e, "published")}
              color="primary"
            >
              Publish
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog
          open={props.open && row.status === "published"}
          onClose={props.onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Do you want to draft this game?"}
          </DialogTitle>
          <DialogActions>
            <Button
              variant="text"
              onClick={(e) => handleClose(e, "close")}
              id="close"
              color="secondary"
            >
              Close
            </Button>
            <Button
              variant="text"
              id="approve"
              onClick={(e) => handleClose(e, "draft")}
              color="primary"
            >
              Draft
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
};
GameStatusPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
  action: PropTypes.string.isRequired,
};
export default GameStatusPopup;
