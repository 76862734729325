import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import propTypes from "prop-types";
import { fetchCoursePlan } from "../../redux/course/course-action";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import { getPermission } from "../../util/auth";
import EditIcon from "@material-ui/icons/Edit";
import CreateCoursePlan from "../../components/popup/course-plan";

let data = {
  skip: 0,
  limit: 10,
  keyword: "",
};

const CoursePlanList = ({ fetchCoursePlan, coursePlan }) => {
  const [page, setPage] = useState(1);
  const [rowCount, setRowCount] = useState(10);
  const [coursePlanModal, setModal] = useState({ open: false, edit: false });
  const [editData, setEditData] = useState({});

  useEffect(() => {
    fetchCoursePlan(data);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const searchCoursePlan = (event) => {
    data.skip = 0;
    data.keyword = event.target.value;
    setPage(1);
    fetchCoursePlan(data);
  };

  const handleRowsPerPage = (event) => {
    setRowCount(event.target.value);
    setPage(1);
    data.limit = event.target.value;
    data.skip = 0;
    fetchCoursePlan(data);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    data.skip = (newPage - 1) * rowCount;
    fetchCoursePlan(data);
  };

  const handleEditCoursePlan = (row) => {
    setEditData(row);
    setModal({ open: true, edit: true });
  };

  const createCoursePlan = () => {
    setModal({ open: true, edit: false });
  };

  const closeModal = () => {
    setModal({ open: false, edit: false });
    setTimeout(() => {
      fetchCoursePlan(data);
    }, 1000);
  };

  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Course Plan</h1>
            <nav
              className="breadcrumb-container d-none d-sm-block d-lg-inline-block"
              aria-label="breadcrumb"
            >
              <ol className="breadcrumb pt-0">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
              </ol>
            </nav>
            <div className="separator mb-5"></div>
            {coursePlanModal.open ? (
              <CreateCoursePlan
                open={coursePlanModal.open}
                planData={editData}
                isEdit={coursePlanModal.edit}
                onClose={closeModal}
              />
            ) : null}

            <div className="file-upload mb-3 ml-2">
              <button
                className="btn btn-outline-primary"
                type="button"
                onClick={createCoursePlan}
              >
                CREATE COURSE PLAN
              </button>
            </div>

            <div className="tableBody">
              <div className="table-filter-div">
                <Grid item xs={6}>
                  <div className="search-div">
                    <input
                      type="text"
                      className="search"
                      onKeyUp={searchCoursePlan}
                      placeholder="Search plans using course name.."
                    ></input>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="rowCount-div">
                    <label>Plans per page : </label>
                    <select className="selection" onChange={handleRowsPerPage}>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </Grid>
              </div>
              <Paper className="paperBody">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="tableHead" align="center">
                          Sl.No
                        </TableCell>
                        <TableCell className="tableHead">Course Name</TableCell>
                        <TableCell className="tableHead">
                          Actual Price
                        </TableCell>
                        <TableCell className="tableHead">Offer Price</TableCell>
                        <TableCell className="tableHead">Duration</TableCell>
                        <TableCell className="tableHead">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {coursePlan &&
                        coursePlan.plan &&
                        coursePlan.plan.map((row, index) => {
                          return (
                            <TableRow hover key={row.planId}>
                              <TableCell
                                className="tableColData"
                                align="center"
                              >
                                {rowCount * (page - 1) + index + 1}
                              </TableCell>
                              <TableCell className="tableColData">
                                {row.courseName}
                              </TableCell>
                              <TableCell className="tableColData">
                                {row.actualPrice} /-
                              </TableCell>
                              <TableCell className="tableColData">
                                {row.offerPrice} /-
                              </TableCell>
                              <TableCell className="tableColData">
                                {row.duration} days
                              </TableCell>
                              <TableCell className="tableColData">
                                {getPermission().plan.edit ? (
                                  <EditIcon
                                    titleAccess="Edit"
                                    aria-label="edit"
                                    fontSize="small"
                                    onClick={() => handleEditCoursePlan(row)}
                                  />
                                ) : null}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className="paginationLabel">
                  Showing {(page - 1) * rowCount + 1} to{" "}
                  {(page - 1) * rowCount +
                    (coursePlan && coursePlan.plan
                      ? coursePlan.plan.length
                      : 0)}{" "}
                  of{" "}
                  {coursePlan.totalCoursePlans
                    ? coursePlan.totalCoursePlans
                    : 0}{" "}
                  entries
                </div>
                <div className="root">
                  <Pagination
                    count={
                      coursePlan && coursePlan.totalCoursePlans
                        ? Math.ceil(coursePlan.totalCoursePlans / rowCount)
                        : 0
                    }
                    page={page}
                    onChange={handlePageChange}
                    variant="outlined"
                  />
                </div>
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

CoursePlanList.propTypes = {
  fetchCoursePlan: propTypes.func,
  coursePlan: propTypes.object,
};

const mapStateToProps = (state) => {
  return {
    coursePlan: state.coursePlanReducer.plan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCoursePlan: () => dispatch(fetchCoursePlan(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CoursePlanList);
