import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import propTypes from "prop-types";
import { createGame } from "../../redux/game/game-action";
import { connect } from "react-redux";
import { getSubjects } from "../../redux/course/course-action";
import moment from 'moment';

let initializeGame = {
  title: "",
  subject: "",
  // topic: "",
  durationType: "true",
  duration: "",
  startDate: "",
  numOfQuestions: "",
};
let initializeGameErrors = {
  title: "",
  subject: "",
  // topic: "",
  durationType: "",
  duration: "",
  startDate: "",
  numOfQuestions: "",
};
let gameId = "";
const CreateGame = ({
  open,
  onClose,
  createGame,
  edit,
  data,
  getSubjects,
  // getTopics,
  subjectResult,
  // topicResult
}) => {
  const [gameForm, setForm] = useState(initializeGame);
  const [gameErrors, setError] = useState(initializeGameErrors);
  useEffect(() => {
    getSubjects();
    reset();
    if (edit) {
      gameId = data._id;
      // getTopics(data.subject._id)
      setForm({
        title: data.title,
        durationType: data.durationType === true ? "true" : "false",
        subject: data.subject._id,
        // topic: data.topic._id,
        numOfQuestions: data.numOfQuestions,
        startDate: moment(data.startDate).format('YYYY-MM-DD[T]HH:mm:ss'),
        duration: data.duration / 60
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // For reseting form error values.
  const reset = () => {
    initializeGameErrors = {
      title: "",
      subject: "",
      // topic: "",
      durationType: "",
      duration: "", 
      startDate: "",
      numOfQuestions: "",
    };
    setError(initializeGameErrors);
    initializeGame = {
      title: "",
      subject: "",
      // topic: "",
      durationType: "true",
      duration: "",
      startDate: "",
      numOfQuestions: "",
    };
    setForm(initializeGame);
  };
  const regNumber = /^[0-9]+$/
  // Handle when user inputs are received.
  const handleFormInputChange = (event) => {
    event.persist();
    const { name, value } = event.target;
    setForm((gameForm) => ({
      ...gameForm,
      [name]: value,
    }));
    if (name === "title") {
      if(!value.length){
        setError({
          ...gameErrors,
          title: "Title should not be empty",
        })
      } else {
        setError({
          ...gameErrors,
          title: "",
        })
      }
    }
    if (name === "subject") {
      if(!value.length){
        setError({
          ...gameErrors,
          subject: "Subject should not be empty",
        })
      } else {
        // getTopics(value)
        setError({
          ...gameErrors,
          subject: "",
        })
      }
    }
    // if (name === "topic") {
    //   if(!value.length){
    //     setError({
    //       ...gameErrors,
    //       topic: "Topic should not be empty",
    //     })
    //   } else {
    //     setError({
    //       ...gameErrors,
    //       topic: "",
    //     })
    //   }
    // }
    if (name === "startDate") {
      if(!value.length || new Date(value) < new Date() ){
        setError({
          ...gameErrors,
          startDate: "Invalid start date",
        })
      } else {
        setError({
          ...gameErrors,
          startDate: "",
        })
      }
    }
    if (name === "durationType") {
      if(!value.length){
        setError({
          ...gameErrors,
          durationType: "Duration type should not be empty",
        })
      } else {
        setError({
          ...gameErrors,
          durationType: "",
        })
      }
    }
    if (name === "numOfQuestions" ) {
      if(!value.length || parseInt(value)<=0 || !regNumber.test(value) || parseInt(value)>20){
        setError({
          ...gameErrors,
          numOfQuestions: "Invalid number of questions",
        })
      } else {
        setError({
          ...gameErrors,
          numOfQuestions: "",
        })
      }
    }
    if (name === "duration") {
      if(!value.length || parseInt(value)<=0 ||
        !regNumber.test(value)){
        setError({
          ...gameErrors,
          duration: "Invalid duration",
        })
      } else {
        setError({
          ...gameErrors,
          duration: "",
        })
      }
    }
  };
  // Create and edit game api handling.
  async function saveGame() {
    if (formValidator(gameErrors)) {
      let data = {
        title: gameForm.title,
        isGameDuration: gameForm.durationType === "true" ? true : false,
        subject: gameForm.subject,
        // topic: gameForm.topic,
        duration: gameForm.duration * 60,
        startDate: new Date(gameForm.startDate),
        numOfQuestions: gameForm.numOfQuestions
      }
      if (edit) {
        data._id = gameId;
        createGame(data);
      } else {
        createGame(data);
      }
      onClose();
    }
  }
  const formValidator = (gameErrors) => {
    for (let key in gameForm) {
      if(!gameForm[key]) {
        if (key === 'title') {
          setError({
            ...gameErrors,
            [key]: 'Title should not be empty'
          })
          return false;
        } if (key === 'subject' && gameForm['subject']=== "") {
          setError({
            ...gameErrors,
            [key]: 'Subject should not be empty'
          })
          return false;
        } 
        // if (key === 'topic' && gameForm['topic']=== "") {
        //   setError({
        //     ...gameErrors,
        //     [key]: 'Topic should not be empty'
        //   })
        //   return false;
        // } 
        if (key === 'durationType') {
          setError({
            ...gameErrors,
            [key]: 'Duration type should not be empty'
          })
          return false;
        }  if (key === 'duration' && gameForm['duration']=== "") {
          setError({
            ...gameErrors,
            [key]: 'Duration should not be empty'
          })
          return false;
        } if (key === 'startDate') {
          setError({
            ...gameErrors,
            [key]: 'Start date should not be empty'
          })
          return false;
        } if (key === 'numOfQuestions' && gameForm['numOfQuestions']=== "") {
          setError({
            ...gameErrors,
            [key]: 'Number of questions should not be empty'
          })
          return false;
        } 
      }
    }
    return true;
  }
  return (
    <Modal open={open} className="modal">
      <div style={{
        maxHeight: "95%",
        overflow: "auto",
        overflowX: "hidden",
        scrollbarWidth: "thin",
        scrollBehavior: "smooth"
      }} className="paper">
        <label className="form-group has-float-label mb-4">
          <input
            className="form-control"
            name="title"
            onChange={(e) => handleFormInputChange(e)}
            value={gameForm.title}
          />
          <span className="error">{gameErrors.title}</span>
          <span>Name</span>
        </label>
        <label className="form-group has-float-label mb-4">
          <select
            data-width="100%"
            className="form-control select2-single"
            onChange={(e) => handleFormInputChange(e)}
            value={gameForm.subject}
            name="subject"
          >
            <option disabled value="">
              Select Subject
            </option>
            {subjectResult &&
              subjectResult.map((subject) => (
                <option key={subject._id} value={subject._id}>{subject.name}</option>
              ))}
          </select>
          <span className="error">{gameErrors.subject}</span>
          <span>Subject</span>
        </label>

        {/* <label className="form-group has-float-label mb-4">
          <select
            data-width="100%"
            className="form-control select2-single"
            onChange={(e) => handleFormInputChange(e)}
            name="topic"
            value={gameForm.topic}
          >
            <option disabled value="">
              Select Topic
            </option>
            {topicResult &&
              topicResult.map((topic) => (
                <option key={topic._id} value={topic._id}>{topic.name}</option>
              ))}
          </select>
          <span className="error">{gameErrors.topic}</span>
          <span>Topic</span>
        </label> */}
        <label className="form-group has-float-label mb-4">
          <select
            data-width="100%"
            className="form-control select2-single"
            value={gameForm.durationType}
            onChange={(e) => handleFormInputChange(e)}
            name="durationType"
          >
            <option value="true">Game duration</option>
            {/* <option value="false">Question duration</option> */}
          </select>
          <span className="error">{gameErrors.durationType}</span>
          <span>Duration Type</span>
        </label>
        <label className="form-group has-float-label mb-4">
          <input
            type="number"
            className="form-control"
            name="duration"
            value={gameForm.duration}
            onChange={(e) => handleFormInputChange(e)}
          />
          <span className="error">{gameErrors.duration}</span>
          <span>Duration (Minutes)</span>
        </label>
        <label className="form-group has-float-label mb-4">
          <div className="input-group date">
            <input
              type="datetime-local"
              className="form-control"
              name="startDate"
              value={gameForm.startDate}
              onChange={handleFormInputChange}
            />
          </div>
          <span className="error">{gameErrors.startDate}</span>
          <span>Start Date</span>
        </label>
        <label className="form-group has-float-label mb-4">
          <input
            className="form-control"
            name="numOfQuestions"
            value={gameForm.numOfQuestions}
            onChange={(e) => handleFormInputChange(e)}
          />
          <span className="error">{gameErrors.numOfQuestions}</span>
          <span>No of questions</span>
        </label>
        <div className="align-items-right text-right mt-4">
          <button
            type="button"
            onClick={onClose}
            className="btn btn-danger btn-lg btn-shadow mr-3"
          >
            CANCEL
          </button>
          <button
            type="button"
            onClick={saveGame}
            className="btn btn-success btn-lg btn-shadow"
          >
            SAVE
          </button>
        </div>
      </div>
    </Modal >
  );
};

CreateGame.propTypes = {
  open: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  createGame: propTypes.func,
  edit: propTypes.bool,
  data: propTypes.object,
  getSubjects: propTypes.func,
  // getTopics: propTypes.func,
  subjectResult: propTypes.array,
  // topicResult: propTypes.array
};
const mapStateToProps = (state) => {
  return {
    subjectResult: state.getCourseSubjectsReducer.subjects,
    // topicResult: state.getCourseTopicReducer.topics,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getSubjects: () => dispatch(getSubjects({ type: "prelim" })),
    // getTopics: (subjectId) => dispatch(getTopics(subjectId)),
    createGame: (data) => dispatch(createGame(data)),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(CreateGame);
