import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { approveQuestion } from "../../redux/questions/questions-action";
import { useDispatch } from "react-redux";
const ActionPopup = (props) => {
  const dispatch = useDispatch();
  const { row } = props;
  const handleClose = (e, action) => {
    e.stopPropagation();
    let data = {
        approve: true,
        _id:row.questionId
      };
    if (action !== "approve") {
      data.approve = false;
    }
    dispatch(approveQuestion(data));
    props.onClose();
  };
  return (
    <React.Fragment>
      {row.approve ? (
        <Dialog
          open={props.open && row.approve}
          onClose={props.onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Do you really want to draft this question?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Drafting this question leads to an agreement for removal of this
              question in the exam and approving list.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="text"
              id="approve"
              onClick={(e) => handleClose(e, "draft")}
              color="primary"
            >
            Draft
            </Button>
            <Button
              variant="text"
              onClick={props.onClose}
              id="close"
              color="secondary"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog
          open={props.open && !row.approve}
          onClose={props.onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Do you want to approve this Question?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Approving this question leads to an agreement for publishing this
              question in the exam and approving list.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="text"
              onClick={props.onClose}
              id="close"
              color="secondary"
            >
              Close
            </Button>
            <Button
              variant="text"
              id="approve"
              onClick={(e) => handleClose(e, "approve")}
              color="primary"
            >
              Approve
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
};
ActionPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
};
export default ActionPopup;
