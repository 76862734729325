import "./app-component.scss";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import Routes from "./routes/common-routes";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure({position: "top-right",autoClose: 3000,hideProgressBar: true,
closeOnClick: true,pauseOnHover: true,draggable: true});
const App = () => {
  return (
    <React.Fragment>
      <Provider store={store}>
        <Router>
          <Routes />
        </Router>
      </Provider>
    </React.Fragment>
  );
};

export default App;
