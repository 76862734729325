export const FETCH_QUESTION_FAQ_REQUEST = "FETCH_QUESTION_FAQ_REQUEST";
export const FETCH_QUESTION_FAQ_SUCCESS = "FETCH_QUESTION_FAQ_SUCCESS";
export const FETCH_QUESTION_FAQ_FAILURE = "FETCH_QUESTION_FAQ_FAILURE";

export const CREATE_EDIT_FAQ_REQUEST = "CREATE_EDIT_FAQ_REQUEST";
export const CREATE_EDIT_FAQ_SUCCESS = "CREATE_EDIT_FAQ_SUCCESS";
export const CREATE_EDIT_FAQ_FAILURE = "CREATE_EDIT_FAQ_FAILURE";

export const CREATE_EDIT_FAQ_VIDEO_REQUEST = "CREATE_EDIT_FAQ_VIDEO_REQUEST";
export const CREATE_EDIT_FAQ_VIDEO_SUCCESS = "CREATE_EDIT_FAQ_VIDEO_SUCCESS";
export const CREATE_EDIT_FAQ_VIDEO_FAILURE = "CREATE_EDIT_FAQ_VIDEO_FAILURE";
