import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { getPrelimTest,  addPrelimTest } from "../../redux/prelim/prelim-action";
import Select from "react-select";

let prelimTestData = {
    test: []
};

let prelimId = '';

const AddPrelimTest = ({ onClose, open, getPrelimTest, prelimTest, prelim, addPrelimTest }) => {
    const [testFields, setFields] = useState([]);

    useEffect(() => {
        getPrelimTest();
        if (prelim.test.length > 0) {
            const test = prelim.test.map((item) => {
                return { value: item._id, label: item.title }
            });
            setFields(test);
        }
    }, [getPrelimTest, prelim.test]);

    const handleOptionChange = (event) => {
        setFields(event);
    }

    const savePrelimTest = () => {
        prelimId = prelim.id;
        prelimTestData.test = testFields.map((item) => item.value);
        addPrelimTest();
        onClose();
    }

    return (
        <Modal open={open} className="modal">
            <div className="paper test-modal">
                <div className="mb-5">
                    <h3>ADD PRELIM TEST TO PRELIM</h3>
                </div>
                <label className="form-group has-float-label mb-4">
                    <Select
                        name="topic"
                        placeholder="Select topics"
                        value={testFields}
                        onChange={(e) => handleOptionChange(e)}
                        isMulti={true}
                        options={
                            prelimTest.map(
                                (elm) => {
                                    return {
                                        value: elm._id,
                                        label: elm.title,
                                    };
                                }
                            )
                        }
                    />
                    <span>Select Prelim Test</span>
                </label>

                <div className="align-items-right text-right mt-4">
                    <button
                        type="button"
                        onClick={onClose}
                        className="btn btn-danger btn-lg btn-shadow mr-3">
                        CANCEL
                    </button>
                    <button
                        type="button"
                        disabled={!testFields || testFields.length === 0}
                        onClick={savePrelimTest}
                        className="btn btn-success btn-lg btn-shadow">
                        SAVE
                    </button>
                </div>
            </div>
        </Modal>
    );
}

AddPrelimTest.propTypes = {
    onClose: propTypes.func.isRequired,
    open: propTypes.bool.isRequired,
    getPrelimTest: propTypes.func,
    prelimTest: propTypes.array,
    prelim: propTypes.object,
    addPrelimTest: propTypes.func
};

const mapStateToProps = (state) => {
    return {
        prelimTest: state.getPrelimTestReducer.test
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPrelimTest: () => dispatch(getPrelimTest()),
        addPrelimTest: () => dispatch(addPrelimTest({ prelimId: prelimId, test: prelimTestData.test }))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPrelimTest);
