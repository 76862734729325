import React, { useEffect } from "react";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";

import { loginWithGoogle } from "../../redux/login/login-action";
import { useHistory } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import config from "../../config";
import propTypes from "prop-types";
import { connect } from "react-redux";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
let tokenId = "";
function Login({ loginWithGoogle, login }) {
  let history = useHistory();
  const [state, setOpen] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const { vertical, horizontal, open } = state;

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: config.GOOGLE_CLIENT_ID,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
  }, []);

  const responseSuccessGoogle = (response) => {
    tokenId = response.tokenObj.id_token;
    loginWithGoogle(tokenId);
  };

  const responseFailureGoogle = (error) => {
    console.log(error);
    setOpen({ open: true, vertical: "top", horizontal: "right" });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  if (login === true) {
    history.push("/question/approved");
  }

  return (
    <div className="background show-spinner no-footer">
      <div className="fixed-background"></div>
      <div className="container">
        <div className="row h-100 google-card">
          <div className="col-12 col-md-10 mx-auto my-auto">
            <div className="card auth-card">
              <div className="position-relative image-side">
                <p className=" text-dark h2">LEAD IAS</p>
                <div className="dark mb-0">Please sign in with google.</div>
                <div className="login-btn">
                  <GoogleLogin
                    clientId={config.GOOGLE_CLIENT_ID}
                    buttonText="Sign in With Google"
                    onSuccess={responseSuccessGoogle}
                    onFailure={responseFailureGoogle}
                    cookiePolicy={"single_host_origin"}
                  />
                </div>
              </div>
              <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={handleClose}
                autoHideDuration={3000}
                key={vertical + horizontal}
              >
                <Alert severity="error">Login failed. Try again !!</Alert>
              </Snackbar>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Login.propTypes = {
  login: propTypes.bool,
  loginWithGoogle: propTypes.func,
};

const mapStateToProps = (state) => {
  return {
    login: state.loginReducer.login,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginWithGoogle: () => dispatch(loginWithGoogle(tokenId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
