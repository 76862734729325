import React, { useEffect, useState } from "react";
import {
  fetchPrelim,
  updatePrelimStatus,
} from "../../redux/prelim/prelim-action";
import propTypes from "prop-types";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Pagination from "@material-ui/lab/Pagination";
import EditIcon from "@material-ui/icons/Edit";
import { getPermission } from "../../util/auth";
import BlockIcon from "@material-ui/icons/Block";
import DoneIcon from "@material-ui/icons/Done";
import { green } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import moment from "moment";
import CreatePrelim from "./create-prelim";
import { Capitalize } from "../../util/helper";
import PostAddIcon from "@material-ui/icons/PostAdd";
import AddPrelimTest from "../../components/popup/add-prelim-test";
import { useSelector } from "react-redux";

let data = {
  skip: 0,
  limit: 10,
  keyword: "",
  status: "all",
};

let updateReqData = {
  prelimId: "",
  status: "",
};

const Prelim = ({ fetchPrelim, prelimResult, updatePrelimStatus }) => {
  const [page, setPage] = useState(1);
  const [rowCount, setRowCount] = useState(10);
  const [dialogue, setOpen] = useState({ open: false, type: "" });
  const { open, type } = dialogue;
  const [prelimModal, setModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [edit, setEdit] = useState(false);
  const [testModal, setTestModal] = useState({ open: false, prelim: {} });
  const statusChange = useSelector((state) => state.addPrelimTestReducer);

  useEffect(() => {
    fetchPrelim(data);
  }, [statusChange]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRowsPerPage = (event) => {
    setRowCount(event.target.value);
    setPage(1);
    data.limit = event.target.value;
    data.skip = 0;
    fetchPrelim(data);
  };

  const searchPrelim = (event) => {
    data.skip = 0;
    data.keyword = event.target.value;
    setPage(1);
    fetchPrelim(data);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    data.skip = (newPage - 1) * rowCount;
    fetchPrelim(data);
  };

  const handleEditPrelim = (row) => {
    setEdit(true);
    setEditData(row);
    setModal(true);
  };

  const handleUpdatePrelim = (row, type) => {
    updateReqData.prelimId = row.prelimId;
    if(type === "deactivate"){
      updateReqData.status = "draft";
    }
    else{
      updateReqData.status = "published"
    }
    setOpen({ open: true, type: type });
  };

  const handleStatusChange = (ev) => {
    data.status = ev.target.value;
    data.skip = 0;
    setPage(1);
    fetchPrelim(data);
  };

  const handleClose = () => {
    setOpen({ open: false });
  };

  const handleAgree = () => {
    setOpen({ open: false });
    updatePrelimStatus(updateReqData);
    setTimeout(() => {
      fetchPrelim(data);
    }, 1000);
  };

  const createPrelimModal = () => {
    setEdit(false);
    setModal(true);
  };

  const closePrelim = () => {
    setModal(false);
    setTimeout(() => {
      fetchPrelim(data);
    }, 1000);
  };

  const addTest = (row) => {
    let prelimData = {
      id: row.prelimId,
      test: row.test ? row.test : [],
    };
    setTestModal({ open: true, prelim: prelimData });
  };

  const closeTestModal = () => {
    setTestModal({ open: false, prelim: {} });
  };

  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Prelims</h1>
            <nav
              className="breadcrumb-container d-none d-sm-block d-lg-inline-block"
              aria-label="breadcrumb"
            >
              <ol className="breadcrumb pt-0">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
              </ol>
            </nav>
            <div className="separator mb-5"></div>

            {testModal.open ? (
              <AddPrelimTest
                onClose={closeTestModal}
                open={testModal.open}
                prelim={testModal.prelim}
              />
            ) : null}

            <div className="file-upload mb-3 ml-2">
              <button
                className="btn btn-outline-primary"
                type="button"
                onClick={createPrelimModal}
              >
                CREATE PRELIM
              </button>
              {prelimModal ? (
                <CreatePrelim
                  open={prelimModal}
                  edit={edit}
                  data={editData}
                  onClose={closePrelim}
                />
              ) : null}
            </div>
            <div className="tableBody">
              <div className="table-filter-div">
                <Grid item xs={6}>
                  <div className="search-div">
                    <input
                      type="text"
                      className="search"
                      onKeyUp={searchPrelim}
                      placeholder="Search prelim.."
                    ></input>

                    <div className="filter-div">
                      <select
                        className="form-control dropdown select2-single"
                        onChange={handleStatusChange}
                      >
                        <option value="all">All</option>
                        <option value="draft">Draft</option>
                        <option value="published">Published</option>
                      </select>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="rowCount-div">
                    <label>Prelims per page : </label>
                    <select className="selection" onChange={handleRowsPerPage}>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </Grid>
              </div>
              <Paper className="paperBody">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="tableHead" align="center">
                          Sl.No
                        </TableCell>
                        <TableCell className="tableHead">Name</TableCell>
                        <TableCell className="tableHead">Open Date</TableCell>
                        <TableCell className="tableHead">Test Count</TableCell>
                        <TableCell className="tableHead">Offer Price</TableCell>
                        <TableCell className="tableHead">
                          Actual Price
                        </TableCell>
                        <TableCell className="tableHead">Status</TableCell>
                        <TableCell className="tableHead">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {prelimResult &&
                        prelimResult.prelims &&
                        prelimResult.prelims.map((row, index) => {
                          return (
                            <TableRow hover key={row.prelimId}>
                              <TableCell
                                className="tableColData"
                                align="center"
                              >
                                {rowCount * (page - 1) + index + 1}
                              </TableCell>
                              <TableCell className="tableColData">
                                {row.name}
                              </TableCell>
                              <TableCell className="tableColData">
                                {moment(row.openDate).format("DD/MM/YYYY")}
                              </TableCell>
                              <TableCell className="tableColData">
                                {row.testCount}
                              </TableCell>
                              <TableCell className="tableColData">
                                {row.offerPrice}/-
                              </TableCell>
                              <TableCell className="tableColData">
                                {row.actualPrice}/-
                              </TableCell>
                              <TableCell className="tableColData">
                                {Capitalize(row.status?row.status:"")}
                              </TableCell>
                              <TableCell className="tableColData">
                                {getPermission().prelim.edit ? (
                                  <EditIcon
                                    aria-label="edit"
                                    titleAccess="Edit"
                                    fontSize="small"
                                    onClick={() => handleEditPrelim(row)}
                                  />
                                ) : null}
                                {getPermission().prelim.custom && row.testCount ? (
                                  <span className="ml-3">
                                    {row.status === "published" ? (
                                      <BlockIcon
                                        titleAccess="Deactivate"
                                        color="secondary"
                                        aria-label="deactivate"
                                        fontSize="small"
                                        onClick={() =>
                                          handleUpdatePrelim(row, "deactivate")
                                        }
                                      />
                                    ) : (
                                      <DoneIcon
                                        titleAccess="Publish"
                                        style={{ color: green[500] }}
                                        aria-label="publish"
                                        fontSize="small"
                                        onClick={() =>
                                          handleUpdatePrelim(row, "publish")
                                        }
                                      />
                                    )}
                                  </span>
                                ) : null}
                                <PostAddIcon
                                  className="ml-3"
                                  titleAccess="Add Test"
                                  color="primary"
                                  onClick={() => addTest(row)}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className="paginationLabel">
                  Showing {(page - 1) * rowCount + 1} to{" "}
                  {(page - 1) * rowCount +
                    (prelimResult && prelimResult.prelims
                      ? prelimResult.prelims.length
                      : 0)}{" "}
                  of{" "}
                  {prelimResult.totalPrelimCount
                    ? prelimResult.totalPrelimCount
                    : 0}{" "}
                  entries
                </div>
                <div className="root">
                  <Pagination
                    count={
                      prelimResult && prelimResult.totalPrelimCount
                        ? Math.ceil(prelimResult.totalPrelimCount / rowCount)
                        : 0
                    }
                    page={page}
                    onChange={handlePageChange}
                    variant="outlined"
                  />
                </div>
              </Paper>
            </div>

            <Dialog
              open={open}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{`Do you want to ${type} this prelim?`}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {type === "publish"
                    ? "By clicking publish button this prelim will be listout in aspirant app."
                    : "By clicking deactivate button, this prelim will be disappear from aspirant app."}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="secondary">
                  Close
                </Button>
                <Button onClick={handleAgree} color="primary">
                  {type === "publish" ? "PUBLISH" : "DEACTIVATE"}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    </main>
  );
};

Prelim.propTypes = {
  fetchPrelim: propTypes.func,
  prelimResult: propTypes.object,
  updatePrelimStatus: propTypes.func,
};

const mapStateToProps = (state) => {
  return {
    prelimResult: state.prelimReducer.prelim,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPrelim: () => dispatch(fetchPrelim(data)),
    updatePrelimStatus: () => dispatch(updatePrelimStatus(updateReqData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Prelim);
