import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import propTypes from "prop-types";
import { createUsers } from "../../redux/user/user-action";
import { connect } from "react-redux";
import Select from "react-select";
import { getSubjects } from "../../redux/course/course-action";

let initializeUsers = {
    fullName: "",
    email: "",
    userType: "backoffice",
    phoneNumber: '',
    subjects: [],
    costPerEvaluation: 0,
    maximumEvaluationPerDay: 0
};
let initializeUserErrors = {
    fullName: "",
    email: "",
    userType: "",
    phoneNumber: '',
    subjects: '',
    costPerEvaluation: '',
    maximumEvaluationPerDay: ''
};

const CreateUsers = ({
    open,
    onClose,
    createUsers,
    editUserData,
    isEditUser,
    subjectResult,
    getSubjects
}) => {
    const [usersForm, setForm] = useState(initializeUsers);
    const [userErrorForm, setError] = useState(initializeUserErrors);
    const [selectedSubjects, setFields] = useState([]);

    useEffect(() => {
        getSubjects();
        reset();
        if (isEditUser) {
            if(editUserData.userType === 'evaluator'){
                editUserData.costPerEvaluation = editUserData.costPerEvaluation && editUserData.costPerEvaluation.cost;
                handleEditSubject();
            }
            editUserData.phoneNumber = editUserData.phoneNumber.slice(3);
            setForm(editUserData);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const reset = () => {
        initializeUserErrors = {
            fullName: "",
            email: "",
            userType: "",
            phoneNumber: '',
            subjects: [],
            costPerEvaluation: '',
            maximumEvaluationPerDay: ''
        };
        setError(initializeUserErrors);
    };

    const handleEditSubject = () => {
        subjectResult.forEach((item) => {
            if (editUserData.subjects.includes(item._id)) {
                setFields(oldArray => [...oldArray, { label: item.name, value: item._id }]);
            }
        });
    }

    // Handle when user inputs are received.
    const handleFormInputChange = (event) => {
        event.persist();
        const { name, value } = event.target;
        if (name === 'phoneNumber' || name === 'costPerEvaluation' || name === 'maximumEvaluationPerDay') {
            const re = /^[0-9\b]+$/;
            if (value === '' || (re.test(value) && value.length <= 10)) {
                setForm({
                    ...usersForm,
                    [name]: value
                });
            }
        }
        if (name === 'email') {
            if (!validateEmail(value) || value === null || value === "") {
                setError({
                    ...userErrorForm,
                    [name]: `Invalid ${name} id`
                });
            } else {
                setError({
                    ...userErrorForm,
                    [name]: ''
                })
            }

        } else if (value === null || value === "") {
            setError({
                ...userErrorForm,
                [name]: `${name} is required`
            });
        } else {
            setError({
                ...userErrorForm,
                [name]: ''
            })
        }
        if (name != 'phoneNumber' && name != 'costPerEvaluation' && name != 'maximumEvaluationPerDay') {
            setForm({
                ...usersForm,
                [name]: value
            });
        }
    };

    const validateEmail = (email) => {
        return /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/.test(
            email
        );
    }

    const validateUserForm = () => {
        let isValid = true;
        let inputs = usersForm;
        if (inputs.fullName === null || inputs.fullName === "") {
            initializeUserErrors = { ...initializeUserErrors, fullName: 'Full name is required' }
            isValid = false;
        }
        if (inputs.email === null || inputs.email === "") {
            initializeUserErrors = { ...initializeUserErrors, email: 'Email id is required' }
            isValid = false;
        }
        if (inputs.userType === null || inputs.userType === "") {
            initializeUserErrors = { ...initializeUserErrors, userType: 'User type is required' }
            isValid = false;
        }
        if (inputs.phoneNumber === null || inputs.phoneNumber === "") {
            initializeUserErrors = { ...initializeUserErrors, phoneNumber: 'Phone number is required' }
            isValid = false;
        } else if (inputs.phoneNumber && inputs.phoneNumber.length < 10) {
            initializeUserErrors = { ...initializeUserErrors, phoneNumber: 'Invalid phone number' }
            isValid = false;
        }
        if (inputs.subjects && inputs.subjects.length === 0 && inputs.userType === 'evaluator') {
            initializeUserErrors = { ...initializeUserErrors, subjects: 'Subjects are required' }
            isValid = false;
        }
        if ((inputs.costPerEvaluation === null || inputs.costPerEvaluation === "" || inputs.costPerEvaluation === 0) && inputs.userType === 'evaluator') {
            initializeUserErrors = { ...initializeUserErrors, costPerEvaluation: 'Cost per evaluation is required' }
            isValid = false;
        }
        if ((inputs.maximumEvaluationPerDay === null || inputs.maximumEvaluationPerDay === "" || inputs.maximumEvaluationPerDay === 0) && inputs.userType === 'evaluator') {
            initializeUserErrors = { ...initializeUserErrors, maximumEvaluationPerDay: 'Maximum evaluation per day is required' }
            isValid = false;
        }
        setError(initializeUserErrors);
        return isValid;
    }

    function saveUsers() {
        if (validateUserForm()) {
            let formData = usersForm;
            delete formData.status;
            formData.phoneNumber = `+91${usersForm.phoneNumber}`
            createUsers(formData);
            onClose()
        }
    }

    function handleOptionChange(event) {
        let sub = [];
        if (!event) {
            setError({ ...initializeUserErrors, subjects: 'Subjects are required' });
        } else {
            setError({
                ...userErrorForm,
                ['subjects']: ''
            });
            sub = event.map((item) => item.value);
        }
        setForm({
            ...usersForm,
            ['subjects']: sub
        });
        setFields(event);
    }

    return (
        <Modal open={open} className="modal">
            <div className="paper">
                <h2> Users </h2>

                <label className="form-group has-float-label mb-4">
                    <input
                        className="form-control"
                        name="fullName"
                        required
                        value={usersForm.fullName}
                        onChange={(e) => handleFormInputChange(e)} />
                    <span className="error">{userErrorForm.fullName}</span>
                    <span>Name</span>
                </label>

                <label className="form-group has-float-label mb-4">
                    <select
                        data-width="100%"
                        className="form-control select2-single"
                        disabled={isEditUser}
                        required
                        value={usersForm.userType}
                        onChange={(e) => handleFormInputChange(e)}
                        name="userType">
                        <option value="backoffice">Backoffice</option>
                        <option value="evaluator">Evaluator</option>
                    </select>
                    <span className="error">{userErrorForm.userType}</span>
                    <span>User Type</span>
                </label>

                <label className="form-group has-float-label mb-4">
                    <input type="email"
                        className="form-control"
                        required
                        name="email"
                        value={usersForm.email}
                        onChange={(e) => handleFormInputChange(e)} />
                    <span className="error">{userErrorForm.email}</span>
                    <span>Email</span>
                </label>

                <label className="form-group has-float-label mb-4">
                    <input type="text"
                        className="form-control"
                        name="phoneNumber"
                        required
                        value={usersForm.phoneNumber}
                        onChange={(e) => handleFormInputChange(e)} />
                    <span className="error">{userErrorForm.phoneNumber}</span>
                    <span>Phone Number (Without country code)</span>
                </label>

                {usersForm.userType === 'evaluator' && <label className="form-group has-float-label mb-4">
                    <input type="text"
                        className="form-control"
                        name="costPerEvaluation"
                        value={usersForm.costPerEvaluation}
                        required
                        onChange={(e) => handleFormInputChange(e)} />
                    <span className="error">{userErrorForm.costPerEvaluation}</span>
                    <span>Cost per evaluation</span>
                </label>}

                {usersForm.userType === 'evaluator' && <label className="form-group has-float-label mb-4">
                    <input type="text"
                        className="form-control"
                        name="maximumEvaluationPerDay"
                        value={usersForm.maximumEvaluationPerDay}
                        required
                        onChange={(e) => handleFormInputChange(e)} />
                    <span className="error">{userErrorForm.maximumEvaluationPerDay}</span>
                    <span>Maximum evaluation per day allowed</span>
                </label>}

                {usersForm.userType === 'evaluator' && <label className="form-group has-float-label mb-4">
                    <Select
                        name="subjects"
                        placeholder="Select subjects"
                        value={selectedSubjects}
                        onChange={(e) => handleOptionChange(e)}
                        isMulti={true}
                        options={
                            subjectResult && subjectResult.map(
                                (elm) => {
                                    return {
                                        value: elm._id,
                                        label: elm.name,
                                    };
                                }
                            )
                        }
                    />
                    <span className="error">{userErrorForm.subjects}</span>
                    <span>Select Subjects</span>
                </label>}

                <div className="align-items-right text-right mt-4">
                    <button
                        type="button"
                        onClick={onClose}
                        className="btn btn-danger btn-lg btn-shadow mr-3">
                        CANCEL
                    </button>
                    <button
                        type="button"
                        onClick={saveUsers}
                        className="btn btn-success btn-lg btn-shadow">
                        SAVE
                    </button>
                </div>
            </div>
        </Modal>
    );
};

CreateUsers.propTypes = {
    open: propTypes.bool.isRequired,
    onClose: propTypes.func.isRequired,
    createUsers: propTypes.func,
    editUserData: propTypes.object,
    isEditUser: propTypes.bool,
    subjectResult: propTypes.object,
    getSubjects: propTypes.func
};

const mapStateToProps = (state) => {
    return {
        subjectResult: state.getCourseSubjectsReducer.subjects
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        createUsers: (data) => dispatch(createUsers(data)),
        getSubjects: () => dispatch(getSubjects({ type: "all" }))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateUsers);