const TOKEN_KEY = 'jwtToken';
const PERMISSION_KEY = 'permissions';
import { toast } from 'react-toastify';
export const login = (data, permission) => {
    localStorage.setItem(TOKEN_KEY, data);
    localStorage.setItem(PERMISSION_KEY, JSON.stringify(permission));
};

export const logout = () => {
    toast.success("Logged out Successfully.")
    localStorage.removeItem(TOKEN_KEY);
    window.location.replace("/login");
};

export const isLogin = () => {
    if (localStorage.getItem(TOKEN_KEY)) {
        return true;
    }
    return false;
};

export const getPermission = () => {
    return JSON.parse(localStorage.getItem(PERMISSION_KEY));
};
