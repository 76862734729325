import React, { useEffect, useState } from "react";
import {useSelector} from 'react-redux';
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import { fetchDailyTest, updateTestStatus } from "../../redux/exam/exam-action";
import { useHistory } from "react-router-dom";
import propTypes from "prop-types";
import { connect } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import { getPermission } from "../../util/auth";
import DoneIcon from "@material-ui/icons/Done";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import { green } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { Capitalize } from "../../util/helper";

let data = {
  skip: 0,
  limit: 10,
  keyword: "",
  status: "all",
};
let updateReqData = {
  id: "",
  status: "",
};
const DailyTest = ({ fetchDailyTest, dailyTestResult, updateTestStatus }) => {
  const [page, setPage] = useState(1);
  const [rowCount, setRowCount] = useState(10);
  const [dialogue, setOpen] = useState({ open: false, type: "publish" });
  const { open, type } = dialogue;
  let history = useHistory();
  const statusChanged = useSelector((state) => state.updateDailyTestReducer);
  useEffect(() => {
    fetchDailyTest(data);
  }, [statusChanged]); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    data.skip = (newPage - 1) * rowCount;
    fetchDailyTest(data);
  };

  const searchQuestion = (event) => {
    data.skip = 0;
    data.keyword = event.target.value;
    setPage(1);
    fetchDailyTest(data);
  };

  const handleRowsPerPage = (event) => {
    setRowCount(event.target.value);
    setPage(1);
    data.limit = event.target.value;
    data.skip = 0;
    fetchDailyTest(data);
  };

  const handleStatusChange = (ev) => {
    data.status = ev.target.value;
    data.skip = 0;
    setPage(1);
    fetchDailyTest(data);
  };

  const handleEditCourse = (row) => {
    history.push({
      pathname: `/exam/create-exam/${row._id}`,
      state: "dailytest",
    });
  };

  const updateStatus = (type, row) => {
    updateReqData.id = row._id;
    updateReqData.status = type === 'draft' ? 'draft' : 'published';
    setOpen({ open: true, type: type, testId: row._id });
  };

  const handleClose = () => {
    setOpen({ open: false });
  };

  const handleAgree = () => {
    updateTestStatus(updateReqData);
    setOpen({ open: false });
  };
  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Daily Test</h1>
            <nav
              className="breadcrumb-container d-none d-sm-block d-lg-inline-block"
              aria-label="breadcrumb"
            >
              <ol className="breadcrumb pt-0">
                <li className="breadcrumb-item">
                  <a href="/exam/daily-test">Home</a>
                </li>
              </ol>
            </nav>
            <div className="separator mb-5"></div>

            <div className="tableBody">
              <div className="table-filter-div">
                <Grid item xs={6}>
                  <div className="search-div">
                    <input
                      type="text"
                      className="search"
                      onKeyUp={searchQuestion}
                      placeholder="Search test.."
                    ></input>

                    <div className="filter-div">
                      <select
                        className="form-control dropdown select2-single"
                        onChange={(ev) => handleStatusChange(ev)}
                      >
                        <option value="all">All</option>
                        <option value="draft">Draft</option>
                        <option value="published">Published</option>
                      </select>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="rowCount-div">
                    <label>Daily test per page : </label>
                    <select className="selection" onChange={handleRowsPerPage}>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </Grid>
              </div>
              <Paper className="paperBody">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="tableHead" align="center">
                          {" "}
                          Sl.No{" "}
                        </TableCell>
                        <TableCell className="tableHead">Name</TableCell>
                        <TableCell className="tableHead">
                          Total questions
                        </TableCell>
                        <TableCell className="tableHead"> Status </TableCell>
                        <TableCell className="tableHead">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dailyTestResult &&
                        dailyTestResult.dailyTest &&
                        dailyTestResult.dailyTest.map((row, index) => {
                          return (
                            <TableRow hover key={row.testId}>
                              <TableCell
                                className="tableColData"
                                align="center"
                              >
                                {rowCount * (page - 1) + index + 1}
                              </TableCell>
                              <TableCell className="tableColData">
                                {row.title}
                              </TableCell>
                              <TableCell className="tableColData">
                                {row.questionCount}
                              </TableCell>
                              <TableCell className="tableColData">
                                {Capitalize(row.status)}
                              </TableCell>
                              <TableCell className="tableColData">
                                {getPermission().test.edit ? (
                                  <span className="mr-3">
                                    <EditIcon
                                      titleAccess="Edit"
                                      aria-label="edit"
                                      fontSize="small"
                                      onClick={() => handleEditCourse(row)}
                                    />
                                  </span>
                                ) : null}

                                {getPermission().test.custom ? (
                                  row.status === "draft" ? (
                                    <span>
                                      <DoneIcon
                                        titleAccess="Publish"
                                        onClick={() =>
                                          updateStatus("publish", row)
                                        }
                                        style={{ color: green[500] }}
                                        aria-label="publish"
                                        fontSize="small"
                                      />
                                    </span>
                                  ) : (
                                    <span>
                                      <CloseIcon
                                        className="text-danger"
                                        titleAccess="Draft"
                                        onClick={() =>
                                          updateStatus("draft", row)
                                        }
                                        aria-label="draft"
                                        fontSize="small"
                                      />
                                    </span>
                                  )
                                ) : null}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className="paginationLabel">
                  Showing {(page - 1) * rowCount + 1} to{" "}
                  {(page - 1) * rowCount +
                    (dailyTestResult &&
                    dailyTestResult.dailyTest &&
                    dailyTestResult.dailyTest.length
                      ? dailyTestResult.dailyTest.length
                      : 0)}{" "}
                  of{" "}
                  {dailyTestResult.totalDailyTestCount
                    ? dailyTestResult.totalDailyTestCount
                    : 0}{" "}
                  entries
                </div>
                <div className="root">
                  <Pagination
                    count={
                      dailyTestResult && dailyTestResult.totalDailyTestCount
                        ? Math.ceil(
                            dailyTestResult.totalDailyTestCount / rowCount
                          )
                        : 0
                    }
                    page={page}
                    onChange={handlePageChange}
                    variant="outlined"
                  />
                </div>
              </Paper>
            </div>

            <Dialog
              open={open}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{`Do you want to ${type} this test?`}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {type === "publish"
                    ? "By clicking publish button this test will be listout in aspirant app."
                    : "By clicking draft button, this test will be disappear from aspirant app."}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="secondary">
                  Close
                </Button>
                <Button onClick={handleAgree} color="primary">
                  {type === "publish" ? "PUBLISH" : "DRAFT"}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    </main>
  );
};

DailyTest.propTypes = {
  fetchDailyTest: propTypes.func,
  dailyTestResult: propTypes.object,
  updateTestStatus: propTypes.func,
};

const mapStateToProps = (state) => {
  return {
    dailyTestResult: state.fetchDailyTestReducer.dailyTest,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDailyTest: () => dispatch(fetchDailyTest(data)),
    updateTestStatus: () => dispatch(updateTestStatus(updateReqData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DailyTest);
