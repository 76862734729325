import axios from "axios";
import config from '../../config';
import { toast } from "react-toastify";
const {
  FETCH_MENTORSHIP_REQUEST,
  FETCH_MENTORSHIP_SUCCESS,
  FETCH_MENTORSHIP_FAILURE,
  CREATE_MENTORSHIP_REQUEST,
  CREATE_MENTORSHIP_SUCCESS,
  CREATE_MENTORSHIP_FAILURE,
  FETCH_PURCHASED_USERS_REQUEST,
  FETCH_PURCHASED_USERS_SUCCESS,
  FETCH_PURCHASED_USERS_FAILURE,
} = require("./mentorship-types");
export const fetchMentorships = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(fetchMentorshipsRequest());
    axios
      .get(config.API_BASE_URL + "/mentorship/get-mentorship-programs", {
        params: {
          skip: data.skip,
          limit: data.limit,
          keyword: data.keyword,
        },
      })
      .then((response) => {
        const mentorships = response.data;
        dispatch(fetchMentorshipsSuccess(mentorships));
      })
      .catch((error) => {
        toast.error("Failed to get mentorships. Something went wrong.");
        dispatch(fetchMentorshipsFailure(error.message));
      });
  };
};

export const fetchMentorshipsRequest = () => {
  return {
    type: FETCH_MENTORSHIP_REQUEST,
  };
};

export const fetchMentorshipsSuccess = (mentorships) => {
  return {
    type: FETCH_MENTORSHIP_SUCCESS,
    payload: mentorships,
  };
};

export const fetchMentorshipsFailure = (error) => {
  return {
    type: FETCH_MENTORSHIP_FAILURE,
    payload: error,
  };
};

export const createMentorship = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(createMentorshipRequest());
    axios
      .post(config.API_BASE_URL + "/mentorship/create-edit-mentorship", data)
      .then((response) => {
        const result = response.data;
        if (result.success) {
          if (data._id) {
            toast.success("Mentorship updated successfully.");
          } else {
            toast.success("Mentorship created successfully.");
          }
          dispatch(createMentorshipSuccess(result));
        }
      })
      .catch((error) => {
        if (data._id) {
          toast.error("Failed to update mentorship. Something went wrong.");
        } else {
          toast.error("Failed to create mentorship. Something went wrong.");
        }
        dispatch(createMentorshipFailure(error.message));
      });
  };
};

export const createMentorshipRequest = () => {
  return {
    type: CREATE_MENTORSHIP_REQUEST,
  };
};

export const createMentorshipSuccess = (result) => {
  return {
    type: CREATE_MENTORSHIP_SUCCESS,
    payload: result,
  };
};

export const createMentorshipFailure = (error) => {
  return {
    type: CREATE_MENTORSHIP_FAILURE,
    payload: error,
  };
};
export const fetchUserPurchased = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(fetchUserPurchasedRequest());
    axios
      .get(config.API_BASE_URL + "/mentorship/purchased-users", {
        params: {
          skip: data.skip,
          limit: data.limit,
          keyword: data.keyword,
        },
      })
      .then((response) => {
        const mentorships = response.data;
        dispatch(fetchUserPurchasedSuccess(mentorships));
      })
      .catch((error) => {
        toast.error("Failed to get mentorships. Something went wrong.");
        dispatch(fetchUserPurchasedFailure(error.message));
      });
  };
};
export const fetchUserPurchasedRequest = () => {
  return {
    type: FETCH_PURCHASED_USERS_REQUEST,
  };
};
export const fetchUserPurchasedSuccess = (data) => {
  return {
    type: FETCH_PURCHASED_USERS_SUCCESS,
    payload: data,
  };
};
export const fetchUserPurchasedFailure = (error) => {
  return {
    type: FETCH_PURCHASED_USERS_FAILURE,
    payload: error,
  };
}
