import React, {useEffect, useState} from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {connect} from "react-redux";
import {fetchApprovedQuestions} from "../../redux/questions/questions-action";
import propTypes from "prop-types";
import Pagination from "@material-ui/lab/Pagination";
import "./approved-question.css";
import Grid from "@material-ui/core/Grid";
import FlagIcon from '@material-ui/icons/Flag';
import EditIcon from "@material-ui/icons/Edit";
import ActionPopup from "../../components/popup/action-popup";
import QuestionPopup from "../../components/popup/question-popup";
import PostAddIcon from "@material-ui/icons/PostAdd";
import GuidanceNote from "../../components/popup/guidance-note";
import DeleteIcon from "@material-ui/icons/Delete";
import VideoLink from "../../components/popup/video-link";

let data = {
  skip: 0,
  limit: 10,
  keyword: "",
};

function ApprovedQuestions({approvedQuestions, fetchApprovedQuestions, editQuestionResult}) {
  const [page, setPage] = useState(1);
  const [rowCount, setRowCount] = useState(10);
  const [show, setShow] = useState({draft: false, edit: false});
  const [rowData, setRowData] = useState(null);
  const [guidanceNote, setGuidanceNote] = useState({show: false, questionId: '', note: ''});
  const [modal, setModal] = useState(false);
  const [questionData, setQuestionData] = useState({type: '', data: ''});

  useEffect(() => {
    fetchApprovedQuestions(data);
  }, [fetchApprovedQuestions, editQuestionResult]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    data.skip = (newPage - 1) * rowCount;
    fetchApprovedQuestions(data);
  };

  const searchQuestion = (event) => {
    data.skip = 0;
    data.keyword = event.target.value;
    setPage(1);
    fetchApprovedQuestions(data);
  };

  const handleRowsPerPage = (event) => {
    setRowCount(event.target.value);
    setPage(1);
    data.limit = event.target.value;
    data.skip = 0;
    fetchApprovedQuestions(data);
  };

  const handleOpen = (e, row) => {
    setRowData(row);
    setShow({...show, draft: true});
  }
  const handleClose = () => {
    setShow({edit: false, draft: false});
    fetchApprovedQuestions(data);
  };
  const handleEdit = (row) => {
    setShow({...show, edit: true})
    setRowData(row)
  }

  const handleDelete = (e, row) => {
    e.preventDefault();
    setQuestionData({type: 'deleteQuestion', data: row});
    setModal(true);
  }

  const addGuidanceNote = (row) => {
    setGuidanceNote({show: true, questionId: row.questionId, note: row.guidanceNote})
  }
  const closeGuidanceNote = () => {
    setGuidanceNote({show: false, questionId: '', note: ''});
  }
  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Approved Questions</h1>
            <nav
              className="breadcrumb-container d-none d-sm-block d-lg-inline-block"
              aria-label="breadcrumb"
            >
              <ol className="breadcrumb pt-0">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
              </ol>
            </nav>
            <div className="separator mb-5"></div>
            <div className="tableBody">
              <div className="table-filter-div">
                <Grid item xs={6}>
                  <div className="search-div">
                    <input
                      type="text"
                      className="search"
                      onKeyUp={searchQuestion}
                      placeholder="Search questions.."
                    ></input>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="rowCount-div">
                    <label>Questions per page : </label>
                    <select className="selection" onChange={handleRowsPerPage}>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </Grid>
              </div>
              <Paper className="paperBody">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="tableHead" align="center">
                          Sl.No
                        </TableCell>
                        <TableCell className="tableHead">Question</TableCell>
                        <TableCell className="tableHead">Options</TableCell>
                        <TableCell className="tableHead">
                          Correct Answer
                        </TableCell>
                        <TableCell className="tableHead">Subject</TableCell>
                        <TableCell className="tableHead">Topic</TableCell>
                        <TableCell className="tableHead actionsMinWidth" align="center">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {approvedQuestions &&
                        approvedQuestions.questions &&
                        approvedQuestions.questions.map((row, index) => {
                          return (
                            <TableRow hover key={row.questionId}>
                              <TableCell
                                className="tableColData"
                                align="center"
                              >
                                {rowCount * (page - 1) + index + 1}
                              </TableCell>
                              <TableCell className="tableColData">
                                {row.question.split('\n').map(ele => <p key={ele}>{ele}</p>)}
                              </TableCell>
                              <TableCell className="tableColData">
                                {row.options.map((opt, index) => {
                                  return (
                                    <div key={index}>
                                      {opt.optionIndex} - {opt.option}
                                    </div>
                                  );
                                })}
                              </TableCell>
                              <TableCell className="tableColData">
                                {row.correctAnswer.optionIndex} - {" "}
                                {row.correctAnswer.option}
                              </TableCell>
                              <TableCell className="tableColData">
                                {row.subjectName}
                              </TableCell>
                              <TableCell className="tableColData">
                                {row.topicName}
                              </TableCell>
                              <TableCell className="tableColData" align="center" width="130px">

                                <FlagIcon className="point" fontSize="small" onClick={(e) => handleOpen(e, row)}
                                          key={index} titleAccess="flag"/>

                                <EditIcon className="edit " fontSize="small" titleAccess="Edit"
                                          onClick={() => handleEdit(row)}/>

                                <PostAddIcon
                                  className="edit"
                                  titleAccess="Guidance Note"
                                  color="primary"
                                  onClick={() => addGuidanceNote(row)}
                                />
                                <DeleteIcon color="secondary" className="delete" titleAccess="Delete"
                                            onClick={(ev) => handleDelete(ev, row)}/>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                    {show.draft ? (<ActionPopup onClose={() => handleClose()}
                                                open={show.draft}
                                                row={rowData}/>) : null}
                    {show.edit &&
                      <QuestionPopup questionData={rowData} open={show.edit} onClose={() => handleClose()}/>}
                  </Table>
                </TableContainer>
                <div className="paginationLabel">
                  Showing {(page - 1) * rowCount + 1} to{" "}
                  {(page - 1) * rowCount +
                    (approvedQuestions && approvedQuestions.questions
                      ? approvedQuestions.questions.length
                      : 0)}{" "}
                  of{" "}
                  {approvedQuestions.totalQuestions
                    ? approvedQuestions.totalQuestions
                    : 0}{" "}
                  entries
                </div>
                <div className="root">
                  <Pagination
                    count={
                      approvedQuestions && approvedQuestions.totalQuestions
                        ? Math.ceil(approvedQuestions.totalQuestions / rowCount)
                        : 0
                    }
                    page={page}
                    onChange={handlePageChange}
                    variant="outlined"
                  />
                </div>
              </Paper>
            </div>
            {guidanceNote.show &&
              <GuidanceNote
                onClose={closeGuidanceNote}
                question={guidanceNote}
              />}
          </div>
        </div>
      </div>
      {modal ? <VideoLink open={modal} onClose={() => {
        setModal(false);
        fetchApprovedQuestions(data);
      }} type={questionData.type} videoData={questionData.data}/> : null}
    </main>
  );
}

ApprovedQuestions.propTypes = {
  fetchApprovedQuestions: propTypes.func,
  approvedQuestions: propTypes.object,
  editQuestionResult: propTypes.object
};

const mapStateToProps = (state) => {
  return {
    approvedQuestions: state.questionApproveReducer.questions,
    editQuestionResult: state.editQuestionReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApprovedQuestions: () => dispatch(fetchApprovedQuestions(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApprovedQuestions);
