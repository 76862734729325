import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import propTypes from "prop-types";
import Modal from "@material-ui/core/Modal";
import { imageValidation, convertBase64 } from "../../util/helper";
import { getSubjects } from "../../redux/course/course-action";
import { createTopic } from "../../redux/topic/topic-action";
import { Checkbox } from "@material-ui/core";
import config from '../../config';
let initializeTopic = {
  type: "",
  subject: "",
  name: "",
  description: "",
  image: "",
  id: "",
  imageUpdate:"",
  subjectId: ""
};
let initializeErrors = {
  type: "",
  subject: "",
  name: "",
  description: "",
  imageKey: "",
};
const TopicPopup = (props) => {
  const {isEditTopic, editTopicData} = props
  const [image, setImage] = useState("");
  const [form, setForm] = useState(initializeTopic);
  const [errors, setErrors] = useState(initializeErrors);
  const ref = React.useRef();
  const dispatch = useDispatch();
  const reset = () => {
    initializeErrors = {
      type: "",
      subject: "",
      name: "",
      description: "",
      imageKey: "",
    };
    setErrors(initializeErrors);
  };
  useEffect(() => {
    if(!isEditTopic){
      reset();
    }else{
      const {name, _id: id, subjectName: subject, type, description } = editTopicData;
      setForm({name, description, id, type, subject})
    }
    reset();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const handleFormInputChange = (e) => {
    e.persist();
    const { name, value, checked } = e.target;
    setForm((form) => ({
      ...form,
      [name]: value,
    }));
    if (name === "type" && value.length > 0) {
      const params = {
        type: value,
      };
      dispatch(getSubjects(params));
    }
    if (value !== "") {
      setErrors((errors) => ({
        ...errors,
        [name]: "",
      }));
    }
    if(name === 'imageUpdate'){
      setForm({
          ...form,
          imageUpdate: checked
      })
    }
  };
  const handleImageChange = async (e) => {
    const files = e.target.files[0];
    if (files) {
      if (imageValidation(files.name) !== -1) {
        const base64 = await convertBase64(files);
        setImage(base64);
        setErrors((errors) => ({
          ...errors,
          imageKey: "",
        }));
      } else {
        setErrors((errors) => ({
          ...errors,
          imageKey: "*PNG,JPG,JPEG format files only*.",
        }));
      }
    }
  };

  const validate = () => {
    let flag = true;
    let input = form;
    if (input.type === "" || !input.type) {
      flag = false;
      initializeErrors.type = "Please select a subject type";
    }
    if (input.subject === "" || !input.subject) {
      flag = false;
      initializeErrors.subject = "Please select a subject";
    }
    if (input.description === "" || !input.description) {
      flag = false;
      initializeErrors.description = "Description must not be empty";
    }
    if (input.name === "" || !input.name) {
      flag = false;
      initializeErrors.name = "Name must not be empty";
    }
    if (image === "" || !image) {
      if(!isEditTopic || form.imageUpdate){
        flag = false;
        initializeErrors.imageKey = "Please upload an image";
      }
    }
    setErrors((errors) => ({
      ...errors,
      ...initializeErrors,
    }));
    return flag;
  };

  const handleCreate = (e) => {
    e.preventDefault();
    if (validate()) {
      const fieldsToDelete = ['type', 'subject', 'imageUpdate', 'id']
      if(isEditTopic){
        let updateForm = {...form, _id: form.id} 
        if(form.imageUpdate){
          updateForm = {...updateForm, image };
        }
        fieldsToDelete.map(field => delete updateForm[field])
        dispatch(createTopic(updateForm));
      }else{
        form.image = image;
        form.subjectId = form.subject;
        fieldsToDelete.map(field => delete form[field])
        dispatch(createTopic(form));
      }
      props.onClose();
    }
  };
  const subjectsState = useSelector((state) => state.getCourseSubjectsReducer);
  return (
    <Modal open={props.open} className="modal">
      <div className="paper">
        <h2 className="mb-4">{isEditTopic ? 'Edit' : 'Create'} Topic</h2>
        <label className="form-group has-float-label mb-4">
          <select
            data-width="100%"
            className="form-control select2-single"
            value={form.type}
            onChange={(e) => handleFormInputChange(e)}
            name="type"
            disabled={isEditTopic}
          >
            <option disabled value="">
              Select Subject Type
            </option>
            <option value="all">All</option>
            <option value="course">Course</option>
            <option value="prelim">Prelim</option>
            <option value="mains">Mains</option>
          </select>
          <span className="error">{errors.type}</span>
          <span>Subject Type</span>
        </label>
        <label className="form-group has-float-label mb-4">
          <select
            data-width="100%"
            className="form-control select2-single"
            value={form.subject}
            onChange={(e) => handleFormInputChange(e)}
            name="subject"
            disabled={isEditTopic}
          >
            <option disabled value="">
              Select Subject
            </option>
            {isEditTopic ? 
            <option >{form.subject}</option>
            :subjectsState.subjects.length &&
              subjectsState.subjects.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <option value={item._id}>{item.name}</option>
                  </React.Fragment>
                );
              })}
          </select>
          <span className="error">{errors.subject}</span>
          <span>Subject</span>
        </label>
        <label className="form-group has-float-label mb-4">
          <input
            className="form-control"
            name="name"
            value={form.name}
            onChange={(e) => handleFormInputChange(e)}
          />
          <span className="error">{errors.name}</span>
          <span>Topic Name</span>
        </label>

        <label className="form-group has-float-label">
          <textarea
            className="form-control"
            name="description"
            value={form.description}
            onChange={(e) => handleFormInputChange(e)}
          />
          <span className="error">{errors.description}</span>
          <span>Description</span>
        </label>

        {isEditTopic && 
          <React.Fragment>
          <label className="form-group has-float-label mb-4">
              <p>Current Image: </p>
              {isEditTopic && <img className="thumbnail" 
              src={`${config.SPACE_URL}${editTopicData.imageKey}`} alt="thumbnail"></img> }
          </label>
          <label className="">
              <span>Update Image: 
              <Checkbox value={form.imageUpdate} name="imageUpdate"
              onChange={(e) => handleFormInputChange(e)
              }
              />
              </span>
          </label>
          </React.Fragment>
        }

        {!isEditTopic || form.imageUpdate ?
        <label className="form-group has-float-label mb-4">
          <input
            type="file"
            ref={ref}
            className="form-control"
            onChange={(e) => handleImageChange(e)}
            onClick={(e) => (e.target.value = null)}
            value={form.imageKey}
            accept="image/png image/jpeg image/jpg"
          />
          <span className="error">{errors.imageKey}</span>
          <span>Topic Image</span>
        </label>: null}

        <div className="align-items-right text-right mt-4">
          <button
            type="button"
            onClick={props.onClose}
            className="btn btn-danger btn-lg btn-shadow mr-3"
          >
            CANCEL
          </button>
          <button
            type="button"
            onClick={handleCreate}
            className="btn btn-success btn-lg btn-shadow"
          >
            SAVE
          </button>
        </div>
      </div>
    </Modal>
  );
};
TopicPopup.propTypes = {
  open: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  isEditTopic: propTypes.bool.isRequired,
  editTopicData: propTypes.object
};
export default TopicPopup;
