import axios from "axios";
import {
  FETCH_TOPICS_REQUEST,
  FETCH_TOPICS_SUCCESS,
  FETCH_TOPICS_FAILURE,
  CREATE_TOPIC_REQUEST,
  CREATE_TOPIC_SUCCESS,
  CREATE_TOPIC_FAILURE
} from "./topic-types";
import config from '../../config';
import { toast } from "react-toastify";

export const fetchTopics = (params) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(topicRequest());
    axios
      .get(config.API_BASE_URL + "/topic/topic-list", {
        params: {
          skip: params.skip,
          limit: params.limit,
          keyword: params.keyword,
        },
      })
      .then((response) => {
        dispatch(topicsSuccess(response.data));
      })
      .catch((error) => {
        toast.error(error.message);
        dispatch(topicsFailure(error.message));
      });
  };
};

export const topicRequest = () => {
  return {
    type: FETCH_TOPICS_REQUEST,
  };
};

export const topicsSuccess = (data) => {
  return {
    type: FETCH_TOPICS_SUCCESS,
    payload: data,
  };
};

export const topicsFailure = (data) => {
  return {
    type: FETCH_TOPICS_FAILURE,
    payload: data,
  };
};

export const createTopic=(params)=>{
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(createTopicRequest());
    axios
      .post(config.API_BASE_URL + "/topic/create-topic", params)
      .then((response) => {
        if(response.data.success){
        dispatch(createTopicSuccess(response.data));
        !params.isUpdate ?
          toast.success("Topic created successfully")
          : toast.success("Topic updated successfully");
        }
      })
      .catch((error) => {
        toast.error(error.message);
        dispatch(createTopicFailure(error.message));
      });
  };
}

export const createTopicRequest=()=>{
   return{
     type: CREATE_TOPIC_REQUEST
   }
}

export const createTopicSuccess=(data)=>{
  return{
    type: CREATE_TOPIC_SUCCESS,
    payload:data
  }
}

export const createTopicFailure=(data)=>{
   return{
    type: CREATE_TOPIC_FAILURE,
    payload:data
  }
}
