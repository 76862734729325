import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { uploadQuestions } from "../../redux/questions/questions-action";
import "../../pages/Question/approved-question.css";
const FileUploadPopup = (props) => {
  const dispatch = useDispatch();
  const [questionFile, setQuestionFile] = useState(null);
  const useStyles = makeStyles((theme) => ({
    root: {
      height: 300,
      flexGrow: 1,
      minWidth: 300,
      transform: "translateZ(0)",
      "@media all and (-ms-high-contrast: none)": {
        display: "none",
      },
    },
    modal: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(1),
      justifyContent: "center",
    },
    paper: {
      width: "56%",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      marginTop: "6%",
    },
  }));
  const classes = useStyles();
  const handleFileChange = (e) => {
    setQuestionFile((prevState) => ({
      ...prevState,
      questionFile: e.target.files[0],
    }));
  };
  const resultData = useSelector((state) => state.questionUploadReducer);
  const handleFileSubmit = (e) => {
    e.preventDefault();
    if (questionFile) {
      dispatch(uploadQuestions(questionFile));
      if (resultData.data.success) {
        handleClose(true);
      } else {
        handleClose(false);
      }
    } else {
      handleClose(false);
    }
  };
  const handleClose = (value) => {
    props.onClose(value);
  };
  return (
    <React.Fragment>
      <Modal open={props.open} className={classes.modal}>
        <div className={classes.paper}>
          <h2 id="server-modal-title">Questions Upload</h2>
          <p className="text-danger" id="server-modal-description">
            *Please upload only excel files
          </p>
          <input
            type="file"
            className="form-control"
            onChange={handleFileChange}
            id="question"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
          <div className="align-items-right text-right mt-3">
            <button
              className="btn btn-secondary mr-2"
              type="button"
              onClick={() => handleClose("close")}
            >
              Close
            </button>
            <button
              className="btn btn-primary"
              type="button"
              onClick={handleFileSubmit}
            >
              Upload
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};
FileUploadPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  status: PropTypes.bool.isRequired,
};
export default FileUploadPopup;
