const initialState = {
  loading: true,
  data: {},
  error: "",
};

const faqReducer=(state=initialState,action)=>{
  switch (action.type) {
    case "FETCH_QUESTION_FAQ_REQUEST":
      return{
        ...state,
        loading:true
      }
    case "FETCH_QUESTION_FAQ_SUCCESS":
      return{
        loading:false,
        data:action.payload,
        error:""
      }
      case "FETCH_QUESTION_FAQ_FAILURE":
        return{
          loading:false,
          data:"",
          error:action.payload
        }
    default:
      return state;
  }

}

const createEditFaqReducer=(state=initialState,action)=>{

  switch (action.type) {
    case "CREATE_EDIT_FAQ_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "CREATE_EDIT_FAQ_SUCCESS":
      return{
        loading:false,
        data:action.payload,
        error:""
      }
    case "CREATE_EDIT_FAQ_FAILURE":
      return{
        loading:false,
        data:"",
        error:action.payload
      }
    default:
      return state;
  }

}
const createEditFaqVideoReducer=(state=initialState,action)=>{

  switch (action.type) {
    case "CREATE_EDIT_FAQ_VIDEO_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "CREATE_EDIT_FAQ_VIDEO_SUCCESS":
      return{
        loading:false,
        data:action.payload,
        error:""
      }
    case "CREATE_EDIT_FAQ_VIDEO_FAILURE":
      return{
        loading:false,
        data:"",
        error:action.payload
      }
    default:
      return state;
  }

}
export { faqReducer, createEditFaqReducer , createEditFaqVideoReducer};
