import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Table,
  TableContainer,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { Capitalize } from "../../util/helper";
import config from "../../config";
import propTypes from "prop-types";
import CreateArticleImage from "./create-article-image";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { createArticleImage } from "../../redux/article/article-action";
import { fetchArticleImages } from "../../redux/article/article-action";
import { connect } from "react-redux";
let data = {
  skip: 0,
  limit: 10,
};

const ArticleImages = ({
  imagesResult,
  fetchAllImages,
  editImageResult,
  editImageStatus,
}) => {
  const [page, setPage] = useState(1);
  const [rowCount, setRowCount] = useState(10);
  const [imageModal, setImageModal] = useState(false);
  const [statusPopup, setStatusPopup] = useState(false);
  const [imageData, setImageData] = useState({});
  const [action, setAction] = useState("");

  useEffect(() => {
    fetchAllImages(data);
  }, [editImageResult]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRows = (event) => {
    setRowCount(event.target.value);
    setPage(1);
    data.limit = event.target.value;
    data.skip = 0;
    fetchAllImages(data);
  };

  const handlePages = (_, nextPage) => {
    setPage(nextPage);
    data.skip = (nextPage - 1) * rowCount;
    fetchAllImages(data);
  };

  const handleCreateImage = () => setImageModal(true);

  const handleCloseImage = () => setImageModal(false);

  const closeImageStatusPopup = () => {
    setImageModal(false);
    setStatusPopup(false);
  };

  const handleStatus = (row, action) => {
    setStatusPopup(true);
    setImageData(row);
    setAction(action);
  };

  const handleClose = (e, action) => {
    e.stopPropagation();
    if (action === "published" || action === "draft") {
      let param = {
        status: action,
        _id: imageData._id,
      };
      editImageStatus(param);
    }
    closeImageStatusPopup();
  };

  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Article Images</h1>
            <nav
              className="breadcrumb-container d-none d-sm-block d-lg-inline-block"
              aria-label="breadcrumb"
            >
              <ol className="breadcrumb pt-0">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
              </ol>
            </nav>
            <div className="separator mb-5">
              <div className=" ml-2 mb-3">
                <button
                  className="btn btn-outline-primary mr-3"
                  type="button"
                  onClick={handleCreateImage}
                >
                  UPLOAD IMAGE
                </button>
              </div>
              {imageModal ? (
                <CreateArticleImage
                  open={imageModal}
                  onClose={handleCloseImage}
                />
              ) : null}

              <div className="tableBody">
                <div className="table-filter-div">
                  <Grid item xs={6}></Grid>
                  <Grid item xs={6}>
                    <div className="rowCount-div">
                      <label>Articles per page : </label>
                      <select className="selection" onChange={handleRows}>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </Grid>
                </div>
                <Paper className="paperBody">
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="tableHead" align="center">
                            Sl.No
                          </TableCell>
                          <TableCell className="tableHead">Image</TableCell>
                          <TableCell className="tableHead">Status</TableCell>
                          <TableCell className="tableHead">Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {imagesResult &&
                          imagesResult.images &&
                          imagesResult.images.map((row, i) => (
                            <TableRow hover key={row._id}>
                              <TableCell
                                className="tableColData"
                                align="center"
                              >
                                {rowCount * (page - 1) + i + 1}
                              </TableCell>
                              <TableCell className="tableColData">
                                <img
                                  className="thumbnail"
                                  src={config.SPACE_URL + row.key}
                                  alt="thumbnail"
                                ></img>
                              </TableCell>
                              <TableCell className="tableColData">
                                {Capitalize(row.status)}
                              </TableCell>
                              <TableCell className="tableColData">
                                <span className="mr-1">
                                  {row.status === "draft" ? (
                                    <DoneIcon
                                      className="publish text-success"
                                      fontSize="small"
                                      titleAccess="Publish"
                                      onClick={() =>
                                        handleStatus(row, "published")
                                      }
                                    />
                                  ) : null}
                                  {row.status === "published" ? (
                                    <CloseIcon
                                      className="draft text-danger"
                                      fontSize="small"
                                      titleAccess="Draft"
                                      onClick={() => handleStatus(row, "draft")}
                                    />
                                  ) : null}
                                </span>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div className="paginationLabel">
                    Showing {(page - 1) * rowCount + 1} to{" "}
                    {(page - 1) * rowCount +
                      (imagesResult && imagesResult.images
                        ? imagesResult.images.length
                        : 0)}{" "}
                    of {imagesResult.totalImages ? imagesResult.totalImages : 0}{" "}
                    images
                  </div>
                  <div className="root">
                    <Pagination
                      count={
                        imagesResult && imagesResult.totalImages
                          ? Math.ceil(imagesResult.totalImages / rowCount)
                          : 0
                      }
                      page={page}
                      onChange={handlePages}
                      variant="outlined"
                    />
                  </div>
                </Paper>
              </div>
              {statusPopup ? (
                <Dialog
                  open={statusPopup}
                  onClose={closeImageStatusPopup}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {`Do you want to ${
                      action === "published" ? "publish" : "draft"
                    } this image?`}
                  </DialogTitle>
                  <DialogActions>
                    <Button
                      variant="text"
                      onClick={(e) => handleClose(e, "close")}
                      id="close"
                      color="secondary"
                    >
                      Close
                    </Button>
                    <Button
                      variant="text"
                      id="approve"
                      onClick={(e) => handleClose(e, action)}
                      color="primary"
                    >
                      {action === "published" ? "Publish" : "Draft"}
                    </Button>
                  </DialogActions>
                </Dialog>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

ArticleImages.propTypes = {
  fetchAllImages: propTypes.func,
  editImageStatus: propTypes.func,
  imagesResult: propTypes.object,
  editImageResult: propTypes.object,
};

const mapStateToProps = (state) => {
  return {
    imagesResult: state.fetchArticleImagesReducer.data || {},
    editImageResult: state.createArticleImageReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllImages: () => dispatch(fetchArticleImages(data)),
    editImageStatus: (data) => dispatch(createArticleImage(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleImages);
