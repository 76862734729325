import React, { useState } from "react";
import { Route, Redirect } from "react-router-dom";
import propTypes from "prop-types";
import { isLogin } from "../util/auth";
import Header from "../components/layouts/header-component";
import SideMenu from "../components/layouts/side-menu";
import Footer from "../components/layouts/footer-component";

function PrivateRoutes({ component: Component, ...rest }) {
  const [openMenu, setOpenMenu] = useState(true);
  const handleMenu = () => {
    if (openMenu) {
      setOpenMenu(false);
    } else {
      setOpenMenu(true);
    }
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin() ? (
          <div>
            <Header handleMenu={handleMenu} />
            {openMenu ? <SideMenu /> : null}
            <div className={!openMenu ? "menuHide" : null}>
              <Component {...props} />
            </div>
            <Footer />
          </div>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}
PrivateRoutes.propTypes = {
  component: propTypes.any,
  rest: propTypes.any,
};

export default PrivateRoutes;
