import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import "../../pages/Question/approved-question.css";
import { useDispatch, useSelector } from 'react-redux';
import { uploadPrelimTestSyllabus } from "../../redux/exam/exam-action";
import { convertBase64 } from "../../util/helper";
import config from '../../config';

const SyllabusUploadPopup = (props) => {
    const [file, setFile] = useState("")
    const [error, seterror] = useState("")
    const dispatch = useDispatch();
    const result = useSelector(state => state.uploadPrelimTestSyllabusReducer)
    const useStyles = makeStyles((theme) => ({
        root: {
            height: 300,
            flexGrow: 1,
            minWidth: 300,
            transform: "translateZ(0)",
            "@media all and (-ms-high-contrast: none)": {
                display: "none",
            },
        },
        modal: {
            display: "flex",
            alignItems: "center",
            padding: theme.spacing(1),
            justifyContent: "center",
        },
        paper: {
            width: "56%",
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            marginTop: "6%",
        },
        error: {
            color: "red",
            fontStyle: "bold"
        },
        syllabus: {
            fontSize: "12px",
            color: "blue",
            fontStyle: "bold"
          }
    }));
    const classes = useStyles();
    const { row } = props;
    async function handleFileSubmit(e) {
        e.preventDefault();
        if (file) {
            const base64 = await convertBase64(file);
            dispatch(uploadPrelimTestSyllabus({ prelimTestId: row._id, syllabus: base64 }));
            if (result.success) {
                handleClose(true);
            } else {
                e.preventDefault();
                handleClose(false);
            }
        } else {
            seterror("Please select a file to upload");
        }
    }
    const handleClose = (value) => {
        props.onClose(value);
    };
    return (
        <React.Fragment>
            <Modal open={props.open} className={classes.modal}>
                <div className={classes.paper}>
                    <h2 id="server-modal-title">Syllabus Upload</h2>
                   {row.syllabus ? <div className="mb-2"><a href={config.SPACE_URL+row.syllabus} target="_blank" rel="noopener noreferrer" className={classes.syllabus}>Click here</a> to see the uploaded syllabus</div>  : null} 
                    <p className="text-danger" id="server-modal-description">
                        *Please upload only pdf files
          </p>
                    <input
                        type="file"
                        className="form-control"
                        id="syllabus"
                        onChange={(e) => setFile(e.target.files[0])}
                        accept=".pdf"
                    />
                    {
                        error && !file ? (<span className="error">{error}</span>) : null
                    }
                    <div className="align-items-right text-right mt-3">
                        <button
                            className="btn btn-secondary mr-2"
                            type="button"
                            onClick={() => handleClose("close")}
                        >
                            Close
            </button> 
                        <button
                            className="btn btn-primary"
                            type="button"
                            onClick={handleFileSubmit}
                        >
                            Upload
            </button>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    );
};
SyllabusUploadPopup.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    row: PropTypes.object.isRequired,
};
export default SyllabusUploadPopup;
