import React, { useState, useEffect } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import propTypes from "prop-types";
import {
  getSubjects,
  getCourse,
  editCourse,
  getSubjectTopics,
  createCourse,
} from "../../redux/course/course-action";
import "./course.css";
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';

let subjectId = "";
let courseId = "";
let courseData = {};
const params = {
  type: "course",
};
function CreateCourse({
  getSubjects,
  subjects,
  editCourse,
  createCourse,
}) {
  const history = useHistory();
  const location = useLocation();
  const initialErrors = {
    courseName: "",
    order: "",
    description: "",
    shortDescription: "",
    videoLink: "",
  };
  let initialSubjects = {
    subject: "",
    topic: "",
    error: "",
  };
  let initialCourseFormState = {
    courseName: "",
    order: "",
    description: "",
    shortDescription: "",
    videoLink: "",
  };

  // Initialization
  const [courseForm, setForm] = useState(initialCourseFormState);
  const [errors, setErrors] = useState(initialErrors);
  let [usedSubjects, setSubjects] = useState([]);
  const [subjectfields, setFields] = useState([]);
  const [topicRepo, setTopics] = useState({});

  useEffect(() => {
    getSubjects();
    if (location.state && location.state.edit) {
      courseId = location.state.courseId;
      getCourseDetails();
    } else {
      setFields([initialSubjects]);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getMatchingTopics = (allTopics, courseTopics) => {
    let topic = [];
    for (let i = 0; i < courseTopics.length; i++) {
      loop2:
      for (let j = 0; j < allTopics.length; j++) {
        if (allTopics[j]._id === courseTopics[i]) {
          topic.push({ value: allTopics[j]._id, label: allTopics[j].name });
          break loop2;
        }
      }
    }
    return topic;
  }

  const getCourseDetails = async () => {
    initialCourseFormState = await getCourse(courseId);
    setForm(initialCourseFormState);
    let boxes = [];
    if (initialCourseFormState.subjects.length > 0) {
      for (let i = 0; i < initialCourseFormState.subjects.length; i++) {
        usedSubjects.push({ id: initialCourseFormState.subjects[i] });
        topicRepo[i] = await getSubjectTopics(initialCourseFormState.subjects[i]);
        setTopics(topicRepo)
        let result = getMatchingTopics(topicRepo[i], initialCourseFormState.topics);
        let initialBoxValue = {
          subject: initialCourseFormState.subjects[i],
          topic: result,
          error: "",
        };
        boxes.push(initialBoxValue);
      }
      addBox(boxes);
    } else {
      setFields([initialSubjects]);
    }
  }

  // Add mutiple select boxex incase of edit.
  function addBox(val) {
    setFields(val);
  }

  // When subject changes in select box.
  async function handleSubjectChange(i, event) {
    const values = [...subjectfields];
    const { name, value } = event.target;
    if (name === "subject") {
      const result = usedSubjects.filter(
        (item) => item.id === values[i]["subject"]
      );
      if (result.length > 0) {
        usedSubjects = usedSubjects.filter(
          (item) => item.id !== values[i]["subject"]
        );
        setSubjects(usedSubjects);
      }
      usedSubjects.push({ name: name, id: value });
      setSubjects(usedSubjects);
      subjectId = value;
      topicRepo[i] = await getSubjectTopics(subjectId);
      setTopics(topicRepo);
    }
    if (values[i]["topic"] && values[i]["topic"].length > 0) {
      values[i]["topic"] = "";
    }
    values[i][name] = value;
    setFields(values);
  }

  // Add select box when click on plus icon
  function handleAdd() {
    const values = [...subjectfields];
    values.push({ subject: "", topic: "", error: "" });
    setFields(values);
  }

  // Remove select boxes when click on delete icon
  function handleRemove(i) {
    const values = [...subjectfields];
    const sub = values[i]["subject"];
    usedSubjects = usedSubjects.filter((item) => item.id !== sub);
    setSubjects(usedSubjects);
    values.splice(i, 1);
    setFields(values);
  }

  // Handle create/edit api when click on save button.
  function saveCourse() {
    const val = handleSelectBoxValidations();
    if (handleValidation() && val) {
      const result = subjectfields;
      const topics = [];
      const subjects = [];
      result.forEach((elm) => {
        delete elm.error;
        subjects.push(elm.subject);
        const topicResult = elm.topic.map((item) => item.value);
        topics.push(...topicResult);
        elm.topic = topicResult;
      });
      courseData = {
        courseName: courseForm.courseName,
        description: courseForm.description,
        shortDescription: courseForm.shortDescription,
        vimeoId: courseForm.videoLink,
        subjects,
        topics,
        order: courseForm.order
      };
      if (location.state && location.state.edit) {
        courseData.courseId = courseId;
        editCourse(courseData);
      } else {
        createCourse(courseData);
      }
      history.push("/course/list");
    }
  }

  // Cancel course creation/edit
  function cancelCourse() {
    history.push("/course/list");
  }

  // When topic changes.
  function handleOptionChange(idx, opt) {
    const values = [...subjectfields];
    if (opt && opt.length > 0) {
      values[idx]["topic"] = opt.map((elm) => {
        return { value: elm.value, label: elm.label };
      });
      values[idx]["error"] = "";
    } else {
      values[idx]["topic"] = "";
      values[idx]["error"] = "Select atleast one subject and topic";
    }
    setFields(values);
  }

  // When enter items in text field.
  function handleFormInputChange(event) {
    event.persist();
    const { name, value } = event.target;
    if (courseForm[name] != "") {
      setErrors((errors) => ({
        ...errors,
        [name]: "",
      }));
    }
    if (name === "order") {
      if (value > 0 || value === "") {
        setForm((courseForm) => ({
          ...courseForm,
          [name]: value,
        }));
      }
    } else {
      setForm((courseForm) => ({
        ...courseForm,
        [name]: value,
      }));
    }
  }

  const handleChange = (value)=>{
    setForm((courseForm) => ({
      ...courseForm,
      'description': value,
    }));
    if(value === "" || value === null){
      setErrors((errors) => ({
        ...errors,
        'description': "Description should not be empty",
      }));
    }else{
      setErrors((errors) => ({
        ...errors,
        'description': "",
      }));
    }
  }

  // Validation: check the subject is already selected or not.
  function checkIsAlreadyAdded(item, id) {
    const value = [...subjectfields];
    const result = usedSubjects.filter(
      (elm) => elm.id === item._id && elm.id != value[id]["subject"]
    );
    if (result.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  // Validation: Except for selectboxes. Check whether the fields are empty or not.
  function handleValidation() {
    let isValid = true;
    let inputs = courseForm;
    if (inputs["courseName"] === "" || inputs["courseName"].replace(/ /g,'') === "") {
      initialErrors.courseName = "Course name should not be empty";
      isValid = false;
    }
    if (inputs["description"] === "" || inputs["description"].replace(/ /g,'') === "") {
      isValid = false;
      initialErrors.description = "Description should not be empty";
    }
    if (
      inputs["shortDescription"] === "" || inputs["shortDescription"].replace(/ /g,'') === "") {
      isValid = false;
      initialErrors.shortDescription = "Short description should not be empty";
    }
    if (inputs["videoLink"] === "" || inputs["videoLink"].replace(/ /g,'') === "") {
      isValid = false;
      initialErrors.videoLink = "Video Link should not be empty";
    }
    if (inputs["order"] === "" || inputs["order"] === 0) {
      isValid = false;
      initialErrors.order = "Order should not be empty";
    }
    setErrors(initialErrors);
    return isValid;
  }

  // Validation: For subject and topic select boxes.
  function handleSelectBoxValidations() {
    const values = [...subjectfields];
    let isValid = true;
    values.forEach((elm) => {
      if (
        elm.subject === null ||
        elm.subject === "" ||
        elm.topic.length === 0
      ) {
        isValid = false;
        elm.error = "Select atleast one subject and topic";
      }
    });
    setFields(values);
    return isValid;
  }

  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Courses</h1>
            <nav
              className="breadcrumb-container d-none d-sm-block d-lg-inline-block"
              aria-label="breadcrumb"
            >
              <ol className="breadcrumb pt-0">
                <li className="breadcrumb-item">
                  <a href="list">Home</a>
                </li>
                <li className="breadcrumb-item">
                  {location.state && location.state.edit ? (
                    <span>Edit</span>
                  ) : (
                      <span>Create</span>
                    )}
                </li>
              </ol>
            </nav>
            <div className="separator mb-5"></div>

            <div className="row">
              <div className="col-12">
                <div className="card mb-4">
                  <div className="card-body offset-2 col-8 mt-5">
                    <label className="form-group has-float-label mb-4">
                      <input
                        value={courseForm.courseName}
                        onChange={(e) => handleFormInputChange(e)}
                        className="form-control"
                        name="courseName"
                      />
                      <span className="error">{errors.courseName}</span>
                      <span>Name</span>
                    </label>

                    <label className="form-group has-float-label mb-4">
                      <ReactQuill value={courseForm.description} name="description" 
                        onChange={handleChange} />
                      <span className="error">{errors.description}</span>
                      <span>Description</span>
                    </label>

                    <label className="form-group has-float-label mb-4">
                      <textarea
                        maxLength="600"
                        value={courseForm.shortDescription}
                        onChange={(e) => handleFormInputChange(e)}
                        className="form-control"
                        name="shortDescription"
                      ></textarea>
                      <span className="note">Note: Maximum 200 characters are allowed. </span>
                      <span className="error">{errors.shortDescription}</span>
                      <span>Short Description</span>
                    </label>

                    <label className="form-group has-float-label mb-4">
                      <input type="tel"
                        value={courseForm.order}
                        onChange={(e) => handleFormInputChange(e)}
                        className="form-control"
                        name="order"
                      />
                      <span className="error">{errors.order}</span>
                      <span>Course Order</span>
                    </label>

                    <label className="form-group has-float-label mb-4">
                      <input
                        type="text"
                        value={courseForm.videoLink}
                        className="form-control"
                        onChange={(e) => handleFormInputChange(e)}
                        name="videoLink"
                      />
                      <span className="error">{errors.videoLink}</span>
                      <span>Sample video vimeo id</span>
                    </label>

                    <div>
                      <h6>Add subjects and topics to the course</h6>
                    </div>
                    {subjectfields.map((field, idx) => {
                      return (
                        <div key={`${field}-${idx}`} className="subjectRow">
                          <div className="row mb-3 mt-2">
                            <div className="col-9">
                              <select
                                value={subjectfields[idx].subject}
                                data-width="100%"
                                className="form-control select2-single"
                                onChange={(e) => handleSubjectChange(idx, e)}
                                name="subject"
                              >
                                <option disabled value="">
                                  Select subjects
                                </option>
                                {subjects.map((x, y) =>
                                  !checkIsAlreadyAdded(x, idx) ? (
                                    <option value={x._id} key={y}>
                                      {x.name}
                                    </option>
                                  ) : null
                                )}
                              </select>
                            </div>
                          </div>
                          <div className="row mb-3 mt-2">
                            <div className="col-9">
                              <Select
                                name="topic"
                                placeholder="Select topics"
                                value={subjectfields[idx].topic}
                                onChange={(e) => handleOptionChange(idx, e)}
                                isMulti={true}
                                options={
                                  topicRepo[idx]
                                    ? topicRepo[idx].map((elm) => {
                                      return {
                                        value: elm._id,
                                        label: elm.name,
                                      };
                                    })
                                    : null
                                }
                              />
                            </div>

                            {idx != 0 ? (
                              <div className="col-1 align-items-center">
                                <button
                                  type="button"
                                  className="btn btn-primary btn-sm btn-shadow"
                                  onClick={() => handleRemove(idx)}
                                >
                                  <DeleteIcon />
                                </button>
                              </div>
                            ) : null}

                            {subjectfields.length - 1 === idx ? (
                              <div className="col-2 align-items-center">
                                <button
                                  type="button"
                                  className="btn btn-primary btn-sm btn-shadow"
                                  onClick={() => handleAdd(idx)}
                                >
                                  <AddIcon />
                                </button>
                              </div>
                            ) : null}
                          </div>
                          <div className="errorTag">
                            {subjectfields[idx].error}
                          </div>
                        </div>
                      );
                    })}

                    <div className="align-items-right text-right mt-4">
                      <button
                        type="button"
                        onClick={cancelCourse}
                        className="btn btn-danger btn-lg btn-shadow mr-3"
                      >
                        CANCEL
                      </button>
                      <button
                        type="button"
                        onClick={saveCourse}
                        className="btn btn-success btn-lg btn-shadow"
                      >
                        SAVE
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

CreateCourse.propTypes = {
  getSubjects: propTypes.func,
  subjects: propTypes.array,
  editCourse: propTypes.func,
  createCourse: propTypes.func,
};

const mapStateToProps = (state) => {
  return {
    subjects: state.getCourseSubjectsReducer.subjects
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubjects: () => dispatch(getSubjects(params)),
    editCourse: () => dispatch(editCourse(courseData)),
    createCourse: () => dispatch(createCourse(courseData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCourse);
