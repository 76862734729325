export const FETCH_MENTORSHIP_REQUEST = "FETCH_MENTORSHIP_REQUEST";
export const FETCH_MENTORSHIP_SUCCESS = "FETCH_MENTORSHIP_SUCCESS";
export const FETCH_MENTORSHIP_FAILURE = "FETCH_MENTORSHIP_FAILURE";

export const CREATE_MENTORSHIP_REQUEST = "CREATE_MENTORSHIP_REQUEST";
export const CREATE_MENTORSHIP_SUCCESS = "CREATE_MENTORSHIP_SUCCESS";
export const CREATE_MENTORSHIP_FAILURE = "CREATE_MENTORSHIP_FAILURE";

export const FETCH_PURCHASED_USERS_REQUEST = "FETCH_PURCHASED_USERS_REQUEST";
export const FETCH_PURCHASED_USERS_SUCCESS = "FETCH_PURCHASED_USERS_SUCCESS";
export const FETCH_PURCHASED_USERS_FAILURE = "FETCH_PURCHASED_USERS_FAILURE";
