import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import propTypes from "prop-types";
import { editVideo } from '../../redux/video/video-action';
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import Radio from '@material-ui/core/Radio';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import axios from 'axios';
import config from '../../config';

const UploadVideo = ({ onClose, open, video }) => {
    const [uploadedVideo, setVideo] = useState({ title: video.title, videoFile: "", order: video.order >= 0 && video.order });
    const [errors, setError] = useState({ title: "", videoFile: "", order: "" });
    const [value, setRadioValue] = useState(video.isFree);
    const [buttonStatus, setButtonStatus] = useState(false);
    const [progress, setProgress] = useState(0);

    // Handling file input change.
    const handleFileChange = (event) => {
        setVideo((prev) => ({
            ...prev,
            videoFile: event.target.files[0]
        }));
        if (event.target.files[0] === "") {
            setError((prev) => ({
                ...prev,
                videoFile: "Video should not be empty"
            }));
        } else {
            setError((prev) => ({
                ...prev,
                videoFile: ""
            }));
        }
    }

    // Handling text feild change.
    const handleFormInputChange = (ev) => {
        const {name, value} = ev.target;
        if (name === "order") {
            if (value >= 0) {
                setVideo((prev) => ({
                    ...prev,
                    [name]: value
                }));
                setError((prev) => ({
                    ...prev,
                    [name]: ""
                })); 
            }else{
                setError((prev) => ({
                    ...prev,
                    [name]: "Video order is required and it should be greater than zero"
                }));  
            }
        } else {
            setVideo((prev) => ({
                ...prev,
                [name]: value
            }));
        }
        if (value === "" && name === "title") {
            setError((prev) => ({
                ...prev,
                [name]: "Video title should not be empty"
            }));
        } else {
            setError((prev) => ({
                ...prev,
                [name]: ""
            }));
        }
    }

    // Saving video.
    const saveVideo = async () => {
        if (validateForm()) {
            setButtonStatus(true);
            const form = new FormData();
            if(!video.edit){
                form.append("file", uploadedVideo.videoFile);
                form.append("title", uploadedVideo.title);
                form.append("isFree", value);
                const options = {
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent;
                        let percent = Math.floor((loaded * 100) / total);
                        setProgress(percent);
                    }
                };
                axios
                    .post(config.API_BASE_URL + "/video/upload-video", form, options, {
                        headers: { Authorization: `Bearer ${localStorage.getItem("jwtToken")}` },
                    })
                    .then((response) => {
                        if (response.data.success === true) {
                            toast.success("Video is uploaded successfully");
                            onClose();
                        } else {
                            setButtonStatus(false);
                            toast.error("Failed to save video. Something went wrong");
                        }
                    })
                    .catch(() => {
                        setButtonStatus(false);
                        toast.error("Failed to save video. Something went wrong");
                    });
            } else {
                const data = {
                    id: video.id, title: uploadedVideo.title, isFree: value
                }
                if(video.order >= 0){
                    Object.assign(data,{order: uploadedVideo.order})
                }
                const res = await editVideo(data);
                if (res.success === true) {
                    toast.success("Video is updated successfully");
                    onClose();
                } else {
                    setButtonStatus(false);
                    toast.error("Failed to update video. Something went wrong");
                }
            }
        }
    }

    // Validating form
    const validateForm = () => {
        let isValid = true;
        let inputs = uploadedVideo;
        if (inputs["title"] === "") {
            isValid = false;
            setError((prev) => ({
                ...prev,
                title: "Video title should not be empty"
            }));
        }
        // if(video.order >= 0 && (inputs["order"] === "" || inputs["order"] <= 0)){
        //     isValid = false;
        //     setError((prev) => ({
        //         ...prev,
        //         order: "Video order is required and it should be greater than zero"
        //     }));
        // }
        if (!video.edit && inputs["videoFile"] === "") {
            isValid = false;
            setError((prev) => ({
                ...prev,
                videoFile: "Video should not be empty"
            }));
        }
        return isValid;
    }

    const handleRadioChange = () => {
        setRadioValue(value ? false : true);
      };

    return (
        <Modal open={open} className="modal">
            <div className="paper test-modal">
                <div className="mb-3">
                    <h3>{video.edit ? 'UPDATE' :  'UPLOAD' } VIDEO</h3>
                </div>
                <Radio
                    checked={value}
                    onChange={handleRadioChange}
                    color="default"
                    value={true}
                    name="isFree"
                    inputProps={{ "aria-label": true }}
                />
                <label htmlFor="paid">Free</label>
                <Radio
                    checked={!value}
                    onChange={handleRadioChange}
                    color="default"
                    value={false}
                    name="isFree"
                    inputProps={{ "aria-label": false }}
                />
                <label htmlFor="non-paid">Paid</label>
                <label className="form-group has-float-label mt-3">
                    <input type="text" name="title" className="form-control" value={uploadedVideo.title} onChange={(e) => handleFormInputChange(e)} />
                    <span className="error">{errors.title}</span>
                    <span>Video Name</span>
                </label>
                {video.edit && video.order >= 0 &&
                 <label className="form-group has-float-label mb-4">
                    <input type="tel" name="order" className="form-control"
                        value={uploadedVideo.order} onChange={(e) => handleFormInputChange(e)} />
                        <span className="error">{errors.order}</span>
                    <span>Order of video</span>
                </label>}
                {!video.edit ?
                    <div> 
                        <label className="form-group has-float-label mb-4">
                            <input
                                type="file"
                                onChange={handleFileChange}
                                className="form-control"
                                accept="video/mp4"
                            />
                            <span className="error">{errors.videoFile}</span>
                            <span>Upload Video</span>
                        </label> </div> : null
                }
                {
                    !video.edit && progress > 0 ?
                        <Box display="flex" alignItems="center">
                            <Box width="100%" mr={1}>
                                <LinearProgress variant="determinate" value={progress} />
                            </Box>
                            <Box minWidth={35}>
                                <Typography variant="body2" color="textSecondary">{progress}%</Typography>
                            </Box>
                        </Box> : null
                }
                <div className="align-items-right text-right mt-4">
                    <button
                        type="button"
                        onClick={onClose}
                        disabled={progress > 0}
                        className="btn btn-danger btn-lg btn-shadow mr-3">
                        CANCEL
                    </button>
                    <button
                        disabled={buttonStatus}
                        type="button" onClick={saveVideo}
                        className="btn btn-success btn-lg btn-shadow">
                        SAVE
                    </button>
                </div>
            </div>
        </Modal>
    );
}

UploadVideo.propTypes = {
    onClose: propTypes.func.isRequired,
    open: propTypes.bool.isRequired,
    video: propTypes.object
};

export default connect(null, null)(UploadVideo)