import axios from 'axios'
import config from '../../config';
const {
    SUBJECT_LIST_REQUEST,
    SUBJECT_LIST_SUCCESS,
    SUBJECT_LIST_FAILURE,
    CREATE_SUBJECT_REQUEST,
    CREATE_SUBJECT_SUCCESS,
    CREATE_SUBJECT_FAILURE
} = require('./subject-types');
import { toast } from 'react-toastify';

// fetch subjects
export const fetchSubject = (data) => {
    return (dispatch) => {
        axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem("jwtToken")}`;
        dispatch(fetchSubjectRequest());
        axios
            .get(config.API_BASE_URL + '/subject/subject-list', {
                params: {
                    skip: data.skip,
                    limit: data.limit,
                    keyword: data.keyword,
                    type: data.type
                }
            })
            .then(response => {
                const result = response.data;
                dispatch(fetchSubjectSuccess(result));
            })
            .catch(error => {
                dispatch(fetchSubjectFailure(error.message))
            })
    };
};

export const fetchSubjectRequest = () => {
    return {
        type: SUBJECT_LIST_REQUEST
    };
};

export const fetchSubjectSuccess = result => {
    return {
        type: SUBJECT_LIST_SUCCESS,
        payload: result
    };
};

export const fetchSubjectFailure = error => {
    return {
        type: SUBJECT_LIST_FAILURE,
        payload: error
    };
};

// create subjects
export const createSubject = (data) => {
    const {_id} = data
    return (dispatch) => {
        axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem("jwtToken")}`;
        dispatch(createSubjectRequest());
        axios
            .post(config.API_BASE_URL + '/subject/create-subject', data)
            .then(response => {
                const result = response.data;
                if(result.success){
                    dispatch(createSubjectSuccess(result));
                    if(_id){
                        toast.success("Subject updated successfully.")
                    }else{
                        toast.success("Subject created successfully.")
                    }
                }
            })
            .catch(error => {
                toast.error("Failed to create subject.")
                dispatch(createSubjectFailure(error.message))
            })
    };
};

export const createSubjectRequest = () => {
    return {
        type: CREATE_SUBJECT_REQUEST
    };
};

export const createSubjectSuccess = result => {
    return {
        type: CREATE_SUBJECT_SUCCESS,
        payload: result
    };
};

export const createSubjectFailure = error => {
    return {
        type: CREATE_SUBJECT_FAILURE,
        payload: error
    };
};