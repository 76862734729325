export const FETCH_ARTICLE_REQUEST = "FETCH_ARTICLE_REQUEST";
export const FETCH_ARTICLE_SUCCESS = "FETCH_ARTICLE_SUCCESS";
export const FETCH_ARTICLE_FAILURE = "FETCH_ARTICLE_FAILURE";

export const CREATE_ARTICLE_REQUEST = "CREATE_ARTICLE_REQUEST";
export const CREATE_ARTICLE_SUCCESS = "CREATE_ARTICLE_SUCCESS";
export const CREATE_ARTICLE_FAILURE = "CREATE_ARTICLE_FAILURE";

export const FETCH_ARTICLE_IMAGE_REQUEST = "FETCH_ARTICLE_IMAGE_REQUEST";
export const FETCH_ARTICLE_IMAGE_SUCCESS = "FETCH_ARTICLE_IMAGE_SUCCESS";
export const FETCH_ARTICLE_IMAGE_FAILURE = "FETCH_ARTICLE_IMAGE_FAILURE";

export const CREATE_ARTICLE_IMAGE_REQUEST = "CREATE_ARTICLE_IMAGE_REQUEST";
export const CREATE_ARTICLE_IMAGE_SUCCESS = "CREATE_ARTICLE_IMAGE_SUCCESS";
export const CREATE_ARTICLE_IMAGE_FAILURE = "CREATE_ARTICLE_IMAGE_FAILURE";