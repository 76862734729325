import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { connect } from "react-redux";
import { fetchVideos } from '../../redux/video/video-action';
import propTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import VideoStatusPopup from '../../components/popup/video-status-popup';
import Pagination from "@material-ui/lab/Pagination";
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import DeleteIcon from '@material-ui/icons/Delete';
import VideoLink from '../../components/popup/video-link';
import { Capitalize, Transform } from '../../util/helper';
import VideoNote from '../../components/popup/video-note';
import { getPermission } from '../../util/auth';
import UploadVideo from '../../components/popup/video-upload';
import { useSelector } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import { useHistory } from "react-router-dom";

let data = {
    skip: 0,
    limit: 10,
    keyword: "",
};

const VideoList = ({ fetchVideos, videos }) => {
    let history = useHistory();
    const [page, setPage] = useState(1);
    const [rowCount, setRowCount] = useState(10);
    const [videoModal, setModal] = useState(false);
    const [video, setType] = useState({ type: '', data: '' });
    const [videoNote, setVideoNote] = useState({ open: false, videoId: '', note: '' });
    const [uploadVideo, setVideoUpload] = useState(false);
    const videoNoteStatus = useSelector((state) => state.videoNoteReducer);
    const linkStatus = useSelector((state) => state.linkVideoReducer);
    const videoUpdateStatus = useSelector((state) => state.statusUpdateVideoReducer);
    const [show, setshow] = useState(false);
    const [row, setrow] = useState(null);
    const [action, setaction] = useState("");
    const [selectedVideo, setSelectedVideo] = useState({id:'',title:'',isFree: true, edit: false, order: 0});
    useEffect(() => {
        fetchVideos(data);
    }, [fetchVideos, videoNoteStatus, linkStatus, videoUpdateStatus]);

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
        data.skip = (newPage - 1) * rowCount;
        fetchVideos(data);
    };

    const searchVideos = (event) => {
        data.skip = 0;
        data.keyword = event.target.value;
        setPage(1);
        fetchVideos(data);
    };

    const handleRowsPerPage = (event) => {
        setRowCount(event.target.value);
        setPage(1);
        data.limit = event.target.value;
        data.skip = 0;
        fetchVideos(data);
    };

    const linkVideo = (event, row) => {
        if (row.topicName) {
            setType({ type: 'unlink', data: row });
        } else {
            setType({ type: 'link', data: row });
        }
        setModal(true);
    }

    const closeModal = () => {
        setModal(false);
        fetchVideos(data);
    }

    const addNotes = (row) => {
        setVideoNote({ open: true, videoId: row.videoId, note: row.videoNote })
    }

    const closeNoteModal = () => {
        setVideoNote({ open: false, videoId: '', note: '' });
    }

    const handleUploadVideo = () => {
        setVideoUpload(true);
    }

    const closeVideoModal = () => {
        setVideoUpload(false);
        setSelectedVideo({id:'',title:'',isFree: true, edit: false, order: 0});
        fetchVideos(data);
    }

    const handleDelete = (e, row) => {
        e.preventDefault();
        setType({ type: 'deleteVideo', data: row });
        setModal(true);
    }

    const handlePopup = (e, row, action) => {
        setrow(row);
        setaction(action);
        setshow(true);
    };
    const handleClose = () => {
        setshow(false);
    }

    const handleEditVideo = (row) => {
        setSelectedVideo({id:row.videoId,title:row.name,isFree: row.isFree, edit:true, order: 
            (row.topicName && row.subjectName) ? (row.order? row.order : 0) : -1 });
        setVideoUpload(true);
    }

    const handleVideoQuiz = (e, row)=>{
        history.push({
            pathname: `/exam/create-exam/${row.videoId}`,
            state: "videoquiz",
          });
    }
    return (
        <main>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <h1>Videos</h1>
                        <nav
                            className="breadcrumb-container d-none d-sm-block d-lg-inline-block"
                            aria-label="breadcrumb"
                        >
                            <ol className="breadcrumb pt-0">
                                <li className="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>

                            </ol>
                        </nav>
                        <div className="separator mb-5"></div>
                        <div className="file-upload mb-3 ml-2">
                            <button
                                className="btn btn-outline-primary"
                                type="button" onClick={handleUploadVideo}>
                                UPLOAD VIDEO
                             </button>
                        </div>
                        {uploadVideo ? <UploadVideo onClose={closeVideoModal} open={uploadVideo} video={selectedVideo} /> : null}
                        <div className="tableBody">
                            <div className="table-filter-div">
                                <Grid item xs={6}>
                                    <div className="search-div">
                                        <input
                                            type="text"
                                            className="search"
                                            onKeyUp={searchVideos}
                                            placeholder="Search videos.."
                                        ></input>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="rowCount-div">
                                        <label>Videos per page : </label>
                                        <select className="selection"
                                            onChange={handleRowsPerPage}>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </Grid>
                            </div>
                            <Paper className="paperBody">
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="tableHead" align="center">Sl.No</TableCell>
                                                <TableCell className="tableHead">Name</TableCell>
                                                <TableCell className="tableHead">Vimeo Id</TableCell>
                                                <TableCell className="tableHead">Duration (hh:mm:ss)</TableCell>
                                                <TableCell className="tableHead">Subject</TableCell>
                                                <TableCell className="tableHead">Topic</TableCell>
                                                <TableCell className="tableHead">Order</TableCell>
                                                <TableCell className="tableHead">Status</TableCell>
                                                <TableCell className="tableHead">Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {videos &&
                                                videos.video &&
                                                videos.video.map((row, index) => {
                                                    return (
                                                        <TableRow hover key={row.videoId}>
                                                            <TableCell
                                                                className="tableColData"
                                                                align="center"
                                                            >
                                                                {rowCount * (page - 1) + index + 1}
                                                            </TableCell>
                                                            <TableCell width="350px" className="tableColData">
                                                                {row.name}
                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                {row.vimeoId}
                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                {row.duration ? Transform(row.duration) : null}
                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                {row.subjectName}
                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                {row.topicName}
                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                {row.order ? row.order : 0}
                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                {Capitalize(row.status)}
                                                            </TableCell>
                                                            <TableCell className="tableColData" width="210px">
                                                                {getPermission().video.edit || getPermission().video.write ?
                                                                    <NoteAddIcon color="primary" titleAccess="Add Notes" onClick={() => addNotes(row)} className="mr-3" /> : null}
                                                                {row.status === 'published' ? row.topicName && getPermission().video.custom ?
                                                                    <LinkOffIcon color="error" className="mr-2" titleAccess="Unlink" onClick={(ev) => linkVideo(ev, row)} /> :
                                                                    <LinkIcon color="primary" className="mr-2" titleAccess="Link" onClick={(ev) => linkVideo(ev, row)} /> : null}
                                                                <DeleteIcon color="secondary" className="delete" titleAccess="Delete"
                                                                    onClick={(ev) => handleDelete(ev, row)} />
                                                                {
                                                                    row.status === 'draft' || row.status === 'published' ? 
                                                                    <EditIcon titleAccess="Edit" aria-label="edit" fontSize="small" onClick={() => handleEditVideo(row)} />
                                                                    :null
                                                                 }
                                                                {row.status === "draft"  ? (
                                                                    <DoneIcon
                                                                        className="publish text-success"
                                                                        fontSize="small"
                                                                        titleAccess="Publish"
                                                                        onClick={(e) => handlePopup(e, row, "published")}
                                                                    />
                                                                ) : null}
                                                                {row.status === "published"  ? (
                                                                    <CloseIcon
                                                                        className="draft text-danger"
                                                                        fontSize="small"
                                                                        titleAccess="Draft"
                                                                        onClick={(e) => handlePopup(e, row, "draft")}
                                                                    />
                                                                ) : null}
                                                                <QuestionAnswerIcon onClick={(e)=> handleVideoQuiz(e, row)} color="primary" fontSize="small" titleAccess="Quiz" />
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            {show ? (
                                                <VideoStatusPopup
                                                    open={show}
                                                    onClose={handleClose}
                                                    row={row}
                                                    action={action}
                                                />
                                            ) : null}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <div className="paginationLabel">
                                    Showing {(page - 1) * rowCount + 1} to{" "}
                                    {(page - 1) * rowCount +
                                        (videos && videos.video
                                            ? videos.video.length
                                            : 0)}{" "}
                  of{" "}
                                    {videos.totalVideos
                                        ? videos.totalVideos
                                        : 0}{" "}
                  entries
                </div>
                                <div className="root">
                                    <Pagination
                                        count={
                                            videos && videos.totalVideos
                                                ? Math.ceil(videos.totalVideos / rowCount)
                                                : 0
                                        }
                                        page={page}
                                        onChange={handlePageChange}
                                        variant="outlined"
                                    />
                                </div>
                            </Paper>
                        </div>
                        {videoModal ? <VideoLink open={videoModal} onClose={closeModal} type={video.type} videoData={video.data} /> : null}
                        {videoNote.open ? <VideoNote video={videoNote} onClose={closeNoteModal} /> : null}
                    </div>
                </div>
            </div>
        </main>
    );
};
VideoList.propTypes = {
    fetchVideos: propTypes.func,
    deleteVideos: propTypes.func,
    videos: propTypes.object,
};

const mapStateToProps = (state) => {
    return {
        videos: state.fetchVideoReducer.videoList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchVideos: () => dispatch(fetchVideos(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoList);
