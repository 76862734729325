export const FETCH_COURSE_REQUEST = "FETCH_COURSE_REQUEST";
export const FETCH_COURSE_SUCCESS = "FETCH_COURSE_SUCCESS";
export const FETCH_COURSE_FAILURE = "FETCH_COURSE_FAILURE";

export const CHANGE_COURSE_STATUS_REQUEST = "CHANGE_COURSE_STATUS_REQUEST";
export const CHANGE_COURSE_STATUS_SUCCESS = "CHANGE_COURSE_STATUS_SUCCESS";
export const CHANGE_COURSE_STATUS_FAILURE = "CHANGE_COURSE_STATUS_FAILURE";

export const CREATE_COURSE_REQUEST = "CREATE_COURSE_REQUEST";
export const CREATE_COURSE_SUCCESS = "CREATE_COURSE_SUCCESS";
export const CREATE_COURSE_FAILURE = "CREATE_COURSE_FAILURE";

export const FETCH_COURSE_SUBJECT_REQUEST = "FETCH_COURSE_SUBJECT_REQUEST";
export const FETCH_COURSE_SUBJECT_SUCCESS = "FETCH_COURSE_SUBJECT_SUCCESS";
export const FETCH_COURSE_SUBJECT_FAILURE = "FETCH_COURSE_SUBJECT_FAILURE";

export const FETCH_COURSE_TOPIC_REQUEST = "FETCH_COURSE_TOPIC_REQUEST";
export const FETCH_COURSE_TOPIC_SUCCESS = "FETCH_COURSE_TOPIC_SUCCESS";
export const FETCH_COURSE_TOPIC_FAILURE = "FETCH_COURSE_TOPIC_FAILURE";
export const RESET_COURSE_TOPIC = "RESET_COURSE_TOPIC";

export const GET_COURSE_REQUEST = "GET_COURSE_REQUEST";
export const GET_COURSE_SUCCESS = "GET_COURSE_SUCCESS";
export const GET_COURSE_FAILURE = "GET_COURSE_FAILURE";

export const EDIT_COURSE_REQUEST = "EDIT_COURSE_REQUEST";
export const EDIT_COURSE_SUCCESS = "EDIT_COURSE_SUCCESS";
export const EDIT_COURSE_FAILURE = "EDIT_COURSE_FAILURE";

export const FETCH_COURSE_PLAN_REQUEST = "FETCH_COURSE_PLAN_REQUEST";
export const FETCH_COURSE_PLAN_SUCCESS = "FETCH_COURSE_PLAN_SUCCESS";
export const FETCH_COURSE_PLAN_FAILURE = "FETCH_COURSE_PLAN_FAILURE";

export const CREATE_COURSE_PLAN_REQUEST = "CREATE_COURSE_PLAN_REQUEST";
export const CREATE_COURSE_PLAN_SUCCESS = "CREATE_COURSE_PLAN_SUCCESS";
export const CREATE_COURSE_PLAN_FAILURE = "CREATE_COURSE_PLAN_FAILURE";

export const FETCH_ALL_COURSE_REQUEST = "FETCH_ALL_COURSE_REQUEST";
export const FETCH_ALL_COURSE_SUCCESS = "FETCH_ALL_COURSE_SUCCESS";
export const FETCH_ALL_COURSE_FAILURE = "FETCH_ALL_COURSE_FAILURE";

export const FETCH_PURCHASED_USERS_REQUEST = "FETCH_PURCHASED_USERS_REQUEST";
export const FETCH_PURCHASED_USERS_SUCCESS = "FETCH_PURCHASED_USERS_SUCCESS";
export const FETCH_PURCHASED_USERS_FAILURE = "FETCH_PURCHASED_USERS_FAILURE";

export const DELETE_COURSE_REQUEST = "DELETE_COURSE_REQUEST";
export const DELETE_COURSE_SUCCESS = "DELETE_COURSE_SUCCESS";
export const DELETE_COURSE_FAILURE = "DELETE_COURSE_FAILURE";
