import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import propTypes from "prop-types";
import { updatePayment } from "../../redux/user/user-action";
import { connect } from "react-redux";
import moment from 'moment';

let initializePayment = {
    amount: 0,
    paymentDate: ""
};
let initializePaymentErrors = {
    amount: '',
    paymentDate: ''
};

const PaymentForm = ({
    open,
    onClose,
    paymentData,
    updatePayment
}) => {
    const [paymentForm, setForm] = useState(initializePayment);
    const [paymentErrorForm, setError] = useState(initializePaymentErrors);

    useEffect(() => {
        reset();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const reset = () => {
        initializePaymentErrors = {
            fullName: "",
            email: "",
            userType: "",
            phoneNumber: '',
            subjects: [],
            costPerEvaluation: '',
            maximumEvaluationPerDay: ''
        };
        setError(initializePaymentErrors);
    };

    // Handle when user inputs are received.
    const handleFormInputChange = (event) => {
        event.persist();
        const { name, value } = event.target;
        if (name === 'amount') {
            const re = /^[0-9\b]+$/;
            if (value === '' || (re.test(value) && value.length <= 10)) {
                setForm({
                    ...paymentForm,
                    [name]: value
                });
            }
        }else if(name === 'paymentDate'){
            setForm({
                ...paymentForm,
                [name]: value
            });
        }

        if(value === null || value === ''){
            setError({
                ...paymentErrorForm,
                [name]: name === 'amount' ? 'Payment amount is required' : 'Payment date is required'
            });
        }else{
            setError({
                ...paymentErrorForm,
                [name]: ''
            });
        }
      
    };

    const validateUserForm = () => {
        let isValid = true;
        let inputs = paymentForm;
        if (inputs.amount === null || inputs.amount === "" || inputs.amount === 0) {
            initializePaymentErrors = { ...initializePaymentErrors, amount: 'Payment amount is required' }
            isValid = false;
        }
        if (inputs.paymentDate === null || inputs.paymentDate === "" || moment(inputs.paymentDate) > moment()) {
            initializePaymentErrors = { ...initializePaymentErrors, paymentDate: 'Payment date is required' }
            isValid = false;
        }
        setError(initializePaymentErrors);
        return isValid;
    }

    function saveUsers() {
        if (validateUserForm()) {
            const data = {
                ...paymentForm,
                id: paymentData.id
            }
            updatePayment(data);
            onClose();
        }
    }

    return (
        <Modal open={open} className="modal">
            <div className="paper">
                <h2> Payment Form </h2>

                <label className="form-group has-float-label mb-4">
                    <input
                        className="form-control"
                        name="due"
                        value={paymentData.due}
                        disabled
                        required
                    />
                    <span>Due Amount</span>
                </label>

                <label className="form-group has-float-label mb-4">
                    <input type="datetime-local"
                        className="form-control"
                        value={paymentForm.paymentDate}
                        onChange={(e) => handleFormInputChange(e)}
                        name="paymentDate" />
                    <span className="error">{paymentErrorForm.paymentDate}</span>
                    <span>Payment Date</span>
                </label>

                <label className="form-group has-float-label mb-4">
                    <input type="number"
                        className="form-control"
                        required
                        name="amount"
                        onChange={(e) => handleFormInputChange(e)}
                        value={paymentForm.amount}
                    />
                    <span className="error">{paymentErrorForm.amount}</span>
                    <span>Payment Amount</span>
                </label>

                <div className="align-items-right text-right mt-4">
                    <button
                        type="button"
                        onClick={onClose}
                        className="btn btn-danger btn-lg btn-shadow mr-3">
                        CANCEL
                    </button>
                    <button
                        type="button"
                        onClick={saveUsers}
                        className="btn btn-success btn-lg btn-shadow">
                        SAVE
                    </button>
                </div>
            </div>
        </Modal>
    );
};

PaymentForm.propTypes = {
    open: propTypes.bool.isRequired,
    onClose: propTypes.func.isRequired,
    paymentData: propTypes.object,
    updatePayment: propTypes.func
};

const mapDispatchToProps = (dispatch) => {
    return {
        updatePayment: (data) => dispatch(updatePayment(data))
    };
};

export default connect(null, mapDispatchToProps)(PaymentForm);