import axios from "axios";
import config from '../../config';
import { toast } from "react-toastify";
const {
  FETCH_ALERT_REQUEST,
  FETCH_ALERT_SUCCESS,
  FETCH_ALERT_FAILURE,
  FETCH_PURCHASED_USERS_REQUEST,
  FETCH_PURCHASED_USERS_SUCCESS,
  FETCH_PURCHASED_USERS_FAILURE
  
} = require("./mains-types");
export const fetchAvailableAlerts = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(fetchAlertsRequest());
    axios
      .get(config.API_BASE_URL + "/mains/evaluation-alerts", {
        params: {
          skip: data.skip,
          limit: data.limit,
          keyword: data.keyword,
        },
      })
      .then((response) => {
        const courses = response.data;
        dispatch(fetchAlertsSuccess(courses));
      })
      .catch((error) => {
        toast.error("Failed to get alerts. Something went wrong.");
        dispatch(fetchAlertsFailure(error.message));
      });
  };
};

export const fetchAlertsRequest = () => {
  return {
    type: FETCH_ALERT_REQUEST,
  };
};

export const fetchAlertsSuccess= (alerts) => {
  return {
    type: FETCH_ALERT_SUCCESS,
    payload: alerts,
  };
};

export const fetchAlertsFailure = (error) => {
  return {
    type: FETCH_ALERT_FAILURE,
    payload: error,
  };
};

export const fetchMainsPurchasedRequest = () => {
  return {
    type: FETCH_PURCHASED_USERS_REQUEST,
  };
};
export const fetchMainsPurchasedSuccess = (data) => {
  return {
    type: FETCH_PURCHASED_USERS_SUCCESS,
    payload: data,
  };
};
export const fetchMainsPurchasedFailure = (error) => {
  return {
    type: FETCH_PURCHASED_USERS_FAILURE,
    payload: error,
  };
};
export const fetchMainsUserPurchased = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(fetchMainsPurchasedRequest());
    axios
      .get(config.API_BASE_URL + "/mains/purchased-users", {
        params: {
          skip: data.skip,
          limit: data.limit,
          keyword: data.keyword,
        },
      })
      .then((response) => {
        const mains = response.data;
        dispatch(fetchMainsPurchasedSuccess(mains));
      })
      .catch((error) => {
        toast.error("Failed to get courses. Something went wrong.");
        dispatch(fetchMainsPurchasedFailure(error.message));
      });
  };
};
export { }