import axios from "axios";
import config from '../../config';
import { toast } from "react-toastify";
// import approvedQuestions from "../../assets/mock-response/approved-questions.json";
// import questionData from "../../assets/mock-response/categorized-questions.json";
import {
  FETCH_APPROVED_QUESTIONS_REQUEST,
  FETCH_APPROVED_QUESTIONS_SUCCESS,
  FETCH_APPROVED_QUESTIONS_FAILURE,
  FETCH_CATEGORIZED_QUESTIONS_REQUEST,
  FETCH_CATEGORIZED_QUESTIONS_SUCCESS,
  FETCH_CATEGORIZED_QUESTIONS_FAILURE,
  APPROVE_CATEGORIZED_QUESTION_REQUEST,
  APPROVE_CATEGORIZED_QUESTION_SUCCESS,
  APPROVE_CATEGORIZED_QUESTION_FAILURE,
  UPLOAD_QUESTIONS_REQUEST,
  UPLOAD_QUESTIONS_SUCCESS,
  UPLOAD_QUESTIONS_FAILURE,
  FETCH_MAINS_QUESTIONS_REQUEST,
  FETCH_MAINS_QUESTIONS_SUCCESS,
  FETCH_MAINS_QUESTIONS_FAILURE,
  CREATE_QUESTIONS_REQUEST,
  CREATE_QUESTIONS_SUCCESS,
  CREATE_QUESTIONS_FAILURE,
  UPDATE_QUESTIONS_REQUEST,
  UPDATE_QUESTIONS_SUCCESS,
  UPDATE_QUESTIONS_FAILURE,
  CREATE_SAMPLE_ANSWER_REQUEST,
  CREATE_SAMPLE_ANSWER_SUCCESS,
  CREATE_SAMPLE_ANSWER_FAILURE,
  EDIT_QUESTION_REQUEST,
  EDIT_QUESTION_SUCCESS,
  EDIT_QUESTION_FAILURE,
  DELETE_APPROVED_QUESTIONS_REQUEST,
  DELETE_APPROVED_QUESTIONS_SUCCESS,
  DELETE_APPROVED_QUESTIONS_FAILURE
} from "./questions-types";

// Fetch approved questions
export const fetchApprovedQuestions = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem("jwtToken")}`;
    dispatch(fetchApprovedQuestionsRequest())
     axios
     .get(config.API_BASE_URL + '/questions/question-list', {params:{
         skip: data.skip,
         limit: data.limit,
         keyword: data.keyword,
         approve: true
     }})
      .then(response => {
        const questions = response.data
        dispatch(fetchApprovedQuestionsSuccess(questions));
      })
      .catch(error => {
        dispatch(fetchApprovedQuestionsFailure(error.message))
      })
  };
};

export const deleteApprovedQuestions = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem("jwtToken")}`;
    dispatch(deleteApprovedQuestionsRequest())
     axios
     .patch(config.API_BASE_URL + '/questions/delete-question', {
         _id:data._id
     })
      .then(response => {
        dispatch(deleteApprovedQuestionsSuccess(response.data));
      })
      .catch(error => {
        dispatch(deleteApprovedQuestionsFailure(error.message))
      })
  };
};

export const fetchApprovedQuestionsRequest = () => {
  return {
    type: FETCH_APPROVED_QUESTIONS_REQUEST,
  };
};

export const fetchApprovedQuestionsSuccess = (questions) => {
  return {
    type: FETCH_APPROVED_QUESTIONS_SUCCESS,
    payload: questions,
  };
};

export const fetchApprovedQuestionsFailure = (error) => {
  return {
    type: FETCH_APPROVED_QUESTIONS_FAILURE,
    payload: error,
  };
};

export const deleteApprovedQuestionsRequest = () => {
  return {
    type: DELETE_APPROVED_QUESTIONS_REQUEST,
  };
};

export const deleteApprovedQuestionsSuccess = (data) => {
  return {
    type: DELETE_APPROVED_QUESTIONS_SUCCESS,
    payload: data,
  };
};

export const deleteApprovedQuestionsFailure = (error) => {
  return {
    type: DELETE_APPROVED_QUESTIONS_FAILURE,
    payload: error,
  };
};

// categorized-questions
export const categorizedQuestions = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem("jwtToken")}`;
    dispatch(fetchCategorizedQuestionsRequest());
    axios
      .get(`${config.API_BASE_URL}/questions/question-list`, {
        params: {
          skip: data.skip,
          limit: data.limit,
          keyword: data.keyword,
          approve: false
        },
      })
      .then((response) => {
        const { data } = response;
        dispatch(fetchCategorizedQuestionsSuccess(data));
      })
      .catch((error) => {
        dispatch(fetchCategorizedQuestionsFailure(error.message));
      });
  };
};

export const fetchCategorizedQuestionsRequest = () => {
  return {
    type: FETCH_CATEGORIZED_QUESTIONS_REQUEST,
  };
};

export const fetchCategorizedQuestionsSuccess = (data) => {
  return {
    type: FETCH_CATEGORIZED_QUESTIONS_SUCCESS,
    payload: data,
  };
};

export const fetchCategorizedQuestionsFailure = (data) => {
  return {
    type: FETCH_CATEGORIZED_QUESTIONS_FAILURE,
    payload: data,
  };
};
// Approve the question
export const approveQuestion = (params) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem("jwtToken")}`;
    dispatch(approvedCategorizedQuestionRequest());
    axios
      .patch(`${config.API_BASE_URL}/questions/approve`, params)
      .then((response) => {
        const { data } = response;
        if(data.success === true) {
          if(!params.approve){
          toast.success("Question flagged successfully.");
          }
          else{
            toast.success("Question approved successfully");
          }
          dispatch(approvedCategorizedQuestionSuccess(data));
        } else {
          toast.error("Failed. Something went wrong!.");
          dispatch(approvedCategorizedQuestionFailure(data.message));
        }
      })
      .catch((error) => {
        toast.error("Failed. Something went wrong!.");
        dispatch(approvedCategorizedQuestionFailure(error.message));
      });
  };
};
export const approvedCategorizedQuestionRequest = () => {
  return {
    type: APPROVE_CATEGORIZED_QUESTION_REQUEST,
  };
};
export const approvedCategorizedQuestionSuccess = (data) => {
  return {
    type: APPROVE_CATEGORIZED_QUESTION_SUCCESS,
    payload: data,
  };
};
export const approvedCategorizedQuestionFailure = (data) => {
  return {
    type: APPROVE_CATEGORIZED_QUESTION_FAILURE,
    payload: data,
  };
};
// Uploading the questions
export const uploadQuestions = (data) => {
  return (dispatch) => {
    const form = new FormData();
    form.append("file", data.questionFile);
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem("jwtToken")}`;
    dispatch(uploadQuestionsRequest());
    axios
      .post(`${config.API_BASE_URL}/questions/upload`, form)
      .then((response) => {
        const { data } = response;
        if (data.success === true) {
          toast.success("Successfully uploaded questions.");
          dispatch(uploadQuestionsSuccess(data));
        } else {
          toast.error("Failed to upload questions. Something went wrong!.");
          dispatch(uploadQuestionsFailure(data.message));
        }
      })
      .catch((error) => {
        toast.error("Failed to upload questions. Something went wrong!.");
        dispatch(uploadQuestionsFailure(error.message));
      });
  };
};
export const uploadQuestionsRequest = () => {
  return {
    type: UPLOAD_QUESTIONS_REQUEST,
  };
};
export const uploadQuestionsSuccess = (data) => {
  return {
    type: UPLOAD_QUESTIONS_SUCCESS,
    payload: data,
  };
};

export const uploadQuestionsFailure = (data) => {
  return {
    type: UPLOAD_QUESTIONS_FAILURE,
    payload: data,
  };
};
// mains-questions
export const fetchMainsQuestions = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem("jwtToken")}`;
    dispatch(fetchMainsQuestionsRequest());
    axios
      .get(`${config.API_BASE_URL}/questions/get-mains-questions`, {
        params: {
          skip: data.skip,
          limit: data.limit,
          keyword: data.keyword,
        },
      })
      .then((response) => {
        const { data } = response;
        dispatch(fetchMainsQuestionsSuccess(data));
      })
      .catch((error) => {
        dispatch(fetchMainsQuestionsFailure(error.message));
      });
  };
};
export const fetchMainsQuestionsRequest = () => {
  return {
    type: FETCH_MAINS_QUESTIONS_REQUEST,
  };
};
export const fetchMainsQuestionsSuccess = (data) => {
  return {
    type: FETCH_MAINS_QUESTIONS_SUCCESS,
    payload: data,
  };
};
export const fetchMainsQuestionsFailure = (data) => {
  return {
    type: FETCH_MAINS_QUESTIONS_FAILURE,
    payload: data,
  };
};

// Create/Edit the question
export const createQuestion = (params) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem("jwtToken")}`;
    dispatch(createQuestionRequest());
    axios
      .post(`${config.API_BASE_URL}/questions/mains-question`, params)
      .then((response) => {
        const { data } = response;
        if (data.success === true) {
          if (params._id) {
            toast.success("Question updated successfully.");
          } else {
            toast.success("Question created successfully.");
          }
          dispatch(createQuestionSuccess(data));
        } else {
          if (params._id) {
            toast.error("Failed to update question. Something went wrong!.");
          } else {
            toast.error("Failed to create question. Something went wrong!.");
          }
          dispatch(createQuestionFailure(data.message));
        }
      })
      .catch((error) => {
        if (params._id) {
          toast.error("Failed to update question. Something went wrong!.");
        } else {
          toast.error("Failed to create question. Something went wrong!.");
        }
        dispatch(createQuestionFailure(error.message));
      });
  };
};
export const createQuestionRequest = () => {
  return {
    type: CREATE_QUESTIONS_REQUEST,
  };
};
export const createQuestionSuccess = (data) => {
  return {
    type: CREATE_QUESTIONS_SUCCESS,
    payload: data,
  };
};
export const createQuestionFailure = (data) => {
  return {
    type: CREATE_QUESTIONS_FAILURE,
    payload: data,
  };
};

// update-questions
export const updateMainsQuestion = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem("jwtToken")}`;
    dispatch(updateQuestionRequest());
    axios
      .patch(`${config.API_BASE_URL}/mains/update-status`, data)
      .then((response) => {
        const { data } = response;
        if(data.success === true){
          toast.success("question updated successfully");
          dispatch(updateQuestionSuccess(data));
        }
      })
      .catch((error) => {
        toast.success("Failed to update status");
        dispatch(updateQuestionFailure(error.message));
      });
  };
};
export const updateQuestionRequest = () => {
  return {
    type: UPDATE_QUESTIONS_REQUEST,
  };
};
export const updateQuestionSuccess = (data) => {
  return {
    type: UPDATE_QUESTIONS_SUCCESS,
    payload: data,
  };
};
export const updateQuestionFailure = (data) => {
  return {
    type: UPDATE_QUESTIONS_FAILURE,
    payload: data,
  };
};
// upload-sample-answer
export const addSampleAnswer = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem("jwtToken")}`;
    dispatch(addSampleAnswerRequest());
    axios
      .post(`${config.API_BASE_URL}/mains/sample-answer`, data)
      .then((response) => {
        const { data } = response;
        if(data.success === true){
          toast.success("Answer added successfully");
          dispatch(addSampleAnswerSuccess(data));
        }
      })
      .catch((error) => {
        toast.error("Failed to add answer");
        dispatch(addSampleAnswerFailure(error.message));
      });
  };
};
export const addSampleAnswerRequest = () => {
  return {
    type: CREATE_SAMPLE_ANSWER_REQUEST,
  };
};
export const addSampleAnswerSuccess = (data) => {
  return {
    type: CREATE_SAMPLE_ANSWER_SUCCESS,
    payload: data,
  };
};
export const addSampleAnswerFailure = (data) => {
  return {
    type: CREATE_SAMPLE_ANSWER_FAILURE,
    payload: data,
  };
};

// Edit question
export const editQuestion = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(editQuestionRequest());
    axios
      .patch(config.API_BASE_URL + "/questions/edit-question", data)
      .then((response) => {
        const result = response.data;
        if (result.success === true) {
          toast.success("Successfully edited the question.");
          dispatch(editQuestionSuccess(result));
        } else {
          toast.error("Failed to edit question. Something went wrong");
          dispatch(editQuestionFailure(result));
        }
      })
      .catch((error) => {
        if(error.status === 500){
          toast.error("Failed to edit question. Something went wrong");
        }else{
          toast.error(error.response.data.message);
        }
        dispatch(editQuestionFailure(error));
      });
  };
};

export const editQuestionRequest = () => {
  return {
    type: EDIT_QUESTION_REQUEST,
  };
};

export const editQuestionSuccess = (response) => {
  return {
    type: EDIT_QUESTION_SUCCESS,
    payload: response,
  };
};

export const editQuestionFailure = (error) => {
  return {
    type: EDIT_QUESTION_FAILURE,
    payload: error,
  };
};

export const addGuidanceNote = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(editQuestionRequest());
    axios
      .post(config.API_BASE_URL + "/questions/guidance-note", data)
      .then((response) => {
        const result = response.data;
        if (result.success === true) {
          toast.success("Successfully updated guidance note.");
          dispatch(editQuestionSuccess(result));
        }
      })
      .catch((error) => {
        if(error.status === 500){
          toast.error("Failed to add guidance note. Something went wrong");
        }else{
          toast.error(error.response.data.message);
        }
        dispatch(editQuestionFailure(error));
      });
  };
};
