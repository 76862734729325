import React, {useState, useEffect} from "react";
import Modal from "@material-ui/core/Modal";
import propTypes from "prop-types";
import {createPrelim, editPrelim} from "../../redux/prelim/prelim-action";
import {connect} from "react-redux";
import moment from "moment";
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';

let initializePrelim = {
  name: "",
  description: "",
  backgroundColor: "",
  openDate: "",
  actualPrice: "",
  offerPrice: "",
  vimeoId: "",
};
let prelimId = "";

const CreatePrelim = ({
                        open,
                        onClose,
                        createPrelim,
                        edit,
                        data,
                        editPrelim,
                      }) => {
  let initializePrelimErrors = {
    name: "",
    description: "",
    backgroundColor: "",
    openDate: "",
    actualPrice: "",
    offerPrice: "",
    vimeoId: "",
  };
  const [prelimForm, setForm] = useState(initializePrelim);
  const [prelimErrors, setError] = useState(initializePrelimErrors);
  useEffect(() => {
    reset();
    if (edit) {
      prelimId = data.prelimId;
      data.openDate = moment(data.openDate).format("YYYY-MM-DD");
      setForm(data);
    } else {
      setForm({
        name: "",
        description: "",
        backgroundColor: "",
        openDate: "",
        actualPrice: "",
        offerPrice: "",
        vimeoId: ""
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // For reseting form error values.
  const reset = () => {
    initializePrelimErrors = {
      name: "",
      description: "",
      backgroundColor: "",
      openDate: "",
      actualPrice: "",
      offerPrice: "",
      vimeoId: "",
    };
    setError(initializePrelimErrors);
  };

  // Handle when user inputs are received.
  const handleFormInputChange = (event) => {
    event.persist();
    const {name, value} = event.target;
    setForm((prelimForm) => ({
      ...prelimForm,
      [name]: value,
    }));
    if (name === "openDate") {
      if (new Date(value) < new Date()) {
        setError((errors) => ({
          ...errors,
          [name]: "Invalid date",
        }));
      } else {
        setError((errors) => ({
          ...errors,
          [name]: "",
        }));
      }
    } else if (
      name === "actualPrice" ||
      name === "offerPrice"
    ) {
      if (prelimForm[name] >= 0) {
        setError((errors) => ({
          ...errors,
          [name]: "",
        }));
      } else {
        setError((errors) => ({
          ...errors,
          [name]: "Invalid input",
        }));
      }
    } else {
      setError((errors) => ({
        ...errors,
        [name]: "",
      }));
    }
  };

  // Create and edit prelim api handling.
  const savePrelim = () => {
    if (validatePrelimForm()) {
      prelimForm.vimeoId = prelimForm.vimeoId.trim();
      initializePrelim = prelimForm;
      if (edit) {
        initializePrelim.prelimId = prelimId;
        initializePrelim.startDate = initializePrelim.openDate;
        delete initializePrelim["openDate"];
        delete initializePrelim["test"];
        delete initializePrelim["status"];
        delete initializePrelim["testCount"];
        editPrelim(initializePrelim);
      } else {
        createPrelim(initializePrelim);
      }
      onClose();
    }
  };

  const handleChange = (value) => {
    setForm((prelimForm) => ({
      ...prelimForm,
      'description': value,
    }));
    if (value === "" || value === null) {
      setError((errors) => ({
        ...errors,
        'description': "Description should not be empty",
      }));
    } else {
      setError((errors) => ({
        ...errors,
        'description': "",
      }));
    }
  }

  // Form validation.
  const validatePrelimForm = () => {
    let isValid = true;
    let inputs = prelimForm;
    if (inputs["name"] === "" || !inputs["name"]) {
      initializePrelimErrors.name = "Prelim name should not be empty";
      isValid = false;
    }
    if (inputs["description"] === "" || !inputs["description"]) {
      isValid = false;
      initializePrelimErrors.description = "Description should not be empty";
    }
    if (inputs["backgroundColor"] === "" || !inputs["backgroundColor"]) {
      isValid = false;
      initializePrelimErrors.backgroundColor = "Select one background color";
    }
    if (inputs["vimeoId"] === "" || !inputs["vimeoId"]) {
      isValid = false;
      initializePrelimErrors.vimeoId = "Video Link should not be empty";
    }
    if (inputs["actualPrice"] === 0 || inputs["actualPrice"] <= 0) {
      isValid = false;
      initializePrelimErrors.actualPrice =
        "Actual price should be greater than zero";
    }
    if (inputs["offerPrice"] === 0 || inputs["offerPrice"] <= 0) {
      isValid = false;
      initializePrelimErrors.offerPrice =
        "offer price should be greater than zero";
    }
    if (parseInt(inputs["offerPrice"]) > parseInt(inputs["actualPrice"])) {
      isValid = false;
      initializePrelimErrors.offerPrice = "Invalid offer price";
    }

    if (
      inputs["openDate"] === ""
    ) {
      isValid = false;
      initializePrelimErrors.openDate = "Invalid start date";
    } else {
      const selectedDate = new Date(inputs["openDate"]);
      selectedDate.setHours(0, 0, 0, 0)
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      if (selectedDate < today) {
        isValid = false;
        initializePrelimErrors.openDate = "Invalid start date";
      }
    }
    setError(initializePrelimErrors);
    return isValid;
  };

  return (
    <Modal open={open} className="modal">
      <div className="paper">
        <label className="form-group has-float-label mb-4">
          <input
            className="form-control"
            name="name"
            value={prelimForm.name}
            onChange={(e) => handleFormInputChange(e)}
          />
          <span className="error">{prelimErrors.name}</span>
          <span>Name</span>
        </label>

        <label className="form-group has-float-label mb-4">
          <ReactQuill value={prelimForm.description} name="description"
                      onChange={handleChange}/>
          <span className="error">{prelimErrors.description}</span>
          <span>Description</span>
        </label>

        <label className="form-group has-float-label mb-4">
          <select
            data-width="100%"
            className="form-control select2-single"
            value={prelimForm.backgroundColor}
            onChange={(e) => handleFormInputChange(e)}
            name="backgroundColor"
          >
            <option disabled value="">
              Select background color
            </option>
            <option value="green">Green</option>
            <option value="orange">Orange</option>
            <option value="blue">Blue</option>
          </select>
          <span className="error">{prelimErrors.backgroundColor}</span>
          <span>Background Color</span>
        </label>

        <label className="form-group has-float-label mb-4">
          <input
            className="form-control"
            name="vimeoId"
            value={prelimForm.vimeoId}
            onChange={(e) => handleFormInputChange(e)}
          />
          <span className="error">{prelimErrors.vimeoId}</span>
          <span>Sample Video Id</span>
        </label>

        <label className="form-group has-float-label mb-4">
          <input
            type="date"
            className="form-control"
            name="openDate"
            onChange={(e) => handleFormInputChange(e)}
            value={prelimForm.openDate}
          />
          <span className="error">{prelimErrors.openDate}</span>
          <span>Start Date</span>
        </label>

        <label className="form-group has-float-label mb-4">
          <input
            type="number"
            className="form-control"
            name="actualPrice"
            onChange={(e) => handleFormInputChange(e)}
            value={prelimForm.actualPrice}
          />
          <span className="error">{prelimErrors.actualPrice}</span>
          <span>Actual Price</span>
        </label>

        <label className="form-group has-float-label mb-4">
          <input
            type="number"
            className="form-control"
            name="offerPrice"
            onChange={(e) => handleFormInputChange(e)}
            value={prelimForm.offerPrice}
          />
          <span className="error">{prelimErrors.offerPrice}</span>
          <span>Offer Price</span>
        </label>

        <div className="align-items-right text-right mt-4">
          <button
            type="button"
            onClick={onClose}
            className="btn btn-danger btn-lg btn-shadow mr-3"
          >
            CANCEL
          </button>
          <button
            type="button"
            onClick={savePrelim}
            className="btn btn-success btn-lg btn-shadow"
          >
            SAVE
          </button>
        </div>
      </div>
    </Modal>
  );
};

CreatePrelim.propTypes = {
  open: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  createPrelim: propTypes.func,
  edit: propTypes.bool,
  data: propTypes.object,
  editPrelim: propTypes.func,
};

const mapDispatchToProps = (dispatch) => {
  return {
    createPrelim: () => dispatch(createPrelim(initializePrelim)),
    editPrelim: () => dispatch(editPrelim(initializePrelim, prelimId)),
  };
};

export default connect(null, mapDispatchToProps)(CreatePrelim);
