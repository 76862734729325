import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import propTypes from "prop-types";
import { createQuestion } from "../../redux/questions/questions-action";
import { getSubjects, getTopics } from "../../redux/course/course-action";
import { connect } from "react-redux";
import { convertBase64 } from "../../util/helper";

let initializeQuestion = {
  question: "",
  subject: "",
  topic: "",
};
let initializeQuestionErrors = {
  question: "",
  subject: "",
  image: "",
  topic: "",
};
let questionId = "";

const CreateMainsQuestion = ({
  open,
  onClose,
  createQuestion,
  edit,
  data,
  editQuestion,
  getSubjects,
  getTopics,
  subjectResult,
  topicResult
}) => {
  const [questionForm, setForm] = useState(initializeQuestion);
  const [questionErrors, setError] = useState(initializeQuestionErrors);
  const [file, setfile] = useState(null);

  useEffect(() => {
    getSubjects();
    reset();
    if (edit) {
      questionId = data._id;
      getTopics(data.subject._id)
      setForm({ question: data.title, subject: data.subject._id, topic: data.topic._id });
    }
    else {
      setForm({
        question: "",
        subject: "",
        topic: "",
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // For reseting form error values.
  const reset = () => {
    initializeQuestionErrors = {
      question: "",
      subject: "",
      topic: "",
    };
    setError(initializeQuestionErrors);
  };

  // Handle when user inputs are received.
  const handleFormInputChange = (event) => {
    event.persist();
    const { name, value } = event.target;
    if (name === "question") {
      setForm({ ...questionForm, question: value })

    } else if (
      name === "subject") {
      if (value) {
        setForm({ ...questionForm, subject: value })
        getTopics(value)
      }
    } else if (
      name === "topic") {
      if (value) {
        setForm({ ...questionForm, topic: value })
      }
    }
  };

  // Create and edit question api handling.
  const saveQuestion = async () => {
    if (validateQuestionForm()) {
      if (edit) {
        if (file) {
          editQuestion({ _id: questionId, ...questionForm, image: file});
        } else {
          editQuestion({ _id: questionId, ...questionForm });
        }
      } else {
        if (file) {
          createQuestion({ ...questionForm, image: file });
        }
        else {
          createQuestion(questionForm);
        }
      }
      onClose();
    }
  };

  // Form validation.
  const validateQuestionForm = () => {
    let isValid = true;
    let inputs = questionForm;
    if (inputs["question"] === "") {
      initializeQuestionErrors.question = "Question name should not be empty";
      isValid = false;
    } else {
      initializeQuestionErrors.question = "";
    }
    if (inputs["subject"] === "") {
      isValid = false;
      initializeQuestionErrors.subject = "Subject should not be empty";
    } else {
      initializeQuestionErrors.subject = "";
    }
    if (
      inputs["topic"] === "") {
      isValid = false;
      initializeQuestionErrors.topic = "Select one topic";
    } else {
      initializeQuestionErrors.topic = "";
    }
    setError((errors) => ({
      ...errors,
      initializeQuestionErrors,
    }));
    return isValid;
  };
  const handleFileUpload = async (e) => {
    const base64 = await convertBase64(e.target.files[0]);
    setfile(base64)
  }

  return (
    <Modal open={open} className="modal">
      <div className="paper">
        <label className="form-group has-float-label mb-4">
          <textarea
            className="form-control"
            name="question"
            value={edit ? questionForm.question : null}
            onChange={(e) => handleFormInputChange(e)}
          />
          <span className="error">{questionErrors.question}</span>
          <span>Question</span>
        </label>

        <label className="form-group has-float-label mb-4">
          <input
            type="file"
            className="form-control"
            name="image"
            accept="image/*"
            // value={questionForm.image}
            onChange={(e) => handleFileUpload(e)}
          />
          <span className="error">{questionErrors.image}</span>
          <span>Image</span>
        </label>
        <label className="form-group has-float-label mb-4">
          <select
            data-width="100%"
            className="form-control select2-single"
            onChange={(e) => handleFormInputChange(e)}
            value={questionForm.subject}
            name="subject"
          >
            <option disabled value="">
              Select Subject
            </option>
            {subjectResult &&
              subjectResult.map((subject) => (
                <option key={subject._id} value={subject._id}>{subject.name}</option>
              ))}
          </select>
          <span className="error">{questionErrors.subject}</span>
          <span>Subject</span>
        </label>

        <label className="form-group has-float-label mb-4">
          <select
            data-width="100%"
            className="form-control select2-single"
            onChange={(e) => handleFormInputChange(e)}
            name="topic"
            value={questionForm.topic}
          >
            <option disabled value="">
              Select Topic
            </option>
            {topicResult &&
              topicResult.map((topic) => (
                <option key={topic._id} value={topic._id}>{topic.name}</option>
              ))}
          </select>
          <span className="error">{questionErrors.topic}</span>
          <span>Topic</span>
        </label>

        <div className="align-items-right text-right mt-4">
          <button
            type="button"
            onClick={onClose}
            className="btn btn-danger btn-lg btn-shadow mr-3"
          >
            CANCEL
          </button>
          <button
            type="button"
            onClick={saveQuestion}
            className="btn btn-success btn-lg btn-shadow"
          >
            SAVE
          </button>
        </div>
      </div>
    </Modal>
  );
};

CreateMainsQuestion.propTypes = {
  open: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  createQuestion: propTypes.func,
  edit: propTypes.bool,
  data: propTypes.object,
  editQuestion: propTypes.func,
  getSubjects: propTypes.func,
  getTopics: propTypes.func,
  subjectResult: propTypes.object,
  topicResult: propTypes.object
};
const mapStateToProps = (state) => {
  return {
    subjectResult: state.getCourseSubjectsReducer.subjects,
    topicResult: state.getCourseTopicReducer.topics,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getSubjects: () => dispatch(getSubjects({ type: "mains" })),
    getTopics: (subjectId) => dispatch(getTopics(subjectId)),
    createQuestion: (data) => dispatch(createQuestion(data)),
    editQuestion: (data) => dispatch(createQuestion(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateMainsQuestion);
