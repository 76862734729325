import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Pagination from "@material-ui/lab/Pagination";
import { fetchUsers } from "../../redux/user/user-action";
import CreateUsers from "./create-users";
import PaymentForm from "./payment-form";
import EditIcon from "@material-ui/icons/Edit";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import DoneIcon from '@material-ui/icons/Done';
import BlockIcon from '@material-ui/icons/Block';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { createUsers } from "../../redux/user/user-action";


let data = {
    skip: 0,
    limit: 10,
    keyword: "",
    type: "backoffice",
};
let statusData = {
    _id: '',
    status: ''
};

const Users = ({ fetchUsers, userData, createResult, createUsers, paymentUpdate }) => {
    const [page, setPage] = useState(1);
    const [rowCount, setRowCount] = useState(10);
    const [show, setshow] = useState({ open: false, edit: false });
    const [editData, setEditData] = useState({});
    const [dialogue, setOpen] = useState({ open: false, type: 'publish', user: '' });
    const [paymentForm, setPaymentForm] = useState({open:false,paymentData:''});


    useEffect(() => {
        fetchUsers(data);
    }, [fetchUsers, createResult, paymentUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleRowsPerPage = (event) => {
        setRowCount(event.target.value);
        setPage(1);
        data.limit = event.target.value;
        data.skip = 0;
        fetchUsers(data);
    };

    const searchUser = (event) => {
        data.skip = 0;
        data.keyword = event.target.value;
        setPage(1);
        fetchUsers(data);
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
        data.skip = (newPage - 1) * rowCount;
        fetchUsers(data);
    };

    const handleStatusChange = (ev) => {
        data.type = ev.target.value;
        data.skip = 0;
        setPage(1);
        fetchUsers(data);
    };

    const closeSubjectModal = () => {
        setshow({ open: false, edit: false });
    };

    const openSubjectModal = () => {
        setshow({ open: true, edit: false });
    };

    const handleEdit = (row) => {
        let users = {
            _id: row._id,
            phoneNumber: row.phoneNumber,
            fullName: row.fullName,
            email: row.email,
            userType: data.type.toLowerCase()
        };
        if(users.userType === 'evaluator'){
            Object.assign(users,{
                maximumEvaluationPerDay: row.maximumEvaluationPerDay,
                costPerEvaluation: row.costPerEvaluation,
                subjects: row.subjects
            });
        }
        setshow({ open: true, edit: true });
        setEditData(users);
    };

    const handlePaymentUpdate = (row) => {
        let p = {
            id:row._id,
            due: row.totalEarning - row.totalPayedAmount
        };
        setEditData(p);
        setPaymentForm({open:true});
    };

    const closePaymentForm = ()=>{
        setPaymentForm({open:false});
    }

    const changeStatus = (row, type) => {
        console.log(row);
        setOpen({ open: true, type: type, user: row });
    };

    const handleClose = () => {
        setOpen({ open: false });
    };

    const handleAgree = () => {
        statusData._id = dialogue.user._id
        statusData.status = dialogue.type === 'freeze' ? 'draft' : 'published';
        setOpen({ open: false });
        createUsers(statusData);
    }

    return (
        <main>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <h1>Users</h1>
                        <nav
                            className="breadcrumb-container d-none d-sm-block d-lg-inline-block"
                            aria-label="breadcrumb">
                            <ol className="breadcrumb pt-0">
                                <li className="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                            </ol>
                        </nav>
                        <div className="separator mb-5"></div>
                        <div className="file-upload mb-3 ml-2">
                            <button
                                className="btn btn-outline-primary"
                                type="button"
                                onClick={() => setshow({ open: true, edit: false })}>
                                CREATE USERS
                            </button>
                            {show.open ? (
                                <CreateUsers
                                    open={openSubjectModal}
                                    onClose={closeSubjectModal}
                                    editUserData={editData}
                                    isEditUser={show.edit}
                                />) : null}
                        </div>
                        <div className="tableBody">
                            <div className="table-filter-div">
                                <Grid item xs={6}>
                                    <div className="search-div">
                                        <input
                                            type="text"
                                            className="search"
                                            onKeyUp={searchUser}
                                            placeholder="Search users.."
                                        ></input>
                                        <div className="filter-div">
                                            <select
                                                className="form-control dropdown select2-single"
                                                onChange={handleStatusChange}>
                                                <option value="backoffice">Backoffice</option>
                                                <option value="evaluator">Evaluator</option>
                                                <option value="aspirant">Aspirant</option>
                                            </select>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="rowCount-div">
                                        <label>Users per page : </label>
                                        <select className="selection" onChange={handleRowsPerPage}>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </Grid>
                            </div>
                            <Paper className="paperBody">
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="tableHead" align="center">
                                                    Sl.No
                                                </TableCell>
                                                <TableCell className="tableHead">Name</TableCell>
                                                <TableCell className="tableHead">Email</TableCell>
                                                <TableCell className="tableHead">Phone Number</TableCell>
                                                {data.type === 'evaluator' && <TableCell className="tableHead">Total Evaluation Count</TableCell>}
                                                {data.type === 'evaluator' && <TableCell className="tableHead">Total Earnings</TableCell>}
                                                {data.type === 'evaluator' && <TableCell className="tableHead">Due Amount</TableCell>}
                                                {data.type != 'aspirant' && <TableCell className="tableHead">Action</TableCell>}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {userData &&
                                                userData.users &&
                                                userData.users.map((row, index) => {
                                                    return (
                                                        <TableRow hover key={row._id}>
                                                            <TableCell
                                                                className="tableColData"
                                                                align="center">
                                                                {rowCount * (page - 1) + index + 1}
                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                {row.fullName}
                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                {row.email}
                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                {row.phoneNumber}
                                                            </TableCell>
                                                            {data.type === 'evaluator' && <TableCell className="tableColData">
                                                                {row.totalEvaluatedCount && (row.totalEvaluatedCount.mains || row.totalEvaluatedCount.expertQuestions) ? row.totalEvaluatedCount.mains + row.totalEvaluatedCount.expertQuestions : 0}
                                                            </TableCell>}
                                                            {data.type === 'evaluator' && <TableCell className="tableColData">
                                                                {row.totalEarning}
                                                            </TableCell>}
                                                            {data.type === 'evaluator' && <TableCell className="tableColData">
                                                                {row.totalEarning - row.totalPayedAmount}
                                                            </TableCell>}
                                                            {data.type != 'aspirant' && <TableCell className="tableColData">
                                                                <EditIcon className="edit" fontSize="small" titleAccess="Edit" onClick={() => handleEdit(row)} />
                                                                {data.type === 'evaluator' && row.totalEarning - row.totalPayedAmount > 0 && <MonetizationOnIcon className="edit" fontSize="small" onClick={() => handlePaymentUpdate(row)} titleAccess="Update Payment" />}
                                                                {data.type === 'evaluator' && (row.status === 'published' ? <BlockIcon className="edit" onClick={() => changeStatus(row, 'freeze')} fontSize="small" titleAccess="Freeze" /> : <DoneIcon className="edit" onClick={() => changeStatus(row, 'activate')} fontSize="small" titleAccess="Activate" />)}
                                                            </TableCell>}
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <div className="paginationLabel">
                                    Showing {(page - 1) * rowCount + 1} to{" "}
                                    {(page - 1) * rowCount +
                                        (userData && userData.users
                                            ? userData.users.length
                                            : 0)}{" "}
                                    of{" "}
                                    {userData.totalUsers
                                        ? userData.totalUsers
                                        : 0}{" "}
                                    entries
                                </div>
                                <div className="root">
                                    <Pagination
                                        count={
                                            userData && userData.totalUsers
                                                ? Math.ceil(userData.totalUsers / rowCount)
                                                : 0}
                                        page={page}
                                        onChange={handlePageChange}
                                        variant="outlined"
                                    />
                                </div>
                            </Paper>

                            <Dialog
                                open={dialogue.open}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">{`Do you want to ${dialogue.type} this user?`}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        {dialogue.type === 'freeze' ? 'By clicking freeze button, then this user will not be able to access evaluator app.' :
                                            'By clicking active button, then this user can login to evaluator app.'}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose} color="secondary">
                                        Close
                                    </Button>
                                    <Button onClick={handleAgree} color="primary">
                                        {dialogue.type === 'freeze' ? 'FREEZE' : 'ACTIVATE'}
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            {paymentForm.open && <PaymentForm open={paymentForm.open} paymentData={editData} onClose={closePaymentForm} />}
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

Users.propTypes = {
    fetchUsers: propTypes.func,
    userData: propTypes.object,
    createResult: propTypes.object,
    createUsers: propTypes.func,
    paymentUpdate: propTypes.object
};

const mapStateToProps = (state) => {
    return {
        userData: state.fetchUserReducer.data,
        createResult: state.createUserReducer,
        paymentUpdate: state.updatePaymentReducer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUsers: () => dispatch(fetchUsers(data)),
        createUsers: (data) => dispatch(createUsers(data)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
