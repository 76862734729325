import axios from "axios";
import { FETCH_USER_FAILURE, FETCH_USER_SUCCESS, FETCH_USER_REQUEST, CREATE_USER_REQUEST, CREATE_USER_SUCCESS, CREATE_USER_FAILURE } from "./user.types";
import config from '../../config';
import { toast } from "react-toastify";

export const fetchUsers = (params) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(userRequest());
    axios
      .get(config.API_BASE_URL + "/users/get-all-users", {
        params: {
          skip: params.skip,
          limit: params.limit,
          searchKeyword: params.keyword,
          userType: params.type
        },
      })
      .then((response) => {
        dispatch(userSuccess(response.data));
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        dispatch(userFailure(error.response.data.message));
      });
  };
};

export const userRequest = () => {
  return {
    type: FETCH_USER_REQUEST,
  };
};

export const userSuccess = (data) => {
  return {
    type: FETCH_USER_SUCCESS,
    payload: data,
  };
};

export const userFailure = (data) => {
  return {
    type: FETCH_USER_FAILURE,
    payload: data,
  };
};

export const createUsers=(params)=>{
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(createUserRequest());
    axios
      .post(config.API_BASE_URL + "/users/create-edit-user", params)
      .then((response) => {
        if(response.data.success){
        dispatch(createUserSuccess(response.data));
        params._id ?
          toast.success("User updated successfully")
          : toast.success("User created successfully");
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        dispatch(createUserFailure(error.message));
      });
  };
}

export const createUserRequest=()=>{
   return{
     type: CREATE_USER_REQUEST
   }
}

export const createUserSuccess=(data)=>{
  return{
    type: CREATE_USER_SUCCESS,
    payload:data
  }
}

export const createUserFailure=(data)=>{
   return{
    type: CREATE_USER_FAILURE,
    payload:data
  }
}

export const updatePayment = (params) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(updatePaymetRequest());
    axios
      .post(config.API_BASE_URL + "/users/payment", params)
      .then((response) => {
          dispatch(updatePaymentSuccess(response.data));
          toast.success("Payment data updated successfully")
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        dispatch(updatePaymentFailure(error.message));
      });
  };
}

export const updatePaymetRequest = () => {
  return {
    type: CREATE_USER_REQUEST
  }
}

export const updatePaymentSuccess = (data) => {
  return {
    type: CREATE_USER_SUCCESS,
    payload: data
  }
}

export const updatePaymentFailure = (data) => {
  return {
    type: CREATE_USER_FAILURE,
    payload: data
  }
}