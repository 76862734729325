const { SAMPLE_CASE } = require("./action-types");

const initialState = {
    sampleData: ''
}

const sampleReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch(type) {
        case SAMPLE_CASE:
            return {
                ...state,
                sampleData: payload
            }
        default:
            return state
    }
}

export default sampleReducer