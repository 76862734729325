import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import propTypes from "prop-types";
import { createEditFAQ } from "../../redux/faq/faq-action";
import { connect } from "react-redux";

let initializeQuestion = {
    title: "",
    answer: "",
    questionId: ""
};
let initializeQuestionErrors = {
    title: "",
    answer: ""
};

const CreateQuestion = ({
    open,
    onClose,
    createQuestion,
    edit,
    data
}) => {
    const [questionForm, setForm] = useState(initializeQuestion);
    const [questionErrors, setError] = useState(initializeQuestionErrors);
    useEffect(() => {
        reset();
        if (edit) {
            setForm({
                title: data.title,
                answer: data.answer,
                questionId: data._id
            });
        } else {
            setForm({
                title: "",
                answer: ""
            });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // For reseting form error values.
    const reset = () => {
        initializeQuestionErrors = {
            title: "",
            answer: ""
        };
        setError(initializeQuestionErrors);
    };

    // Handle when user inputs are received.
    const handleFormInputChange = (event) => {
        event.persist();
        const { name, value } = event.target;
        if (name === 'title') {
            setForm({
                ...questionForm,
                title: value
            })
            questionErrors.title = "";

        } else {
            setForm({
                ...questionForm,
                answer: value
            })
            questionErrors.answer = "";
        }
    };
    const saveQuestion = () => {
        if (validateQuestionForm(questionErrors)) {
            if (edit) {
                createQuestion(questionForm);
            } else {
                createQuestion({
                    title: questionForm.title,
                    answer: questionForm.answer
                });
            }
            onClose();
        }
    }
    // Form validation.
    const validateQuestionForm = (questionErrors) => {
        let isValid = true;
        let inputs = questionForm;
        if (inputs["answer"] === "") {
            setError({
                ...questionErrors,
                answer: "Answer should not be empty"
            })
            isValid = false;
        }
        if (inputs["title"] === "") {
            setError({
                ...questionErrors,
                title: 'Question title should not be empty'
            })
            isValid = false;
        }
        return isValid;
    };
    return (
        <Modal open={open} className="modal">
            <div className="paper">
                <label className="form-group has-float-label mb-4">
                    <input
                        className="form-control"
                        type="text"
                        name="title"
                        value={questionForm.title}
                        onChange={(e) => handleFormInputChange(e)}
                    />
                    <span className="error">{questionErrors.title}</span>
                    <span>Question</span>
                </label>
                <label className="form-group has-float-label mb-4">
                    <textarea
                        className="form-control"
                        type="text"
                        rows="3"
                        name="answer"
                        value={questionForm.answer}
                        onChange={(e) => handleFormInputChange(e)}
                    />
                    <span className="error">{questionErrors.answer}</span>
                    <span>Answer</span>
                </label>
                <div className="align-items-right text-right mt-4">
                    <button
                        type="button"
                        onClick={onClose}
                        className="btn btn-danger btn-lg btn-shadow mr-3"
                    >
                        CANCEL
          </button>
                    <button
                        type="button"
                        onClick={saveQuestion}
                        className="btn btn-success btn-lg btn-shadow"
                    >
                        SAVE
          </button>
                </div>
            </div>
        </Modal>
    );
};

CreateQuestion.propTypes = {
    open: propTypes.bool.isRequired,
    onClose: propTypes.func.isRequired,
    createQuestion: propTypes.func,
    edit: propTypes.bool,
    data: propTypes.object
};

const mapDispatchToProps = (dispatch) => {
    return {
        createQuestion: (data) => dispatch(createEditFAQ(data)),
    };
};


export default connect(null, mapDispatchToProps)(CreateQuestion);
