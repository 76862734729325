import axios from 'axios';
import config from '../../config';
import { toast } from "react-toastify";
import {
    FETCH_GAME_REQUEST,
    FETCH_GAME_SUCCESS,
    FETCH_GAME_FAILURE,
    CREATE_GAME_REQUEST,
    CREATE_GAME_SUCCESS,
    CREATE_GAME_FAILURE
} from "./game-types";

export const fetchGames=(data)=>{
    return(dispatch)=>{
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem("jwtToken")}`;
      dispatch(fetchGameRequest());
      axios
        .get(config.API_BASE_URL + '/game/list-games', {
          params: {
            skip: data.skip,
            limit: data.limit,
            keyword: data.keyword,
            userType: data.userType
          }
        })
        .then((response) => {
          const {data} = response
          dispatch(fetchGameSuccess(data));
        })
        .catch(error => {
          dispatch(fetchGameFailure(error.message));
          toast.error("Failed to fetch games. Something went wrong" + error.message);
          // dispatch(fetchGameFailure(error.message));
        });
    }
  }
  
  export const fetchGameRequest=()=>{
    return {
    type: FETCH_GAME_REQUEST,
    };
  }
  
  export const fetchGameSuccess=(data)=>{
    return {
      type: FETCH_GAME_SUCCESS,
      payload: data,
    };
  }
  
  export const fetchGameFailure=(error)=>{
    return{
      type: FETCH_GAME_FAILURE,
      payload: error,
    };
  };

export const createGame=(data)=>{
  return(dispatch)=>{
  axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem("jwtToken")}`;
    dispatch(createGameRequest());
    axios
      .post(config.API_BASE_URL + '/game/create-edit', data )
      .then(response => {
        const result = response.data;
        if(data._id)
        toast.success("Game updated successfully.");
        else
        toast.success("Game created successfully.");
        dispatch(createGameSuccess(result));
      })
      .catch(error => {
        if(data._id)
        toast.error("Failed to update game. Something went wrong");
        else
        toast.error("Failed to create game. Something went wrong");
        dispatch(createGameFailure(error.message));
      });
  }
}

export const createGameRequest=()=>{
  return {
  type: CREATE_GAME_REQUEST,
  };
}

export const createGameSuccess=(data)=>{
  return {
    type: CREATE_GAME_SUCCESS,
    payload: data,
  };
}

export const createGameFailure=(error)=>{
  return{
    type: CREATE_GAME_FAILURE,
    payload: error,
  };
};
