export function Capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Methods to convert seconds to hours:minute:seconds
 */
export function Transform(seconds) {
  seconds = Number(seconds);
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);
  const hDisplay = h > 9 ? h : 0 + "" + h;
  const mDisplay = m > 9 ? m : 0 + "" + m;
  const sDisplay = s > 9 ? s : 0 + "" + s;
  return hDisplay + ":" + mDisplay + ":" + sDisplay;
}

export const imageValidation = (filePath) => {
  const allowedExtensions = ["jpg", "jpeg", "png"];
  const extension = filePath.split(".");
  return allowedExtensions.indexOf(
    extension[extension.length - 1].toLowerCase()
  );
};
export const convertBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

/**
 * Methods to convert days to months.
 */
export function DaysToMonths(days) {
  const months = Math.floor(days / 30);
  return months > 1 ? months + " months" : months + " month";
}
