import React, { useEffect, useState } from "react";
import {
    fetchGames
} from "../../redux/game/game-action";
import propTypes from "prop-types";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Pagination from "@material-ui/lab/Pagination";
import { Capitalize } from "../../util/helper";
import EditIcon from "@material-ui/icons/Edit";
import moment from 'moment';
import CreateGame from './create-game';
import GameStatusPopup from '../../components/popup/game-status-popup'
let data = {
    skip: 0,
    limit: 10,
    keyword: "",
    userType: "all"
};
const Game = ({ gameResult, fetchGame, editGameResult }) => {
    const [page, setPage] = useState(1);
    const [rowCount, setRowCount] = useState(10);
    const [gameModal, setGameModal] = useState(false);
    const [gameData, setGameData] = useState({});
    const [editGame, setEditGame] = useState(false);
    const [statusPopup, setStatusPopup] = useState(false);
    const [action,setAction] = useState('')
    useEffect(() => {
        fetchGame(data);
    }, [editGameResult]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleRowsPerPage = (event) => {
        setRowCount(event.target.value);
        setPage(1);
        data.limit = event.target.value;
        data.skip = 0;
        fetchGame(data);
    };
    const searchGame = (event) => {
        data.skip = 0;
        data.keyword = event.target.value;
        setPage(1);
        fetchGame(data);
    };
    const handlePageChange = (event, newPage) => {
        setPage(newPage);
        data.skip = (newPage - 1) * rowCount;
        fetchGame(data);
    };
    const handleUserTypeChange = (event) => {
        data.skip = 0;
        setPage(1);
        data.userType = event.target.value;
        fetchGame(data);
    }
    const handleEditGame = (row) => {
        setEditGame(true);
        setGameData(row);
        setGameModal(true);
    }
    const createGameHandler = () => {
        setEditGame(false);
        setGameModal(true);
    }
    const closeGameModalHandler = () => {
        setGameModal(false);
        setStatusPopup(false)
    }
    const handleStatus = (row, action) => {
        setStatusPopup(true)
        setGameData(row)
        setAction(action)
    }
    return (
        <main>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <h1>Games</h1>
                        <nav
                            className="breadcrumb-container d-none d-sm-block d-lg-inline-block"
                            aria-label="breadcrumb"
                        >
                            <ol className="breadcrumb pt-0">
                                <li className="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                            </ol>
                        </nav>
                        <div className="separator mb-5">
                        <div className=" ml-2 mb-3">
                            <button
                                className="btn btn-outline-primary mr-3"
                                type="button"
                                onClick={createGameHandler}
                            >
                                CREATE GAME
                            </button>
                        </div>
                        {
                            gameModal ? (
                            <CreateGame
                                open={gameModal}
                                edit={editGame}
                                data={gameData}
                                onClose={closeGameModalHandler}
                            />
                    ) : null
                  }
                        {
                            statusPopup && 
                            <GameStatusPopup
                                open={statusPopup}
                                onClose={closeGameModalHandler}
                                row={gameData}
                                action={action}
                            /> 
                        }
                        </div>
                        <div className="tableBody">
                            <div className="table-filter-div">
                                <div className="row">
                                    <div className="col serach-div">
                                        <input
                                            type="text"
                                            style={{ maxWidth: 160 }}
                                            className="search"
                                            onKeyUp={searchGame}
                                            placeholder="Search game..."
                                        ></input>
                                    </div>
                                    <div className="col">
                                        <select
                                            style={{ maxWidth: 160 }}
                                            className="form-control dropdown select2-single"
                                            onChange={(ev) => handleUserTypeChange(ev)}
                                        >
                                            <option value="all">All</option>
                                            <option value="admin">Admin</option>
                                            <option value="aspirant">Aspirant</option>
                                        </select>
                                    </div>
                                </div>
                                <Grid style={{ marginLeft: 200 }} item xs={6}>
                                    <div className="rowCount-div">
                                        <label>Games per page : </label>
                                        <select className="selection" onChange={handleRowsPerPage}>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </Grid>
                            </div>
                            <Paper className="paperBody">
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="tableHead" align="center">Sl.No</TableCell>
                                                <TableCell className="tableHead">Title</TableCell>
                                                <TableCell className="tableHead">Subject</TableCell>
                                                <TableCell className="tableHead">Created By</TableCell>
                                                {/* <TableCell className="tableHead">Duration</TableCell> */}
                                                <TableCell className="tableHead">Num Of Questions</TableCell>
                                                <TableCell className="tableHead">Start Time</TableCell>
                                                <TableCell className="tableHead">Status</TableCell>
                                                {data.userType != 'aspirant' && <TableCell className="tableHead">Actions</TableCell>}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                gameResult &&
                                                gameResult.games &&
                                                gameResult.games.map((row, index) => {
                                                    return (
                                                        <TableRow hover key={row._id}>
                                                            <TableCell
                                                                className="tableColData"
                                                                align="center"
                                                            >
                                                                {rowCount * (page - 1) + index + 1}
                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                {Capitalize(row.title)}
                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                {row.subject.name}
                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                {Capitalize(row.createdBy)}
                                                            </TableCell>
                                                            {/* <TableCell className="tableColData">
                                                                {row.duration}
                                                            </TableCell> */}
                                                            <TableCell className="tableColData">
                                                                {row.numOfQuestions}
                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                {moment(row.startDate).format("DD/MM/YYYY hh:mm a")}
                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                {Capitalize(row.status)}
                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                <span className="mr-1">
                                                                    {
                                                                        row.createdBy === 'admin' && (
                                                                            <EditIcon
                                                                                titleAccess="Edit"
                                                                                aria-label="edit"
                                                                                fontSize="small"
                                                                                onClick={() => handleEditGame(row)}
                                                                            />
                                                                        )
                                                                    }
                                                                </span>
                                                                { row.createdBy === 'admin' && row.status === "draft"  ? (
                                                                    <DoneIcon
                                                                        className="publish text-success"
                                                                        fontSize="small"
                                                                        titleAccess="Publish"
                                                                        onClick={() => handleStatus(row, "published")}
                                                                    />
                                                                ) : null}
                                                                {row.createdBy === 'admin' && row.status === "published"  ? (
                                                                    <CloseIcon
                                                                        className="draft text-danger"
                                                                        fontSize="small"
                                                                        titleAccess="Draft"
                                                                        onClick={() => handleStatus(row, "draft")}
                                                                    />
                                                                ) : null}
                                                                
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <div className="paginationLabel">
                                    Showing {(page - 1) * rowCount + 1} to{" "}
                                    {(page - 1) * rowCount +
                                        (gameResult && gameResult.games
                                            ? gameResult.games.length
                                            : 0)}{" "}
                  of{" "}
                                    {gameResult.totalNumOfGames
                                        ? gameResult.totalNumOfGames
                                        : 0}{" "}
                  entries
                </div>
                                <div className="root">
                                    <Pagination
                                        count={
                                            gameResult && gameResult.totalNumOfGames
                                                ? Math.ceil(gameResult.totalNumOfGames / rowCount)
                                                : 0
                                        }
                                        page={page}
                                        onChange={handlePageChange}
                                        variant="outlined"
                                    />
                                </div>
                            </Paper>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

Game.propTypes = {
    fetchGame: propTypes.func,
    gameResult: propTypes.object,
    editGameResult: propTypes.object
};

const mapStateToProps = (state) => {
    return {
        gameResult: state.gameReducer.data || {},
        editGameResult: state.createGameReducer
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchGame: () => dispatch(fetchGames(data)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Game);
