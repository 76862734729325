import axios from "axios";
import config from "../../config";
import { toast } from "react-toastify";
import {
  FETCH_ARTICLE_REQUEST,
  FETCH_ARTICLE_SUCCESS,
  FETCH_ARTICLE_FAILURE,
  CREATE_ARTICLE_REQUEST,
  CREATE_ARTICLE_SUCCESS,
  CREATE_ARTICLE_FAILURE,
  FETCH_ARTICLE_IMAGE_REQUEST,
  FETCH_ARTICLE_IMAGE_SUCCESS,
  FETCH_ARTICLE_IMAGE_FAILURE,
  CREATE_ARTICLE_IMAGE_REQUEST,
  CREATE_ARTICLE_IMAGE_SUCCESS,
  CREATE_ARTICLE_IMAGE_FAILURE,
} from "./article-types";

export const fetchArticles = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(fetchArticleRequest());
    axios
      .post(config.API_BASE_URL + "/daily-article/list-articles", data)
      .then((response) => {
        const result = response.data;
        dispatch(fetchArticleSuccess(result));
      })
      .catch((error) => {
        dispatch(fetchArticleFailure(error.message));
        toast.error(
          "Failed to fetch articles. Something went wrong" + error.message
        );
      });
  };
};

export const fetchArticleRequest = () => {
  return {
    type: FETCH_ARTICLE_REQUEST,
  };
};

export const fetchArticleSuccess = (data) => {
  return {
    type: FETCH_ARTICLE_SUCCESS,
    payload: data,
  };
};

export const fetchArticleFailure = (error) => {
  return {
    type: FETCH_ARTICLE_FAILURE,
    payload: error,
  };
};

export const createArticle = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(createArticleRequest());
    axios
      .post(config.API_BASE_URL + "/daily-article/create-edit-article", data)
      .then((response) => {
        const result = response.data;
        if (data._id) toast.success("Article updated successfully.");
        else toast.success("Article created successfully.");
        dispatch(createArticleSuccess(result));
      })
      .catch((error) => {
        if (data._id)
          toast.error("Failed to update article. Something went wrong");
        else toast.error("Failed to create article. Something went wrong");
        dispatch(createArticleFailure(error.message));
      });
  };
};

export const createArticleRequest = () => {
  return {
    type: CREATE_ARTICLE_REQUEST,
  };
};

export const createArticleSuccess = (data) => {
  return {
    type: CREATE_ARTICLE_SUCCESS,
    payload: data,
  };
};

export const createArticleFailure = (error) => {
  return {
    type: CREATE_ARTICLE_FAILURE,
    payload: error,
  };
};

export const fetchArticleImages = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(fetchArticleImageRequest());
    axios
      .get(config.API_BASE_URL + "/daily-article/get-article-images", {
        params: {
          skip: data.skip,
          limit: data.limit,
        },
      })
      .then((response) => {
        dispatch(fetchArticleImageSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchArticleImageFailure(error.message));
        toast.error(
          "Failed to fetch article images. Something went wrong" + error.message
        );
      });
  };
};

export const fetchArticleImageRequest = () => {
  return {
    type: FETCH_ARTICLE_IMAGE_REQUEST,
  };
};

export const fetchArticleImageSuccess = (data) => {
  return {
    type: FETCH_ARTICLE_IMAGE_SUCCESS,
    payload: data,
  };
};

export const fetchArticleImageFailure = (error) => {
  return {
    type: FETCH_ARTICLE_IMAGE_FAILURE,
    payload: error,
  };
};

export const createArticleImage = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(createArticleImageRequest());
    axios
      .post(
        config.API_BASE_URL + "/daily-article/create-edit-article-image",
        data
      )
      .then((response) => {
        const result = response.data;
        if (data._id) toast.success("Article image updated successfully.");
        else toast.success("Article image created successfully.");
        dispatch(createArticleImageSuccess(result));
      })
      .catch((error) => {
        if (data._id)
          toast.error("Failed to update article image. Something went wrong");
        else
          toast.error("Failed to create article image. Something went wrong");
        dispatch(createArticleImageFailure(error.message));
      });
  };
};

export const createArticleImageRequest = () => {
  return {
    type: CREATE_ARTICLE_IMAGE_REQUEST,
  };
};

export const createArticleImageSuccess = (data) => {
  return {
    type: CREATE_ARTICLE_IMAGE_SUCCESS,
    payload: data,
  };
};

export const createArticleImageFailure = (error) => {
  return {
    type: CREATE_ARTICLE_IMAGE_FAILURE,
    payload: error,
  };
};
