import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { useDispatch } from "react-redux";
import { updateMainsQuestion } from "../../redux/questions/questions-action";
const MainsStatusPopup = (props) => {
  const dispatch = useDispatch();
  const { row } = props;
  const handleClose = (e, action) => {
    e.stopPropagation();
    if (action === "published" || action === "draft") {
      let param = {
        status: action,
        questionId: row._id
      };
      dispatch(updateMainsQuestion(param));
    }
    props.onClose();
  };
  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {row.status === "draft" && props.action === "published" ?
            "Do you want to publish this question?" : "Do you want to draft this question?"}
        </DialogTitle>
        <DialogActions>
          <Button
            variant="text"
            onClick={(e) => handleClose(e, "close")}
            id="close"
            color="secondary"
          >
            Close
            </Button>
          <Button
            variant="text"
            id="approve"
            onClick={(e) => handleClose(e, row.status === "draft" && props.action === "published"
              ? "published" : "draft")}
            color="primary"
          >
            {row.status === "draft" && props.action === "published" ? "Publish" : "Draft" }
            </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
MainsStatusPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
  action: PropTypes.string.isRequired,
};
export default MainsStatusPopup;
