const initialState = {
  loading: true,
  mentorship: {},
  error: "",
};

const availableMentorshipsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_MENTORSHIP_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_MENTORSHIP_SUCCESS":
      return {
        loading: false,
        mentorship: action.payload,
        error: "",
      };
    case "FETCH_MENTORSHIP_FAILURE":
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
const createMentorshipReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CREATE_MENTORSHIP_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "CREATE_MENTORSHIP_SUCCESS":
      return {
        loading: false,
        result: action.payload,
        error: "",
      };
    case "CREATE_MENTORSHIP_FAILURE":
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
const fetchUserPurchasedReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_PURCHASED_USERS_REQUEST":
      return {
        loading: true,
      };
    case "FETCH_PURCHASED_USERS_SUCCESS":
      return {
        ...state,
        loading: false,
        mentorship: action.payload,
      };
    case "FETCH_PURCHASED_USERS_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  availableMentorshipsReducer,
  createMentorshipReducer,
  fetchUserPurchasedReducer,
};
