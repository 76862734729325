import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import SubMenu from "./sub-menu";
import { sidemenudata } from "../../assets/data/side-menu-data";
import "../../assets/css/vendor/perfect-scrollbar.css";
const initialState = sidemenudata;
let data = initialState.subPages;
let againOpen = "";
const SideMenu = () => {
  let { pathname } = useLocation();
  pathname = pathname.split("/");
  useEffect(() => {
    return initialState.links.map((item) => {
      if (item.to === pathname[1]) {
        setState((prevState) => ({
          ...prevState,
          activeLink: item.id,
        }));
      } else if (pathname[1] === "") {
        setState((prevState) => ({
          ...prevState,
          activeLink: 1,
        }));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [{ links, activeLink, subMenuLink }, setState] = useState(initialState);
  const [isOpenSecondMenu, setIsOpenSecondMenu] = useState(false);
  const handleClick = (e) => {
    e.stopPropagation();
    let menuName = e.target.dataset.menuname;
    if (menuName !== againOpen) {
      data = initialState[menuName];
      setIsOpenSecondMenu(true);
      againOpen = menuName;
    } else {
      setIsOpenSecondMenu(false);
      againOpen = "";
    }
  };
  const closeSubMenu = (e, id, subId) => {
    setIsOpenSecondMenu(false);
    againOpen = "";
    setState((prevState) => ({
      ...prevState,
      activeLink: id,
      subMenuLink: subId,
    }));
  };
  return (
    <div className="menu">
      <div className="main-menu showMenu">
        <div className="div-scroll scroll ps ps--active-y">
          <ul className="ul-scroll list-unstyled">
            {links.map((link) => {
              return (
                <React.Fragment key={link.id}>
                  <li
                    data-menuname={link.menuName}
                    className={link.id === activeLink ? "active menu-item" : "menu-item"}
                  >
                    <Link
                      exact
                      to="#"
                      onClick={(e) => handleClick(e, link.id)}
                      data-menuname={link.menuName}
                    >
                      <i
                        className={link.className}
                        data-menuname={link.menuName}
                      ></i>
                      <span data-menuname={link.menuName}>{link.name}</span>
                    </Link>
                  </li>
                </React.Fragment>
              );
            })}
          </ul>
        </div>
      </div>
      {isOpenSecondMenu ? (
        <SubMenu
          data={data || []}
          subMenuLink={subMenuLink}
          closeSubMenu={closeSubMenu}
        />
      ) : null}
    </div>
  );
};
export default SideMenu;
