import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import "../../pages/Question/approved-question.css";
import { useHistory } from "react-router-dom";
import PrelimTestPopup from "../popup/prelim-test-popup";
import SyllabusUploadPopup from "../popup/syllabus-upload-popup";
import PropTypes from "prop-types";
import { Capitalize } from '../../util/helper';
import moment from 'moment';
const columns = [
  { label: "Sl.no", align: "center" },
  { label: "Name" },
  {
    label: "Start Date",
    format: (value) => {
      value = value.split("-").reverse().join("-");
      return value;
    },
  },
  {
    label: "Total Marks",
    align: "center",
  },
  {
    label: "Total Questions",
    align: "center",
  },
  {
    label: "Status",
    format: (value) => {
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  {
    label: "Actions",
  },
];
const PrelimListTable = (props) => {
  const [show, setShow] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [rowData, setRowData] = useState({});
  const [action, setAction] = useState("");
  const { data, permission } = props;
  let history = useHistory();
  const handleEdit = (e, prelimTestId) => {
    e.preventDefault();
    history.push({
      pathname: `/exam/create-exam/${prelimTestId}`,
      state: "prelimtest",
    });
  };
  const handlePopup = (e, row, action) => {
    setRowData(row);
    setAction(action);
    setShow(true);
  };
  const handleUpload = (e, row) => {
    setRowData(row);
    setShowUpload(true);
  }
  const handleClose = () => {
    setShow(false);
  };
  const handleUploadClose = () => {
    setShowUpload(false);
  };
  const tableHeader = (
    <TableHead>
      <TableRow>
        {columns.map((column, index) => (
          <TableCell className="tableHead" key={index} align={column.align}>
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
  return (
    <Paper className="paperBody">
      <TableContainer>
        <Table>
          {tableHeader}
          {data && data.length ? (
            <TableBody>
              {data.map((row, index) => {
                return (
                  <TableRow hover key={index}>
                    <TableCell className="tableColData" align="center">
                      {index + 1}
                    </TableCell>
                    <TableCell className="tableColData">{row.title}</TableCell>
                    <TableCell className="tableColData">
                      {moment(row.startDate).format('DD/MM/YYYY hh:mm a')}
                    </TableCell>
                    <TableCell className="tableColData" align="center">
                      {row.maxMarks}
                    </TableCell>
                    <TableCell className="tableColData" align="center">
                      {row.questionCount}
                    </TableCell>
                    <TableCell className="tableColData">
                      {row.status ? Capitalize(row.status) : null}
                    </TableCell>
                    <TableCell className="tableColData">
                      {permission.edit ? (
                        <EditIcon
                          className="edit"
                          fontSize="small"
                          titleAccess="Edit"
                          onClick={(e) => handleEdit(e, row._id)}
                        />
                      ) : null}
                      {row.status === "draft" && permission.custom ? (
                        <DoneIcon
                          className="publish text-success"
                          fontSize="small"
                          titleAccess="Publish"
                          onClick={(e) => handlePopup(e, row, "published")}
                        />
                      ) : null}
                      {row.status === "published" && permission.custom ? (
                        <CloseIcon
                          className="draft text-danger"
                          fontSize="small"
                          titleAccess="Draft"
                          onClick={(e) => handlePopup(e, row, "draft")}
                        />
                      ) : null}
                      <CloudUploadIcon
                        className="ml-2"
                        fontSize="small"
                        titleAccess="Upload Syllabus"
                        onClick={(e) => handleUpload(e, row)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
              <TableBody>
                <TableRow hover>
                  <TableCell colSpan="6" align="center">
                    No Data to Display
                </TableCell>
                </TableRow>
              </TableBody>
            )}

          {show ? (
            <PrelimTestPopup
              open={show}
              onClose={handleClose}
              row={rowData}
              action={action}
            />
          ) : null}
          {showUpload ? (
            <SyllabusUploadPopup
              open={showUpload}
              onClose={handleUploadClose}
              row={rowData}
            />
          ) : null}
        </Table>
      </TableContainer>
    </Paper>
  );
};
PrelimListTable.propTypes = {
  data: PropTypes.array.isRequired,
  permission: PropTypes.object.isRequired,
};
export default PrelimListTable;
