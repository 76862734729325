import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";
import { fetchUserPurchased } from "../../redux/mentorship/mentorship-action";
let data = {
  skip: 0,
  limit: 10,
  keyword: "",
};
const Purchasedusers = () => {
  const [page, setPage] = useState(1);
  const [rowCount, setRowCount] = useState(10);
  const dispatch = useDispatch();
  const purchasedUsers = useSelector(
    (state) => state.fetchUserPurchasedReducer.mentorship
  );
  useEffect(() => {
    dispatch(fetchUserPurchased(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const searchMentorship = (e) => {
    data.skip = 0;
    data.keyword = e.target.value;
    setPage(1);
    dispatch(fetchUserPurchased(data));
  };
  const handleRowsPerPage = (e) => {
    setRowCount(e.target.value);
    setPage(1);
    data.limit = e.target.value;
    data.skip = 0;
    dispatch(fetchUserPurchased(data));
  };
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    data.skip = (newPage - 1) * rowCount;
    dispatch(fetchUserPurchased(data));
  };
  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
          <h1>Purchased Users</h1>
          <nav
            className="breadcrumb-container d-none d-sm-block d-lg-inline-block"
            aria-label="breadcrumb"
          >
            <ol className="breadcrumb pt-0">
              <li className="breadcrumb-item">
                <a href="/exam/daily-test">Home</a>
              </li>
            </ol>
          </nav>
          <div className="separator mb-5"></div>
          <div className="tableBody">
            <div className="table-filter-div">
              <Grid item xs={6}>
                <div className="search-div">
                  <input
                    type="text"
                    className="search"
                    onKeyUp={searchMentorship}
                    placeholder="Search user..."
                  ></input>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="rowCount-div">
                  <label>Users per page : </label>
                  <select className="selection" onChange={handleRowsPerPage}>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
              </Grid>
            </div>
            <Paper className="paperBody">
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="tableHead" align="center">
                        {" "}
                        Sl.No{" "}
                      </TableCell>
                      <TableCell className="tableHead" align="center">
                        Aspirant Name
                      </TableCell>
                      <TableCell className="tableHead" align="center">
                        Contact Number
                      </TableCell>
                      <TableCell className="tableHead" align="center">
                        Plan Name
                      </TableCell>
                      <TableCell className="tableHead" align="center">
                        Duration
                      </TableCell>
                      <TableCell className="tableHead" align="center">
                        Purchased Price
                      </TableCell>
                      <TableCell className="tableHead" align="center">
                        Purchased Date
                      </TableCell>
                      <TableCell className="tableHead" align="center">
                        End Date
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {purchasedUsers &&
                      purchasedUsers.users &&
                      purchasedUsers.users.map((row, index) => {
                        return (
                          <TableRow hover key={row._id}>
                          <TableCell className="tableColData" align="center">
                              {rowCount * (page - 1) + index + 1}
                            </TableCell>
                            <TableCell className="tableColData" align="center">
                              {row.name}
                            </TableCell>
                            <TableCell className="tableColData" align="center">
                              {row.phoneNumber}
                            </TableCell>
                            <TableCell className="tableColData" align="center">
                              {row.planName}
                            </TableCell>
                            <TableCell className="tableColData" align="center">
                              {row.duration} days
                            </TableCell>
                            <TableCell className="tableColData" align="center">
                              ₹{row.offerPrice}
                            </TableCell>
                            <TableCell className="tableColData" align="center">
                              {moment(row.purchaseDate).format(
                                "DD/MM/YYYY HH:mm"
                              )}
                            </TableCell>
                            <TableCell className="tableColData" align="center">
                              {moment(row.endDate).format("DD/MM/YYYY HH:mm")}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="paginationLabel">
                Showing {(page - 1) * rowCount + 1} to{" "}
                {(page - 1) * rowCount +
                  (purchasedUsers && purchasedUsers.totalUsers
                    ? purchasedUsers.users.length
                    : 0)}{" "}
                of{" "}
                {purchasedUsers
                  ? purchasedUsers.totalUsers
                  : 0}{" "}
                entries
              </div>
              <div className="root">
                <Pagination
                  count={
                    purchasedUsers && purchasedUsers.totalUsers
                      ? Math.ceil(
                          purchasedUsers.totalUsers / rowCount
                        )
                      : 0
                  }
                  page={page}
                  onChange={handlePageChange}
                  variant="outlined"
                />
              </div>
            </Paper>
          </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default Purchasedusers;
