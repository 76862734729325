import React, {useState, useEffect} from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import {connect} from "react-redux";
import {fetchAvailableCourses, changeCourseStatus} from "../../redux/course/course-action";
import propTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import BlockIcon from '@material-ui/icons/Block';
import {green} from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {getPermission} from '../../util/auth';
import {useHistory} from "react-router-dom";
import {Capitalize} from '../../util/helper';
import {useSelector} from 'react-redux';
import DeleteIcon from "@material-ui/icons/Delete";
import VideoLink from "../../components/popup/video-link";

let data = {
  skip: 0,
  limit: 10,
  keyword: "",
};

let statusData = {
  courseId: '',
  status: ''
};

const CourseListing = ({availableCourses, fetchAvailableCourses, changeCourseStatus}) => {
  let history = useHistory();
  const [page, setPage] = useState(1);
  const [rowCount, setRowCount] = useState(10);
  const [dialogue, setOpen] = useState({open: false, type: 'publish', course: ''});
  const {open, type, course} = dialogue;
  const [modal, setModal] = useState(false);
  const [courseData, setCourseData] = useState({type: '', data: ''});

  data.limit = rowCount;
  data.skip = (page - 1) * rowCount;
  const updateCourseStatus = useSelector((state) => state.changeCourseStatusReducer);
  const statusChange = useSelector((state) => state.createCourseReducer);

  useEffect(() => {
    fetchAvailableCourses(data);
  }, [statusChange, updateCourseStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    data.skip = (newPage - 1) * rowCount;
    fetchAvailableCourses(data);
  };

  const searchCourse = (event) => {
    data.skip = 0;
    data.keyword = event.target.value;
    setPage(1);
    fetchAvailableCourses(data);
  };

  const handleRowsPerPage = (event) => {
    setRowCount(event.target.value);
    setPage(1);
    data.limit = event.target.value;
    data.skip = 0;
    fetchAvailableCourses(data);
  };

  const handleEditCourse = (rowData) => {
    history.push('/course/create', {edit: true, courseId: rowData.courseId});
  }
  const createCourse = () => {
    history.push('/course/create', {edit: false});
  }

  const handlePublishCourse = (rowData) => {
    setOpen({open: true, type: 'published', course: rowData.courseId});
  }

  const handleDeactivateCourse = (rowData) => {
    setOpen({open: true, type: 'deactivated', course: rowData.courseId});
  }

  const handleClose = () => {
    setOpen({open: false});
  };

  const handleAgree = () => {
    statusData.courseId = course
    statusData.status = type
    setOpen({open: false});
    changeCourseStatus(statusData);
  }

  const handleDelete =(e, row) =>{
    e.preventDefault();
    setCourseData({type: 'deleteCourse', data: row});
    setModal(true);
  }

  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Courses</h1>
            <nav
              className="breadcrumb-container d-none d-sm-block d-lg-inline-block"
              aria-label="breadcrumb"
            >
              <ol className="breadcrumb pt-0">
                <li className="breadcrumb-item">
                  <div>Home</div>
                </li>
              </ol>
            </nav>
            <div className="separator mb-5"></div>
            {
              getPermission().course.write ?
                <div className="file-upload mb-3 ml-2">
                  <button
                    className="btn btn-outline-primary"
                    type="button"
                    onClick={createCourse}
                  >
                    CREATE COURSE
                  </button>
                </div> : null
            }
            <div className="tableBody">
              <div className="table-filter-div">
                <Grid item xs={6}>
                  <div className="search-div">
                    <input
                      type="text"
                      className="search"
                      onKeyUp={searchCourse}
                      placeholder="Search course by name and status.."
                    ></input>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="rowCount-div">
                    <label>Courses per page : </label>
                    <select className="selection" onChange={handleRowsPerPage}>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </Grid>
              </div>
              <Paper className="paperBody">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="tableHead" align="center">
                          Sl.No
                        </TableCell>
                        <TableCell className="tableHead">Name</TableCell>
                        <TableCell className="tableHead">Description</TableCell>
                        <TableCell className="tableHead" align="center">
                          No. Of Subjects
                        </TableCell>
                        <TableCell className="tableHead" align="center">No. Of Topics</TableCell>
                        <TableCell className="tableHead" align="center">Order</TableCell>
                        <TableCell className="tableHead" align="center">Status</TableCell>
                        <TableCell className="tableHead" align="center">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {availableCourses &&
                        availableCourses.course &&
                        availableCourses.course.map((row, index) => {
                          return (
                            <TableRow hover key={row.courseId}>
                              <TableCell
                                className="tableColData"
                                align="center"
                              >
                                {rowCount * (page - 1) + index + 1}
                              </TableCell>
                              <TableCell className="tableColData" width="300">
                                {row.name}
                              </TableCell>
                              <TableCell className="tableColData" width="400">
                                <div dangerouslySetInnerHTML={{__html: row.description}}/>
                              </TableCell>
                              <TableCell className="tableColData" align="center">
                                {row.numOfSubjects}
                              </TableCell>
                              <TableCell className="tableColData" align="center">
                                {row.numOfTopics}
                              </TableCell>
                              <TableCell className="tableColData" align="center">
                                {row.order}
                              </TableCell>
                              <TableCell className="tableColData" align="center">
                                {Capitalize(row.status)}
                              </TableCell>
                              <TableCell className="tableColData" align="center" width="130px">
                                {
                                  getPermission().course.edit ?
                                    <EditIcon titleAccess="Edit" aria-label="edit" fontSize="small"
                                              onClick={() => handleEditCourse(row)}/>
                                    : null
                                }
                                {
                                  getPermission().course.custom && row.status !== 'draft' ?
                                    (row.status === 'published' ?
                                        <BlockIcon titleAccess="Deactivate" color="secondary" aria-label="deactivate"
                                                   fontSize="small" onClick={() => handleDeactivateCourse(row)}/>
                                        :
                                        <DoneIcon titleAccess="Publish" style={{color: green[500]}} aria-label="publish"
                                                  fontSize="small" onClick={() => handlePublishCourse(row)}/>
                                    ) : null
                                }
                                <DeleteIcon color="secondary" className="delete" titleAccess="Delete"
                                            onClick={(ev) => handleDelete(ev, row)}/>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className="paginationLabel">
                  Showing {(page - 1) * rowCount + 1} to{" "}
                  {(page - 1) * rowCount +
                    (availableCourses && availableCourses.course
                      ? availableCourses.course.length
                      : 0)}{" "}
                  of{" "}
                  {availableCourses.totalCourses
                    ? availableCourses.totalCourses
                    : 0}{" "}
                  entries
                </div>
                <div className="root">
                  <Pagination
                    count={
                      availableCourses && availableCourses.totalCourses
                        ? Math.ceil(availableCourses.totalCourses / rowCount)
                        : 0
                    }
                    page={page}
                    onChange={handlePageChange}
                    variant="outlined"
                  />
                </div>
              </Paper>
            </div>


            <Dialog
              open={open}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{`Do you want to ${type} this course?`}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {type === 'published' ? 'By clicking publish button this course will be listout in aspirant app.' :
                    'By clicking deactivate button, this course will be disappear from aspirant app.'}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="secondary">
                  Close
                </Button>
                <Button onClick={handleAgree} color="primary">
                  {type === 'published' ? 'PUBLISH' : 'DEACTIVATE'}
                </Button>
              </DialogActions>
            </Dialog>


          </div>
        </div>
      </div>

      {modal ? <VideoLink open={modal} onClose={() => {
        setModal(false);
        fetchAvailableCourses(data);
      }} type={courseData.type} videoData={courseData.data}/> : null}
    </main>
  );
};
CourseListing.propTypes = {
  fetchAvailableCourses: propTypes.func,
  availableCourses: propTypes.object,
  changeCourseStatus: propTypes.func,
};

const mapStateToProps = (state) => {
  return {
    availableCourses: state.availableCourseReducer.course,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAvailableCourses: () => dispatch(fetchAvailableCourses(data)),
    changeCourseStatus: () => dispatch(changeCourseStatus(statusData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseListing)
