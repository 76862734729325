import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import propTypes from "prop-types";
import { createMentorship } from "../../redux/mentorship/mentorship-action";
import { connect } from "react-redux";
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';

let initializeMentorship = {
  name: "",
  description: "",
  backgroundColor: "",
  vimeoId: "",
  actualPrice: "",
  offerPrice: "",
  duration: "",
};
let initializeMentorshipErrors = {
  name: "",
  description: "",
  backgroundColor: "",
  vimeoId: "",
  actualPrice: "",
  offerPrice: "",
  duration: "",
};
let mentorshipId = "";
const CreateMentorship = ({
  open,
  onClose,
  createMentorship,
  edit,
  data
}) => {
  const [mentorshipForm, setForm] = useState(initializeMentorship);
  const [mentorshipErrors, setError] = useState(initializeMentorshipErrors);
  useEffect(() => {
    reset();
    if (edit) {
      mentorshipId = data._id;
      setForm({
        name: data.name,
        vimeoId: data.vimeoId,
        description: data.description,
        actualPrice: data.actualPrice,
        offerPrice: data.offerPrice,
        backgroundColor: data.backgroundColor,
        duration: data.duration
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // For reseting form error values.
  const reset = () => {
    initializeMentorshipErrors = {
      name: "",
      description: "",
      backgroundColor: "",
      vimeoId: "",
      actualPrice: "",
      offerPrice: "",
      duration: "",
    };
    setError(initializeMentorshipErrors);
    initializeMentorship = {
      name: "",
      description: "",
      backgroundColor: "",
      vimeoId: "",
      actualPrice: "",
      offerPrice: "",
      duration: "",
    };
    setForm(initializeMentorship);
  };
  const regString = /^[a-zA-Z][a-zA-Z ]+$/
  const regNumber = /^[0-9]+$/
  // Handle when user inputs are received.
  const handleFormInputChange = (event) => {
    event.persist();
    const { name, value } = event.target;
    setForm((mentorshipForm) => ({
      ...mentorshipForm,
      [name]: value,
    }));
    if (name == "name") {
      if(!value.length){
        setError({
          ...mentorshipErrors,
          name: "Mentorship name should not be empty",
        })
      } else {
        setError({
          ...mentorshipErrors,
          name: "",
        })
      }
    }
    if (name == "description") {
      if(!value.length){
        setError({
          ...mentorshipErrors,
          description: "Description should not be empty",
        })
      } else {
        setError({
          ...mentorshipErrors,
          description: "",
        })
      }
    }
    if (name == "backgroundColor") {
      if(!value.length || !regString.test(value)){
        setError({
          ...mentorshipErrors,
          backgroundColor: "Invalid background color",
        })
      } else {
        setError({
          ...mentorshipErrors,
          backgroundColor: "",
        })
      }
    }
    if (name == "vimeoId") {
      if(!value.length){
        setError({
          ...mentorshipErrors,
          vimeoId: "VimeoId should not be empty",
        })
      } else {
        setError({
          ...mentorshipErrors,
          vimeoId: "",
        })
      }
    }
    if (name == "actualPrice" ) {
      if(!value.length || parseInt(value)<=0 || !regNumber.test(value)){
        setError({
          ...mentorshipErrors,
          actualPrice: "Invalid actual price",
        })
      } else {
        setError({
          ...mentorshipErrors,
          actualPrice: "",
        })
      }
    }
    if (name == "offerPrice" ) {
      if(!value.length || parseInt(value)<=0 || !regNumber.test(value)){
        setError({
          ...mentorshipErrors,
          offerPrice: "Invalid offer price",
        })
      } else if (value >= parseInt(mentorshipForm["actualPrice"])) {
        setError({
          ...mentorshipErrors,
          offerPrice: "The offer price should be less than actual price"
        })
      } else {
        setError({
          ...mentorshipErrors,
          offerPrice: "",
        })
      }
    }
    if (name == "duration") {
      if(!value.length || parseInt(value)<=0 || value.length>5 ||
        !regNumber.test(value)){
        setError({
          ...mentorshipErrors,
          duration: "Invalid duration",
        })
      } else {
        setError({
          ...mentorshipErrors,
          duration: "",
        })
      }
    }
  };
  // Create and edit mentorship api handling.
  async function saveMentorship() {
    if (formValidator()) {
      let data = {
        name: mentorshipForm.name,
        vimeoId: mentorshipForm.vimeoId.toString(),
        description: mentorshipForm.description,
        duration: mentorshipForm.duration,
        backgroundColor: mentorshipForm.backgroundColor,
        actualPrice: mentorshipForm.actualPrice,
        offerPrice: mentorshipForm.offerPrice,
      }
      if (edit) {
        data._id = mentorshipId;
        createMentorship(data);
      } else {
        createMentorship(data);
      }
      onClose();
    }
  }

  const handleChange = (value)=>{
    setForm((mentorshipForm) => ({
      ...mentorshipForm,
      'description': value,
    }));
    if(value === "" || value === null){
      setError((errors) => ({
        ...errors,
        'description': "Description should not be empty",
      }));
    }else{
      setError((errors) => ({
        ...errors,
        'description': "",
      }));
    }
  }

  const formValidator = () => {
    for (let key in mentorshipForm) {
      if(!mentorshipForm[key]) {
        if (key === 'name') {
          setError({
            ...mentorshipErrors,
            [key]: 'Name should not be empty'
          })
          return false;
        } if (key === 'description' && mentorshipForm['description']=== "") {
          setError({
            ...mentorshipErrors,
            [key]: 'Description should not be empty'
          })
          return false;
        } if (key === 'backgroundColor' && mentorshipForm['backgroundColor']=== "") {
          setError({
            ...mentorshipErrors,
            [key]: 'Background color should not be empty'
          })
          return false;
        } if (key === 'vimeoId' && mentorshipForm['vimeoId']=== "") {
          setError({
            ...mentorshipErrors,
            [key]: 'Vimeo id should not be empty'
          })
          return false;
        } if (key === 'actualPrice' && mentorshipForm['actualPrice']=== "") {
          setError({
            ...mentorshipErrors,
            [key]: 'Actual price should not be empty'
          })
          return false;
        } if (key === 'OfferPrice' && mentorshipForm['offerPrice']=== "") {
          setError({
            ...mentorshipErrors,
            [key]: 'Offer price should not be empty'
          })
          return false;
        } if (key === 'duration' && mentorshipForm['duration']=== "") {
          setError({
            ...mentorshipErrors,
            [key]: 'Duration should not be empty'
          })
          return false;
        }
      }
    }
    return true;
  }
  return (
    <Modal open={open} className="modal">
      <div style={{
        maxHeight: "95%",
        overflow: "auto",
        overflowX: "hidden",
        scrollbarWidth: "thin",
        scrollBehavior: "smooth"
      }} className="paper">
        <label className="form-group has-float-label mb-4">
          <input
            className="form-control"
            name="name"
            onChange={(e) => handleFormInputChange(e)}
            value={mentorshipForm.name}
          />
          <span className="error">{mentorshipErrors.name}</span>
          <span>Name</span>
        </label>
        <label className="form-group has-float-label mb-4">
        <ReactQuill value={mentorshipForm.description} name="description" 
                        onChange={handleChange} />
          <span className="error">{mentorshipErrors.description}</span>
          <span>Description</span>
        </label>
        <label className="form-group has-float-label mb-4">
          <select
            data-width="100%"
            className="form-control select2-single"
            value={mentorshipForm.backgroundColor}
            onChange={(e) => handleFormInputChange(e)}
            name="backgroundColor"
          >
            <option disabled value="">
              Select background color
            </option>
            <option value="green">Green</option>
            <option value="orange">Orange</option>
            <option value="blue">Blue</option>
          </select>
          <span className="error">{mentorshipErrors.backgroundColor}</span>
          <span>Background Color</span>
        </label>
        <label className="form-group has-float-label mb-4">
          <input
            className="form-control"
            name="vimeoId"
            value={mentorshipForm.vimeoId}
            onChange={(e) => handleFormInputChange(e)}
          />
          <span className="error">{mentorshipErrors.vimeoId}</span>
          <span>Vimeo Id</span>
        </label>
        <label className="form-group has-float-label mb-4">
          <input
            className="form-control"
            name="actualPrice"
            value={mentorshipForm.actualPrice}
            onChange={(e) => handleFormInputChange(e)}
          />
          <span className="error">{mentorshipErrors.actualPrice}</span>
          <span>Actual Price</span>
        </label>
        <label className="form-group has-float-label mb-4">
          <input
            className="form-control"
            name="offerPrice"
            value={mentorshipForm.offerPrice}
            onChange={(e) => handleFormInputChange(e)}
          />
          <span className="error">{mentorshipErrors.offerPrice}</span>
          <span>Offer Price</span>
        </label>
        <label className="form-group has-float-label mb-4">
          <input
            className="form-control"
            name="duration"
            disabled={edit ? true : false}
            value={mentorshipForm.duration}
            onChange={(e) => handleFormInputChange(e)}
          />
          <span className="error">{mentorshipErrors.duration}</span>
          <span>Duration - (Number of Days)</span>
        </label>
        <div className="align-items-right text-right mt-4">
          <button
            type="button"
            onClick={onClose}
            className="btn btn-danger btn-lg btn-shadow mr-3"
          >
            CANCEL
          </button>
          <button
            type="button"
            onClick={saveMentorship}
            className="btn btn-success btn-lg btn-shadow"
          >
            SAVE
          </button>
        </div>
      </div>
    </Modal >
  );
};

CreateMentorship.propTypes = {
  open: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  createMentorship: propTypes.func,
  edit: propTypes.bool,
  data: propTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return {
    createMentorship: (data) => dispatch(createMentorship(data)),
  };
};


export default connect(null, mapDispatchToProps)(CreateMentorship);
