import axios from 'axios';
import {
  FETCH_QUESTION_FAQ_REQUEST,
  FETCH_QUESTION_FAQ_SUCCESS,
  FETCH_QUESTION_FAQ_FAILURE,
  CREATE_EDIT_FAQ_REQUEST,
  CREATE_EDIT_FAQ_SUCCESS,
  CREATE_EDIT_FAQ_FAILURE,
  CREATE_EDIT_FAQ_VIDEO_REQUEST,
  CREATE_EDIT_FAQ_VIDEO_SUCCESS,
  CREATE_EDIT_FAQ_VIDEO_FAILURE
} from "./faq-types";
import config from '../../config';
import { toast } from "react-toastify";

export const fetchFaq=(data)=>{
  return(dispatch)=>{
  axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem("jwtToken")}`;
    dispatch(fetchFaqRequest());
    axios
      .get(config.API_BASE_URL + '/faq/list-faq-questions', {
        params: {
          skip: data.skip,
          limit: data.skip,
          keyword: data.keyword,
        }
      })
      .then(response => {
        const result = response.data;
        dispatch(fetchFaqSuccess(result));
      })
      .catch(error => {
        toast.error("Failed to fetch faqs. Something went wrong");
        dispatch(fetchFaqFailure(error.message));
      });
  }
}

export const fetchFaqRequest=()=>{
  return {
  type: FETCH_QUESTION_FAQ_REQUEST,
  };
}

export const fetchFaqSuccess=(data)=>{
  return {
    type: FETCH_QUESTION_FAQ_SUCCESS,
    payload: data,
  };
}

export const fetchFaqFailure=(error)=>{
  return{
    type: FETCH_QUESTION_FAQ_FAILURE,
    payload: error,
  };
};

export const createEditFAQ = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(createEditRequest());
    axios
      .patch(config.API_BASE_URL + "/faq/create-edit-faq", data)
      .then((response) => {
        const result = response.data;
        if (result.success) {
          if (data.questionId) {
            toast.success("Faq updated successfully.");
          } else {
            toast.success("Faq created successfully.");
          }
          dispatch(createEditFAQSuccess(result));
        }
      })
      .catch((error) => {
        if (data.questionId) {
          toast.error("Failed to update Faq. Something went wrong.");
        } else {
          toast.error("Failed to create Faq. Something went wrong.");
        }
        dispatch(createEditFAQFailure(error.message));
      });
  };
};

export const createEditRequest = () => {
  return {
    type: CREATE_EDIT_FAQ_REQUEST,
  };
};
export const createEditFAQSuccess = (data) => {
  return {
    type: CREATE_EDIT_FAQ_SUCCESS,
    payload: data,
  };
};

export const createEditFAQFailure = (error) => {
  return {
    type: CREATE_EDIT_FAQ_FAILURE,
    payload: error,
  };
};

export const createEditFaqVideo = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(createEditVideoRequest());
    axios
      .post(config.API_BASE_URL + "/faq/link-video", data)
      .then((response) => {
        const result = response.data;
        if (result.success) {
          if (data._id) {
            toast.success("Faq video updated successfully.");
          } else {
            toast.success("Faq video created successfully.");
          }
          dispatch(createEditVideoSuccess(result));
        }
      })
      .catch((error) => {
        if (data._id) {
          toast.error("Failed to update Faq video. Something went wrong.");
        } else {
          toast.error("Failed to create Faq vidoe. Something went wrong.");
        }
        dispatch(createEditVideoFailure(error.message));
      });
  };
};

export const createEditVideoRequest = () => {
  return {
    type: CREATE_EDIT_FAQ_VIDEO_REQUEST,
  };
};
export const createEditVideoSuccess = (data) => {
  return {
    type: CREATE_EDIT_FAQ_VIDEO_SUCCESS,
    payload: data,
  };
};

export const createEditVideoFailure = (error) => {
  return {
    type: CREATE_EDIT_FAQ_VIDEO_FAILURE,
    payload: error,
  };
};
