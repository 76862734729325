import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import propTypes from "prop-types";
import Modal from "@material-ui/core/Modal";
import "../../pages/Question/approved-question.css";
import { editQuestion } from '../../redux/questions/questions-action'

const initQuestion = {
  _id: "",
  question: "",
  options: [],
  correctAnswer: "",
  subjectName: "",
  topicName: "",
};
let initErrors = { question: "", options: "" };
const QuestionPopup = (props) => {
  const [form, setForm] = useState(initQuestion);
  const [errors, setErrors] = useState(initErrors);
  const reset = () => {
    initErrors = { question: "", options: "" };
    setErrors(initErrors);
  };
  const dispatch = useDispatch()

  const handleSave = (e) => {
    e.preventDefault();
    if (validateQuestionForm()) {
      setForm(initQuestion);
      ['subjectName','topicName'].map(input => delete form[input])
      dispatch(editQuestion(form))
      props.onClose();
    }
  };

  const handleFormInputChange = (e, optionIndex = 0) => {
    e.persist();
    const { name, value } = e.target;
    let updatedOptions = [];
    if (optionIndex !== 0) {
      updatedOptions = form.options.map((opt) => {
        if (opt.optionIndex === optionIndex) {
          opt.option = value;
        }
        return opt;
      });
    }
    setForm((form) => ({
      ...form,
      [name]: optionIndex !== 0 ? updatedOptions : value,
    }));
    if (value !== "") {
      setErrors((errors) => ({ ...errors, [name]: "" }));
    }
  };

  const validateQuestionForm = () => {
    let flag = true;
    const { question, options } = form;
    if (question === "" || !question) {
      flag = false;
      initErrors.question = "Please enter a valid question";
    }
    const invalidOption = options
      .map(({ option }) => option)
      .some((option) => option === "");
    if (invalidOption) {
      flag = false;
      initErrors.options = "Please enter a valid option";
    }
    setErrors((errors) => ({ ...errors, ...initErrors }));
    return flag;
  };

  useEffect(() => {
    const {
      questionData: {
        questionId: _id,
        question,
        options,
        correctAnswer: { optionIndex: correctAnswer },
        subjectName,
        topicName,
      },
    } = props;
    setForm({ _id, question, options, correctAnswer, subjectName, topicName });
    reset();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal open={props.open} className="modal">
      <div className="paper">
        <h2 className="mb-4">Edit Question</h2>
        <label className="form-group has-float-label mb-4"></label>
        <label className="form-group has-float-label">
          <textarea
            className="form-control"
            name="question"
            value={form.question}
            onChange={(e) => handleFormInputChange(e)}
            style={{ minHeight: "150px" }}
          />
          <span className="error">{errors.question}</span>
          <span>Question</span>
        </label>
        <label htmlFor="">Options:</label>
        <label className="form-group has-float-label mb-4">
          {form.options.map(({ optionIndex, option }) => (
            <div className="optionsContainer" key={optionIndex}>
              <input
                type="text"
                disabled
                value={optionIndex}
                className="form-control"
              />
              <input
                type="text"
                className="form-control"
                name="options"
                value={option}
                key={optionIndex}
                onChange={(e) => handleFormInputChange(e, optionIndex)}
              ></input>
            </div>
          ))}
          <p className="error">{errors.options}</p>
        </label>
        <label htmlFor="correctAnswer">Correct Answer:</label>
        <label className="form-group has-float-label mb-4">
          <select
            data-width="100%"
            className="form-control select2-single"
            value={form.correctAnswer}
            onChange={(e) => handleFormInputChange(e)}
            name="correctAnswer"
          >
            <option disabled>Select Correct Option</option>
            {form.options.map(({ optionIndex }) => (
              <option key={optionIndex}>{optionIndex}</option>
            ))}
          </select>
        </label>
        <label className="form-group has-float-label mb-4">
          <input
            type="text"
            className="form-control"
            name="subjectName"
            value={form.subjectName}
            disabled
          />
          <span>Subject</span>
        </label>
        <label className="form-group has-float-label mb-4">
          <input
            type="text"
            className="form-control"
            name="topicName"
            value={form.topicName}
            disabled
          />
          <span>Topic</span>
        </label>
        <div className="align-items-right text-right mt-4">
          <button
            type="button"
            onClick={props.onClose}
            className="btn btn-danger btn-lg btn-shadow mr-3"
          >
            CANCEL
          </button>
          <button
            type="button"
            onClick={handleSave}
            className="btn btn-success btn-lg btn-shadow"
          >
            SAVE
          </button>
        </div>
      </div>
    </Modal>
  );
};

QuestionPopup.propTypes = {
  open: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  questionData: propTypes.object.isRequired,
};
export default QuestionPopup;
