import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import Grid from "@material-ui/core/Grid";
import { PrelimTests } from "../../redux/exam/exam-action";
import PrelimListTable from "../../components/Tables/prelim-list-table";
import "./list-prelims.css";
import { getPermission } from "../../util/auth";
let data = {
  skip: 0,
  limit: 10,
  keyword: "",
  status: "all",
};
const ListPrelimsExams = () => {
  const [page, setPage] = useState(1);
  const [rowCount, setRowCount] = useState(10);
  const dispatch = useDispatch();
  const [status, setStatus] = React.useState("all");
  const statusChanged = useSelector((state) => state.prelimTestStatusReducer);
  const syllabusUpload = useSelector(state => state.uploadPrelimTestSyllabusReducer)
  useEffect(() => {
    dispatch(PrelimTests(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusChanged,syllabusUpload]);
  const resultData = useSelector((state) => state.fetchprelimTestReducer);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    data.skip = (newPage - 1) * rowCount;
    dispatch(PrelimTests(data));
  };
  const handleRowsPerPage = (e) => {
    setRowCount(e.target.value);
    setPage(1);
    data.limit = e.target.value;
    data.skip = 0;
    dispatch(PrelimTests(data));
  };
  const handleKeyUp = (e) => {
    data.skip = 0;
    data.keyword = e.target.value;
    setPage(1);
    dispatch(PrelimTests(data));
  };
  const handleChange = (event) => {
    event.persist();
    if (event.target.value === "") {
      return;
    }
    setStatus(event.target.value);
    data.skip = 0;
    data.status = event.target.value;
    setPage(1);
    dispatch(PrelimTests(data));
  };
  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Listing Prelims</h1>
            <nav
              className="breadcrumb-container d-none d-sm-block d-lg-inline-block"
              aria-label="breadcrumb"
            >
              <ol className="breadcrumb pt-0">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
              </ol>
            </nav>
            <div className="separator mb-5"></div>
            <div className="tableBody">
              <div className="table-filter-div">
                <Grid item xs={6}>
                  <div className="search-div">
                    <input
                      type="text"
                      className="search"
                      onKeyUp={handleKeyUp}
                      placeholder="Search Prelim Tests.."
                    />
                    <div className="filter-div">
                      <select
                        value={status}
                        className="form-control dropdown select2-single"
                        onChange={handleChange}
                      >
                        <option value="all">All</option>
                        <option value="draft">Draft</option>
                        <option value="published">Published</option>
                      </select>
                    </div>
                  </div>
                  {/* <div className="filter-div">
                  </div> */}
                </Grid>
                <Grid item xs={6}>
                  <div className="rowCount-div">
                    <label>Prelim Tests per page : </label>
                    <select className="selection" onChange={handleRowsPerPage}>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </Grid>
              </div>
              <PrelimListTable
                data={resultData.data.prelimTest || []}
                permission={getPermission().prelim}
              />
              <div className="paginationLabel">
                Showing {(page - 1) * rowCount + 1} to{" "}
                {(page - 1) * rowCount +
                  (resultData && resultData.data.totalQuestions
                    ? resultData.data.questions.length
                    : 0)}{" "}
                of{" "}
                {resultData.data.totalPrelims
                  ? resultData.data.totalPrelims
                  : 0}{" "}
                entries
              </div>
              <div className="root">
                <Pagination
                  count={
                    resultData && resultData.data.totalPrelims
                      ? Math.ceil(resultData.data.totalPrelims / rowCount)
                      : 0
                  }
                  page={page}
                  onChange={handleChangePage}
                  variant="outlined"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default withRouter(ListPrelimsExams);
