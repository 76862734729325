import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { convertBase64 } from '../../util/helper';
import { createRecommendedVideo } from '../../redux/video/video-action';

let videoData = {
    vimeoId: '',
    image: ''
}

let initialError = {
    vimeoId: '',
    image: ''
}

const CreateRecommendedVideo = ({ onClose, open, createRecommendedVideo }) => {
    const [image, setImage] = useState({ file: '' });
    const [vimeo, setVimeoId] = useState({ vimeoId: '' });
    const [errors, setErrors] = useState(initialError);

    const handleImageChange = async (e) => {
        setImage({ file: await convertBase64(e.target.files[0]) });
        setErrors((errors) => ({
            ...errors,
            ['image']: ""
        }));
    }

    const handleFormInput = (event) => {
        setVimeoId({ vimeoId: event.target.value });
        setErrors((errors) => ({
            ...errors,
            ['vimeoId']: ""
        }));
    }

    const createVideo = () => {
        if (validateForm()) {
            videoData.vimeoId = vimeo.vimeoId;
            videoData.image = image.file;
            createRecommendedVideo(videoData);
            onClose();
        }
    }

    const validateForm = () => {
        let isValid = true;
        if (vimeo.vimeoId === '') {
            setErrors((errors) => ({
                ...errors,
                ['vimeoId']: "Vimeo Id should not be empty!"
            }));
            isValid = false;
        }
        if (image.file === '') {
            setErrors((errors) => ({
                ...errors,
                ['image']: "Upload image"
            }));
            isValid = false;
        }
        return isValid;
    }

    return (
        <Modal open={open} className="modal">
            <div className="paper test-modal">
                <div className="mb-5">
                    <h3>Recommended Video</h3>
                </div>

                <label className="form-group has-float-label mb-4">
                    <input onChange={handleFormInput} value={vimeo.vimeoId} name="vimeoId"
                        placeholder="Enter vimeo Id here..." className="form-control" />
                    <span className="error">{errors.vimeoId}</span>
                    <span>Vimeo Id</span>
                </label>

                <label className="form-group has-float-label mb-4">
                    <input
                        type="file"
                        className="form-control"
                        name="image"
                        onChange={(e) => handleImageChange(e)}
                        accept="image/png image/jpeg image/jpg" />
                    <span className="error">{errors.image}</span>
                    <span>Upload image</span>
                </label>

                <div className="align-items-right text-right mt-4">
                    <button
                        type="button"
                        onClick={onClose}
                        className="btn btn-danger btn-lg btn-shadow mr-3">
                        CANCEL
                    </button>
                    <button
                        type="button"
                        onClick={createVideo}
                        className="btn btn-success btn-lg btn-shadow">
                        SAVE
                    </button>
                </div>

            </div>
        </Modal>
    );
}

CreateRecommendedVideo.propTypes = {
    onClose: propTypes.func.isRequired,
    open: propTypes.bool.isRequired,
    createRecommendedVideo: propTypes.func
};

const mapDispatchToProps = (dispatch) => {
    return {
        createRecommendedVideo: () => dispatch(createRecommendedVideo(videoData))
    };
};

export default connect(null, mapDispatchToProps)(CreateRecommendedVideo);