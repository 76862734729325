import axios from "axios";
import config from "../../config";
import { toast } from "react-toastify";
import {
  FETCH_PRELIM_REQUEST,
  FETCH_PRELIM_SUCCESS,
  FETCH_PRELIM_FAILURE,
  UPDATE_PRELIM_STATUS_FAILURE,
  UPDATE_PRELIM_STATUS_REQUEST,
  UPDATE_PRELIM_STATUS_SUCCESS,
  CREATE_PRELIM_FAILURE,
  CREATE_PRELIM_SUCCESS,
  CREATE_PRELIM_REQUEST,
  EDIT_PRELIM_FAILURE,
  EDIT_PRELIM_REQUEST,
  EDIT_PRELIM_SUCCESS,
  FETCH_PRELIM_TEST_REQUEST,
  FETCH_PRELIM_TEST_FAILURE,
  FETCH_PRELIM_TEST_SUCCESS,
  ADD_PRELIM_TEST_REQUEST,
  ADD_PRELIM_TEST_SUCCESS,
  ADD_PRELIM_TEST_FAILURE,
  FETCH_PURCHASED_USERS_REQUEST,
  FETCH_PURCHASED_USERS_SUCCESS,
  FETCH_PURCHASED_USERS_FAILURE,
} from "./prelim-types";

//Fetch prelims.
export const fetchPrelim = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(fetchPrelimRequest());
    axios
      .get(config.API_BASE_URL + "/prelim/list-prelim", {
        params: {
          skip: data.skip,
          limit: data.limit,
          keyword: data.keyword,
          status: data.status,
        },
      })
      .then((response) => {
        const result = response.data;
        dispatch(fetchPrelimSuccess(result));
      })
      .catch((error) => {
        dispatch(fetchPrelimFailure(error.message));
      });
  };
};

export const fetchPrelimRequest = () => {
  return {
    type: FETCH_PRELIM_REQUEST,
  };
};

export const fetchPrelimSuccess = (result) => {
  return {
    type: FETCH_PRELIM_SUCCESS,
    payload: result,
  };
};

export const fetchPrelimFailure = (error) => {
  return {
    type: FETCH_PRELIM_FAILURE,
    payload: error,
  };
};

//Add prelim test to prelim.
export const addPrelimTest = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(addPrelimTestRequest());
    axios
      .post(config.API_BASE_URL + "/prelim/add-prelim-test", data)
      .then((response) => {
        const prelim = response.data;
        if (prelim.success === true) {
          toast.success("Successfuly updated the prelim.");
          dispatch(addPrelimTestSuccess(prelim));
        } else {
          toast.error("Failed to update prelim. Something went wrong");
          dispatch(addPrelimTestFailure(prelim.error));
        }
      })
      .catch((error) => {
        dispatch(addPrelimTestFailure(error.message));
      });
  };
};

export const addPrelimTestRequest = () => {
  return {
    type: ADD_PRELIM_TEST_REQUEST,
  };
};

export const addPrelimTestSuccess = (result) => {
  return {
    type: ADD_PRELIM_TEST_SUCCESS,
    payload: result,
  };
};

export const addPrelimTestFailure = (error) => {
  return {
    type: ADD_PRELIM_TEST_FAILURE,
    payload: error,
  };
};

// Update status.
export const updatePrelimStatus = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(updatePrelimStatusRequest());
    axios
      .patch(config.API_BASE_URL + "/prelim/edit-prelim", data)
      .then((response) => {
        if (response.data.success === true) {
          toast.success("Successfuly updated the prelim.");
          dispatch(updatePrelimStatusSuccess(response.data));
        } else {
          toast.error("Failed to update prelim. Something went wrong");
          dispatch(updatePrelimStatusFailure(response.data));
        }
      })
      .catch((error) => {
        toast.error(error.message);
        dispatch(updatePrelimStatusFailure(error.message));
      });
  };
};

export const updatePrelimStatusRequest = () => {
  return {
    type: UPDATE_PRELIM_STATUS_REQUEST,
  };
};

export const updatePrelimStatusSuccess = (result) => {
  return {
    type: UPDATE_PRELIM_STATUS_SUCCESS,
    payload: result,
  };
};

export const updatePrelimStatusFailure = (error) => {
  return {
    type: UPDATE_PRELIM_STATUS_FAILURE,
    payload: error,
  };
};

// Create prelim.
export const createPrelim = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(createPrelimRequest());
    axios
      .post(config.API_BASE_URL + "/prelim/create", data)
      .then((response) => {
        const prelim = response.data;
        if (prelim.success === true) {
          toast.success("Successfuly created the prelim.");
          dispatch(createPrelimSuccess(prelim));
        } else {
          toast.error("Failed to create prelim. Something went wrong");
          dispatch(createPrelimFailure(prelim.error));
        }
      })
      .catch((error) => {
        if (error.status === 500) {
          toast.error("Failed to create prelim. Something went wrong");
        } else {
          toast.error(error.response.data.message);
        }
        dispatch(createPrelimFailure(error.message));
      });
  };
};

export const createPrelimRequest = () => {
  return {
    type: CREATE_PRELIM_REQUEST,
  };
};

export const createPrelimSuccess = (result) => {
  return {
    type: CREATE_PRELIM_SUCCESS,
    payload: result,
  };
};

export const createPrelimFailure = (error) => {
  return {
    type: CREATE_PRELIM_FAILURE,
    payload: error,
  };
};

// Edit prelim.
export const editPrelim = (data) => {
  // data and prelimId
  return (dispatch) => {
    dispatch(editPrelimRequest());
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    axios
      .patch(config.API_BASE_URL + "/prelim/edit-prelim", data)
      .then((response) => {
        if (response.data.success) {
          toast.success("Successfuly updated the prelim.");
          dispatch(editPrelimSuccess(response.data));
        } else {
          toast.error("Failed to update prelim. Something went wrong");
          dispatch(editPrelimFailure(response.data));
        }
      })
      .catch((error) => {
        if (error.status === 500) {
          toast.error("Failed to update prelim. Something went wrong");
        } else {
          toast.error(error.response.data.message);
        }
        dispatch(editPrelimFailure(error.message));
      });
  };
};

export const editPrelimRequest = () => {
  return {
    type: EDIT_PRELIM_REQUEST,
  };
};

export const editPrelimSuccess = (result) => {
  return {
    type: EDIT_PRELIM_SUCCESS,
    payload: result,
  };
};

export const editPrelimFailure = (error) => {
  return {
    type: EDIT_PRELIM_FAILURE,
    payload: error,
  };
};

// Get all prelim test.
export const getPrelimTest = () => {
  return (dispatch) => {
    dispatch(getPrelimTestRequest());
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    axios
      .get(config.API_BASE_URL + "/prelim/get-all-prelim-test")
      .then((response) => {
        const prelim = response.data;
        dispatch(getPrelimTestSuccess(prelim));
      })
      .catch((error) => {
        dispatch(getPrelimTestFailure(error.message));
      });
  };
};

export const getPrelimTestRequest = () => {
  return {
    type: FETCH_PRELIM_TEST_REQUEST,
  };
};

export const getPrelimTestSuccess = (result) => {
  return {
    type: FETCH_PRELIM_TEST_SUCCESS,
    payload: result,
  };
};

export const fetchPrelimUserPurchased = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(fetchUserPurchasedRequest());
    axios
      .get(config.API_BASE_URL + "/prelim/purchased-users", {
        params: {
          skip: data.skip,
          limit: data.limit,
          keyword: data.keyword,
        },
      })
      .then((response) => {
        const prelims = response.data;
        dispatch(fetchUserPurchasedSuccess(prelims));
      })
      .catch((error) => {
        toast.error("Failed to get mentorships. Something went wrong.");
        dispatch(fetchUserPurchasedFailure(error.message));
      });
  };
};

export const getPrelimTestFailure = (error) => {
  return {
    type: FETCH_PRELIM_TEST_FAILURE,
    payload: error,
  };
};

export const fetchUserPurchasedRequest = () => {
  return {
    type: FETCH_PURCHASED_USERS_REQUEST,
  };
};
export const fetchUserPurchasedSuccess = (data) => {
  return {
    type: FETCH_PURCHASED_USERS_SUCCESS,
    payload: data,
  };
};
export const fetchUserPurchasedFailure = (error) => {
  return {
    type: FETCH_PURCHASED_USERS_FAILURE,
    payload: error,
  };
};
