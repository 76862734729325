const initialState = {
  loading: true,
  data: {},
  error: "",
};

export const fetchTopicReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_TOPICS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_TOPICS_SUCCESS":
      return {
        loading: false,
        data: action.payload,
        error: "",
      };
     case "FETCH_TOPICS_FAILURE":
      return {
        loading: false,
        data: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export const createTopicReducer=(state = initialState,action)=>{
  switch (action.type) {
    case "CREATE_TOPIC_REQUEST":
      return{
        ...state,
        loading:true,
      }
    case "CREATE_TOPIC_SUCCESS":
      return{
        loading:false,
        data:action.payload,
        error:""
      }
    case "CREATE_TOPIC_FAILURE":
      return{
        loading:false,
        data:{},
        error:action.payload
      }
    default:
      return state
  }
}
