import axios from 'axios'
import config from '../../config';
import { login } from '../../util/auth';
import { toast } from 'react-toastify';
const {
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGIN_REQUEST
} = require('./login-types');
const userData = require('../../assets/mock-response/auth-user.json');


export const loginWithGoogle = (token) => {
    return (dispatch) => {
        let data = {tokenId: token}
        dispatch(loginRequest());
        axios
            .post(config.API_BASE_URL + '/authentication/google-login', data)
            .then(response => {
                const user = userData;
                if (user.token) {
                    login(response.data.token, user.permission);
                }
                dispatch(loginSuccess(true));
            })
            .catch(error => {
                toast.error('Login Failed. Something went wrong');
                dispatch(loginFailure(error))
            })
    };
};

export const loginRequest = () => {
    return {
        type: LOGIN_REQUEST
    };
};

export const loginSuccess = isLogin => {
    return {
        type: LOGIN_SUCCESS,
        payload: isLogin
    };
};

export const loginFailure = error => {
    return {
        type: LOGIN_FAILURE,
        payload: error
    };
};