const initialState = {
  loading: true,
  alerts: {},
  error: "",
};

const availableAlertsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_ALERT_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_ALERT_SUCCESS":
      return {
        loading: false,
        alerts: action.payload,
        error: "",
      };
    case "FETCH_ALERT_FAILURE":
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const fetchMainsPurchasedUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_PURCHASED_USERS_REQUEST":
      return {
        loading: true,
      };
    case "FETCH_PURCHASED_USERS_SUCCESS":
      return {
        ...state,
        loading: false,
        mains: action.payload,
      };
    case "FETCH_PURCHASED_USERS_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export { availableAlertsReducer,fetchMainsPurchasedUserReducer }