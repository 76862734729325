const sidemenudata = {
  links: [
    {
      id: "1",
      name: "Questions",
      to: "question",
      className: "iconsminds-shop-4",
      menuName: "subQuestion",
    },
    {
      id: "2",
      name: "Exams",
      to: "exam",
      className: "iconsminds-digital-drawing",
      menuName: "subExam",
    },
    {
      id: "3",
      name: "Courses",
      to: "course",
      className: "iconsminds-air-balloon-1",
      menuName: "subCourse",
    },
    {
      id: "4",
      name: "Prelims",
      to: "prelim",
      className: "iconsminds-pantone",
      menuName: "subPrelim",
    },
    {
      id: "5",
      name: "Videos",
      to: "video",
      className: "iconsminds-digital-drawing",
      menuName: "subVideo",
    },
    {
      id: "6",
      name: "Subjects",
      to: "subject",
      className: "iconsminds-air-balloon-1",
      menuName: "subSubject",
    },
    {
      id: "7",
      name: "Mains",
      to: "mains",
      className: "iconsminds-air-balloon-1",
      menuName: "subMains",
    },
    {
      id: "8",
      name: "Mentorship",
      to: "mentorship",
      className: "iconsminds-air-balloon-1",
      menuName: "subMentorship",
    },
    {
      id: "9",
      name: "FAQ",
      to: "faqs",
      className: "iconsminds-air-balloon-1",
      menuName: "subFaq",
    },
    {
      id: "10",
      name: "Games",
      to: "games",
      className: "iconsminds-digital-drawing",
      menuName: "subGame",
    },
    {
      id: "11",
      name: "Articles",
      to: "articles",
      className: "iconsminds-digital-drawing",
      menuName: "subArticle",
    },
    {
      id: "12",
      name: "Users",
      to: "users",
      className: "iconsminds-shop-4",
      menuName: "subUser",
    },
  ],
  activeLink: "",
  subMenuLink: "",
  subQuestion: [
    {
      parent: "1",
      id: "1",
      name: "Uploaded Questions",
      isDropdown: false,
      icon: "simple-icon-rocket",
      to: "/question/upload",
    },
    {
      parent: "1",
      id: "2",
      name: "Approved Questions",
      isDropdown: false,
      icon: "simple-icon-pie-chart",
      to: "/question/approved",
    },
    {
      parent: "1",
      id: "3",
      name: "Mains Questions",
      isDropdown: false,
      icon: "simple-icon-pie-chart",
      to: "/question/mains",
    },
  ],
  subExam: [
    {
      parent: "2",
      id: "1",
      name: "Create Exam",
      isDropdown: false,
      icon: "simple-icon-rocket",
      to: "/exam/create-exam",
    },
    {
      parent: "2",
      id: "2",
      name: "Daily Test",
      isDropdown: false,
      icon: "simple-icon-pie-chart",
      to: "/exam/daily-test",
    },
    {
      parent: "2",
      id: "4",
      name: "Prelim Test",
      isDropdown: false,
      icon: "simple-icon-pie-chart",
      to: "/exam/prelim-test",
    },
  ],
  subCourse: [
    {
      parent: "3",
      id: "1",
      name: "Create course",
      isDropdown: false,
      icon: "simple-icon-rocket",
      to: "/course/list",
    },
    {
      parent: "3",
      id: "2",
      name: " Create course plan",
      isDropdown: false,
      icon: "simple-icon-pie-chart",
      to: "/course/course-plan",
    },
    {
      parent: "3",
      id: "3",
      name: "Purchased Users",
      isDropdown: false,
      icon: "simple-icon-pie-chart",
      to: "/course/purchased-users",
    },
  ],
  subPrelim: [
    {
      parent: "4",
      id: "1",
      name: "Create Prelims",
      isDropdown: false,
      icon: "simple-icon-pie-chart",
      to: "/prelim/list",
    },
    {
      parent: "4",
      id: "2",
      name: "Purchased Users",
      isDropdown: false,
      icon: "simple-icon-pie-chart",
      to: "/prelim/purchased-users",
    },
  ],
  subVideo: [
    {
      parent: "5",
      id: "1",
      name: "Video List",
      isDropdown: false,
      icon: "simple-icon-rocket",
      to: "/video/list",
    },
    {
      parent: "5",
      id: "2",
      name: "Recommended Videos",
      isDropdown: false,
      icon: "simple-icon-rocket",
      to: "/video/recommended",
    },
  ],
  subSubject: [
    {
      parent: "6",
      id: "1",
      name: "Create Subjects",
      isDropdown: false,
      icon: "simple-icon-rocket",
      to: "/subject/list",
    },
    {
      parent: "6",
      id: "2",
      name: "Create Topic",
      isDropdown: false,
      icon: "simple-icon-rocket",
      to: "/subject/create-topic",
    },
  ],
  subMains: [
    {
      parent: "7",
      id: "1",
      name: "Alerts",
      isDropdown: false,
      icon: "simple-icon-rocket",
      to: "/mains/alerts",
    },
    {
      parent: "7",
      id: "2",
      name: "Purchased Users",
      isDropdown: false,
      icon: "simple-icon-pie-chart",
      to: "/mains/purchased-users",
    },
  ],
  subMentorship: [
    {
      parent: "8",
      id: "1",
      name: "Create Mentorship",
      isDropdown: false,
      icon: "simple-icon-rocket",
      to: "/mentorship/list",
    },
    {
      parent: "8",
      id: "2",
      name: "Purchased Users",
      isDropdown: false,
      icon: "simple-icon-pie-chart",
      to: "/mentorship/purchased-users",
    },
  ],
  subFaq: [
    {
      parent: "9",
      id: "1",
      name: "Faq Questions",
      isDropdown: false,
      icon: "simple-icon-pie-chart",
      to: "/faq/faq-questions",
    },
  ],
  subGame: [
    {
      parent: "10",
      id: "1",
      name: "Create Game",
      isDropdown: false,
      icon: "simple-icon-pie-chart",
      to: "/game/list",
    },
  ],
  subArticle: [
    {
      parent: "11",
      id: "1",
      name: "Create Article",
      isDropdown: false,
      icon: "simple-icon-pie-chart",
      to: "/article/list",
    },
    {
      parent: "11",
      id: "2",
      name: "Upload Article Image",
      isDropdown: false,
      icon: "simple-icon-pie-chart",
      to: "/image/list",
    },
  ],
  subUser: [
    {
      parent: "12",
      id: "1",
      name: "Manage users",
      isDropdown: false,
      icon: "simple-icon-rocket",
      to: "/user/create",
    },
  ],
};
export { sidemenudata };
