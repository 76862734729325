import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { addSampleAnswer } from "../../redux/questions/questions-action";
import "../../pages/Question/approved-question.css";
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';


const AnswerUploadPopup = (props) => {
    const { row } = props;
    const dispatch = useDispatch();
    const [editorState, setEditorState] = useState("");
    const [editorError, setEditorError] = useState("");
    useEffect(() => {
        if(row.sampleAnswer) {
           setEditorState(row.sampleAnswer) 
        }
      }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const useStyles = makeStyles((theme) => ({
        root: {
            height: 300,
            flexGrow: 1,
            minWidth: 300,
            transform: "translateZ(0)",
            "@media all and (-ms-high-contrast: none)": {
                display: "none",
            },
        },
        modal: {
            display: "flex",
            alignItems: "center",
            padding: theme.spacing(2),
            justifyContent: "center",
        },
        paper: {
            width: "65%",
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            overflow: "auto",
            minHeight: "70%",
            maxHeight: "90%"
        },
        error: {
            color: "crimson"
        },
        title: {
            margin: "10px",
            fontSize: "20px",
        }
    }));
    const classes = useStyles();
    const submitHandler = (e) => {
        e.preventDefault();
        if (editorState) {
            const args = {
                _id: row._id,
                answer: editorState
            }
            dispatch(addSampleAnswer(args));
            handleClose(false);
        } else {
            setEditorError("Answer is required.")
            // handleClose(false);
        }
    };
    const handleClose = (value) => {
        props.onClose(value);
    };
    const handleChange = (value) => {
        setEditorError("");
        setEditorState(value);
    }
    return (
        <React.Fragment>
            <Modal open={props.open} className={classes.modal}>
                <div className={classes.paper}>
                    <p className={classes.title}><b>Sample Answer</b> - {row.title}</p>
                    <ReactQuill value={editorState}
                        onChange={handleChange} />
                    <p className={classes.error}>{editorError}</p>
                    <div className="align-items-right text-right mt-3">
                        <button
                            className="btn btn-danger mr-2"
                            type="button"
                            onClick={() => handleClose("close")}
                        >
                            Cancel
            </button>
                        <button
                            className="btn btn-success"
                            type="button"
                            onClick={submitHandler}
                        >
                            Submit
            </button>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    );
};
AnswerUploadPopup.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    row: PropTypes.object.isRequired,
};
export default AnswerUploadPopup;