import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import propTypes from "prop-types";
import {
  getAllCourses,
  createCoursePlan,
} from "../../redux/course/course-action";
import { connect } from "react-redux";

let initialCourseFormState = {
  courseId: "",
  courseName: "",
  offerPrice: "",
  actualPrice: "",
  duration: "",
};
let courseData = {};

const CreateCoursePlan = ({
  onClose,
  open,
  isEdit,
  getAllCourses,
  courses,
  createCoursePlan,
  planData,
}) => {
  let initialErrors = {
    courseId: "",
    courseName: "",
    offerPrice: "",
    actualPrice: "",
    duration: "",
  };
  const [courseForm, setForm] = useState(initialCourseFormState);
  const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    if (!isEdit) {
      getAllCourses();
    } else {
      setForm(planData);
    }
  }, [isEdit, getAllCourses, planData]);

  // Handle form input change.
  const handleFormInputChange = (event) => {
    event.persist();
    const { name, value } = event.target;
    if (name === "courseId" && courseForm[name] != "") {
      setErrors((errors) => ({
        ...errors,
        [name]: "",
      }));
    } else {
      if (courseForm[name] < 0 || courseForm[name] === "" && name !== "courseId") {
        setErrors((errors) => ({
          ...errors,
          [name]:
            name === "actualPrice" || name === "offerPrice"
              ? "Price should be greater than zero"
              : "Duration should be greater than zero",
        }));
      } else {
        setErrors((errors) => ({
          ...errors,
          [name]: "",
        }));
      }
    }
    setForm((courseForm) => ({
      ...courseForm,
      [name]: value,
    }));
  };

  // method to create course plan.
  const saveCourse = () => {
    if (validateForm()) {
      courseData = courseForm;
      if (isEdit) {
        courseData.coursePlanId = planData.planId;
        delete courseData["courseId"];
        delete courseData["planId"];
        delete courseData["courseName"];
        delete courseData["duration"];
        createCoursePlan(courseData);
      } else {
        delete courseData["courseName"];
        createCoursePlan(courseData);
      }
      initialCourseFormState.courseId = "";
      initialCourseFormState.actualPrice = "";
      initialCourseFormState.duration = "";
      initialCourseFormState.offerPrice = "";
      onClose();
    }
  };

  // Method to validate course plan from.
  const validateForm = () => {
    let isValid = true;
    let inputs = courseForm;
    if (inputs["courseId"] === "") {
      isValid = false;
      setErrors((errors) => ({
        ...errors,
        ["courseId"]: "Select one course",
      }));
    }
    if (
      parseInt(inputs["actualPrice"]) === 0 || inputs["actualPrice"] === "" ||
      parseInt(inputs["actualPrice"] <= 0)
    ) {
      isValid = false;
      setErrors((errors) => ({
        ...errors,
        ["actualPrice"]: "Actual price should be greater than zero",
      }));
    }
    if (
      parseInt(inputs["offerPrice"]) < 0 || inputs["offerPrice"] === "" ||
      parseInt(inputs["offerPrice"]) > parseInt(inputs["actualPrice"])
    ) {
      isValid = false;
      setErrors((errors) => ({
        ...errors,
        ["offerPrice"]: "Invalid offer price",
      }));
    }
    if (parseInt(inputs["duration"]) <= 0 || inputs["duration"] === "") {
      isValid = false;
      setErrors((errors) => ({
        ...errors,
        ["duration"]: "Duration should be greater than zero",
      }));
    }
    return isValid;
  };

  return (
    <Modal open={open} className="modal">
      <div className="paper">
        <div className="mb-5">
          <h3>COURSE PLAN</h3>
        </div>
        {!isEdit ? (
          <label className="form-group has-float-label mb-4">
            <select
              data-width="100%"
              onChange={(e) => handleFormInputChange(e)}
              className="form-control select2-single"
              name="courseId"
              value={courseForm.courseId}
            >
              <option disabled value="">
                Select course
              </option>
              {courses.map((x, y) => (
                <option value={x._id} key={y}>
                  {x.name}
                </option>
              ))}
            </select>
            <span className="error">{errors.courseId}</span>
            <span>Courses</span>
          </label>
        ) : (
          <label className="form-group has-float-label mb-4">
            <input
              className="form-control"
              name="courseId"
              value={courseForm.courseName}
              disabled={isEdit}
            />
            <span className="error">{errors.actualPrice}</span>
            <span>Course Name</span>
          </label>
        )}

        <label className="form-group has-float-label mb-4">
          <input
            value={courseForm.actualPrice}
            onChange={(e) => handleFormInputChange(e)}
            className="form-control"
            name="actualPrice"
            type="number"
          />
          <span className="error">{errors.actualPrice}</span>
          <span>Actual Price</span>
        </label>
        <label className="form-group has-float-label mb-4">
          <input
            value={courseForm.offerPrice}
            className="form-control"
            onChange={(e) => handleFormInputChange(e)}
            name="offerPrice"
            type="number"
          />
          <span className="error">{errors.offerPrice}</span>
          <span>Offer Price</span>
        </label>
        {!isEdit ? (
          <label className="form-group has-float-label mb-4">
            <input
              value={courseForm.duration}
              className="form-control"
              onChange={(e) => handleFormInputChange(e)}
              name="duration"
              type="number"
            />
            <span className="error">{errors.duration}</span>
            <span>Duration - (Number of Days)</span>
          </label>
        ) : (
          <label className="form-group has-float-label mb-4">
            <input
              value={courseForm.duration}
              className="form-control"
              disabled={true}
              name="duration"
              type="number"
            />
            <span className="error">{errors.duration}</span>
            <span>Duration - (Number of Days)</span>
          </label>
        )}

        <div className="align-items-right text-right mt-4">
          <button
            type="button"
            onClick={onClose}
            className="btn btn-danger btn-lg btn-shadow mr-3"
          >
            CANCEL
          </button>
          <button
            type="button"
            onClick={saveCourse}
            className="btn btn-success btn-lg btn-shadow"
          >
            SAVE
          </button>
        </div>
      </div>
    </Modal>
  );
};

CreateCoursePlan.propTypes = {
  onClose: propTypes.func.isRequired,
  open: propTypes.bool.isRequired,
  getAllCourses: propTypes.func,
  courses: propTypes.array,
  createCoursePlan: propTypes.func,
  isEdit: propTypes.bool,
  planData: propTypes.object,
};

const mapStateToProps = (state) => {
  return {
    courses: state.getAllCourseReducer.course,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCourses: () => dispatch(getAllCourses()),
    createCoursePlan: () => dispatch(createCoursePlan(courseData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCoursePlan);
