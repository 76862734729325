import React, { useEffect, useState } from "react";
import {
    fetchSubject,
} from "../../redux/subject/subject-action";
import propTypes from "prop-types";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Pagination from "@material-ui/lab/Pagination";
import config from '../../config';
import CreateSubject from "./create-subject";
import { Capitalize } from "../../util/helper";
import EditIcon from "@material-ui/icons/Edit";

let data = {
    skip: 0,
    limit: 10,
    keyword: "",
    type: "all",
};

const Subject = ({ fetchSubject, subjectResult, createResult }) => {
    const [page, setPage] = useState(1);
    const [rowCount, setRowCount] = useState(10);
    const [show, setshow] = useState({ open: false, edit: false});
    const [editData, setEditData] = useState({});

    useEffect(() => {
        fetchSubject(data);
    }, [createResult]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleRowsPerPage = (event) => {
        setRowCount(event.target.value);
        setPage(1);
        data.limit = event.target.value;
        data.skip = 0;
        fetchSubject(data);
    };

    const searchSubject = (event) => {
        data.skip = 0;
        data.keyword = event.target.value;
        setPage(1);
        fetchSubject(data);
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
        data.skip = (newPage - 1) * rowCount;
        fetchSubject(data);
    };

    const handleStatusChange = (ev) => {
        data.type = ev.target.value;
        data.skip = 0;
        setPage(1);
        fetchSubject(data);
    };

    const closeSubjectModal = () => {
        setshow({ open: false, edit: false});
    }
    const openSubjectModal = () => {
        setshow({ open: true, edit: false});
    };
    const handleEdit = (row) => {
        setshow({ open: true, edit: true});
        setEditData(row);
    }
    
    return (
        <main>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <h1>Subjects</h1>
                        <nav
                            className="breadcrumb-container d-none d-sm-block d-lg-inline-block"
                            aria-label="breadcrumb"
                        >
                            <ol className="breadcrumb pt-0">
                                <li className="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                            </ol>
                        </nav>
                        <div className="separator mb-5"></div>

                        <div className="file-upload mb-3 ml-2">
                            <button
                                className="btn btn-outline-primary"
                                type="button"
                                onClick={() => setshow({open: true, edit: false})}
                            >
                                CREATE SUBJECT
              </button>
                            {
                                show.open ? (
                                    <CreateSubject
                                        open={openSubjectModal}
                                        onClose={closeSubjectModal}
                                        editSubjectData={editData}
                                        isEditSubject={show.edit}
                                    />
                                ) : null
                            }

                        </div>
                        <div className="tableBody">
                            <div className="table-filter-div">
                                <Grid item xs={6}>
                                    <div className="search-div">
                                        <input
                                            type="text"
                                            className="search"
                                            onKeyUp={searchSubject}
                                            placeholder="Search subject.."
                                        ></input>

                                        <div className="filter-div">
                                            <select
                                                className="form-control dropdown select2-single"
                                                onChange={handleStatusChange}
                                            >
                                                <option value="all">All</option>
                                                <option value="course">Course</option>
                                                <option value="prelim">Prelim</option>
                                                <option value="mains">Mains</option>
                                            </select>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="rowCount-div">
                                        <label>Subjects per page : </label>
                                        <select className="selection" onChange={handleRowsPerPage}>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </Grid>
                            </div>
                            <Paper className="paperBody">
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="tableHead" align="center">
                                                    Sl.No
                        </TableCell>
                                                <TableCell className="tableHead">Name</TableCell>
                                                <TableCell className="tableHead">Image</TableCell>
                                                <TableCell className="tableHead">Type</TableCell>
                                                <TableCell className="tableHead">No. of topics</TableCell>
                                                <TableCell className="tableHead">Topics</TableCell>
                                                <TableCell className="tableHead">Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {subjectResult &&
                                                subjectResult.subjects &&
                                                subjectResult.subjects.map((row, index) => {
                                                    return (
                                                        <TableRow hover key={row._id}>
                                                            <TableCell
                                                                className="tableColData"
                                                                align="center"
                                                            >
                                                                {rowCount * (page - 1) + index + 1}
                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                {row.name}
                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                <img className="thumbnail" src={config.SPACE_URL + row.imageKey} alt="thumbnail"></img>
                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                {Capitalize(row.type)}
                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                {row.noOfTopics}
                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                {
                                                                    row.topics &&
                                                                    row.topics.map((topic, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td >
                                                                                    {topic}
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                }

                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                <EditIcon className="edit" fontSize="small" titleAccess="Edit" onClick={() => handleEdit(row)}/>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <div className="paginationLabel">
                                    Showing {(page - 1) * rowCount + 1} to{" "}
                                    {(page - 1) * rowCount +
                                        (subjectResult && subjectResult.subjects
                                            ? subjectResult.subjects.length
                                            : 0)}{" "}
                  of{" "}
                                    {subjectResult.totalSubjects
                                        ? subjectResult.totalSubjects
                                        : 0}{" "}
                  entries
                </div>
                                <div className="root">
                                    <Pagination
                                        count={
                                            subjectResult && subjectResult.totalSubjects
                                                ? Math.ceil(subjectResult.totalSubjects / rowCount)
                                                : 0
                                        }
                                        page={page}
                                        onChange={handlePageChange}
                                        variant="outlined"
                                    />
                                </div>
                            </Paper>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

Subject.propTypes = {
    fetchSubject: propTypes.func,
    subjectResult: propTypes.object,
    createResult: propTypes.object
};

const mapStateToProps = (state) => {
    return {
        subjectResult: state.fetchSubjectReducer.subjects,
        createResult: state.createSubjectReducer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchSubject: () => dispatch(fetchSubject(data)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Subject);
