const initialState = {
  loading: true,
  course: {},
  error: "",
};

const initialStateOfCommonResponse = {
  loading: true,
  status: {},
  error: "",
};

const subjectResponse = {
  loading: true,
  subjects: [],
  error: "",
};

const topicResponse = {
  loading: true,
  topics: [],
  error: "",
};

const courseResponse = {
  loading: true,
  course: [],
  error: "",
};

const coursePlanResponse = {
  loading: true,
  plan: {},
  error: "",
};

const availableCourseReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_COURSE_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_COURSE_SUCCESS":
      return {
        loading: false,
        course: action.payload,
        error: "",
      };
    case "FETCH_COURSE_FAILURE":
      return {
        loading: false,
        course: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const changeCourseStatusReducer = (
  state = initialStateOfCommonResponse,
  action
) => {
  switch (action.type) {
    case "CHANGE_COURSE_STATUS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "CHANGE_COURSE_STATUS_SUCCESS":
      return {
        loading: false,
        status: action.payload,
        error: "",
      };
    case "CHANGE_COURSE_STATUS_FAILURE":
      return {
        loading: false,
        status: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const createCourseReducer = (state = initialStateOfCommonResponse, action) => {
  switch (action.type) {
    case "CREATE_COURSE_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "CREATE_COURSE_SUCCESS":
      return {
        loading: false,
        status: action.payload,
        error: "",
      };
    case "CREATE_COURSE_FAILURE":
      return {
        loading: false,
        status: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const deleteCourseReducer = (state = initialStateOfCommonResponse, action) => {
  switch (action.type) {
    case "DELETE_COURSE_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "DELETE_COURSE_SUCCESS":
      return {
        loading: false,
        status: action.payload,
        error: "",
      };
    case "DELETE_COURSE_FAILURE":
      return {
        loading: false,
        status: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const getCourseSubjectsReducer = (state = subjectResponse, action) => {
  switch (action.type) {
    case "FETCH_COURSE_SUBJECT_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_COURSE_SUBJECT_SUCCESS":
      return {
        loading: false,
        subjects: action.payload,
        error: "",
      };
    case "FETCH_COURSE_SUBJECT_FAILURE":
      return {
        loading: false,
        subjects: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const getCourseTopicReducer = (state = topicResponse, action) => {
  switch (action.type) {
    case "FETCH_COURSE_TOPIC_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_COURSE_TOPIC_SUCCESS":
      return {
        loading: false,
        topics: action.payload,
        error: "",
      };
    case "FETCH_COURSE_TOPIC_FAILURE":
      return {
        loading: false,
        topics: [],
        error: action.payload,
      };
    case "RESET_COURSE_TOPIC":
      return {
        ...state,
        topics: [],
      };
    default:
      return state;
  }
};

const editCourseDataReducer = (
  state = initialStateOfCommonResponse,
  action
) => {
  switch (action.type) {
    case "EDIT_COURSE_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "EDIT_COURSE_SUCCESS":
      return {
        loading: false,
        status: action.payload,
        error: "",
      };
    case "EDIT_COURSE_FAILURE":
      return {
        loading: false,
        status: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const coursePlanReducer = (state = coursePlanResponse, action) => {
  switch (action.type) {
    case "FETCH_COURSE_PLAN_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_COURSE_PLAN_SUCCESS":
      return {
        loading: false,
        plan: action.payload,
        error: "",
      };
    case "FETCH_COURSE_PLAN_FAILURE":
      return {
        loading: false,
        plan: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const createCoursePlanReducer = (
  state = initialStateOfCommonResponse,
  action
) => {
  switch (action.type) {
    case "CREATE_COURSE_PLAN_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "CREATE_COURSE_PLAN_SUCCESS":
      return {
        loading: false,
        status: action.payload,
        error: "",
      };
    case "CREATE_COURSE_PLAN_FAILURE":
      return {
        loading: false,
        status: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const getAllCourseReducer = (state = courseResponse, action) => {
  switch (action.type) {
    case "FETCH_ALL_COURSE_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_ALL_COURSE_SUCCESS":
      return {
        loading: false,
        course: action.payload,
        error: "",
      };
    case "FETCH_ALL_COURSE_FAILURE":
      return {
        loading: false,
        course: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const fetchCoursePurchasedUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_PURCHASED_USERS_REQUEST":
      return {
        loading: true,
      };
    case "FETCH_PURCHASED_USERS_SUCCESS":
      return {
        ...state,
        loading: false,
        course: action.payload,
      };
    case "FETCH_PURCHASED_USERS_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export {
  availableCourseReducer,
  changeCourseStatusReducer,
  createCourseReducer,
  getCourseSubjectsReducer,
  getCourseTopicReducer,
  editCourseDataReducer,
  coursePlanReducer,
  createCoursePlanReducer,
  getAllCourseReducer,
  fetchCoursePurchasedUserReducer,
  deleteCourseReducer
};
