const initialState = {
  loading: true,
  data: {},
  error: "",
};

const fetchArticleReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_ARTICLE_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_ARTICLE_SUCCESS":
      return {
        loading: false,
        data: action.payload,
        error: "",
      };
    case "FETCH_GAME_FAILURE":
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const createArticleReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CREATE_ARTICLE_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "CREATE_ARTICLE_SUCCESS":
      return {
        loading: false,
        data: action.payload,
        error: "",
      };
    case "CREATE_ARTICLE_FAILURE":
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const fetchArticleImagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_ARTICLE_IMAGE_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_ARTICLE_IMAGE_SUCCESS":
      return {
        loading: false,
        data: action.payload,
        error: "",
      };
    case "FETCH_ARTICLE_IMAGE_FAILURE":
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const createArticleImageReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CREATE_ARTICLE_IMAGE_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "CREATE_ARTICLE_IMAGE_SUCCESS":
      return {
        loading: false,
        data: action.payload,
        error: "",
      };
    case "CREATE_ARTICLE_IMAGE_FAILURE":
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export { fetchArticleReducer, createArticleReducer, fetchArticleImagesReducer, createArticleImageReducer };
