import axios from 'axios';
import config from '../../config';
import {FETCH_VIDEO_REQUEST,FETCH_VIDEO_SUCCESS,FETCH_VIDEO_FAILURE,
        LINK_VIDEO_FAILURE,LINK_VIDEO_REQUEST,LINK_VIDEO_SUCCESS,
        FETCH_TOPIC_FAILURE,FETCH_TOPIC_REQUEST,FETCH_TOPIC_SUCCESS,
        ADD_VIDEO_NOTE_FAILURE, ADD_VIDEO_NOTE_REQUEST, ADD_VIDEO_NOTE_SUCCESS,
        CREATE_RECOMMENDED_VIDEO_FAILURE, CREATE_RECOMMENDED_VIDEO_REQUEST, CREATE_RECOMMENDED_VIDEO_SUCCESS,
        FETCH_RECOMMENDED_VIDEO_FAILURE, FETCH_RECOMMENDED_VIDEO_SUCCESS, FETCH_RECOMMENDED_VIDEO_REQUEST,
        DELETE_RECOMMENDED_VIDEO_FAILURE, DELETE_RECOMMENDED_VIDEO_REQUEST, DELETE_RECOMMENDED_VIDEO_SUCCESS,
        DELETE_VIDEO_REQUEST,
        DELETE_VIDEO_SUCCESS,
        DELETE_VIDEO_FAILURE,
        STATUS_UPDATE_VIDEO_REQUEST,
        STATUS_UPDATE_VIDEO_SUCCESS,
        STATUS_UPDATE_VIDEO_FAILURE,
        VIDEO_QUIZ_REQUEST,
        VIDEO_QUIZ_SUCCESS,
        VIDEO_QUIZ_FAILURE

      } from './video-types';
import { toast } from 'react-toastify';

// import videoquiz from '../../assets/mock-response/video-quiz.json';
// import commonResponse from '../../assets/mock-response/common-response.json';
// import recommendedVideos from '../../assets/mock-response/recommended-videos.json';

/**
 * Fetch all videos.
 */
export const fetchVideos = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem( "jwtToken")}`;
    dispatch(fetchVideoRequest());
    axios
      .get(config.API_BASE_URL + "/video/available-videos", {
        params: {
          skip: data.skip,
          limit: data.limit,
          keyword: data.keyword,
        },
      })
      .then((response) => {
        const result = response.data;
        // const result = video;
        dispatch(fetchVideoSuccess(result));
      })
      .catch((error) => {
        toast.error(error.message);
        dispatch(fetchVideoFailure(error.message));
      });
  };
};

export const fetchVideoRequest = () => {
  return {
    type: FETCH_VIDEO_REQUEST,
  };
};

export const fetchVideoSuccess = (response) => {
  return {
    type: FETCH_VIDEO_SUCCESS,
    payload: response,
  };
};

export const fetchVideoFailure = (error) => {
  return {
    type: FETCH_VIDEO_FAILURE,
    payload: error,
  };
};

/**
 * For link and unlink topics to video.
 */
export const linkVideos = (data) => {
  return (dispatch) => {
    if (data.type === "unlink") {
      delete data.topicId;
    }
    dispatch(linkVideoRequest());
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    axios
      .patch(config.API_BASE_URL + "/video/link-video", data)
      .then((response) => {
        const result = response.data;
        if (result.success === true) {
          toast.success(`Successfuly ${data.type}ed the topic.`);
          dispatch(linkVideoSuccess(result));
        } else {
          toast.error(`Failed to ${data.type} topic. Something went wrong!`);
          dispatch(linkVideoFailure(result));
        }
      })
      .catch((error) => {
        toast.error(`Failed to ${data.type} topic. Something went wrong!`);
        dispatch(linkVideoFailure(error.message));
      });
  };
};

export const linkVideoRequest = () => {
  return {
    type: LINK_VIDEO_REQUEST,
  };
};

export const linkVideoSuccess = (response) => {
  return {
    type: LINK_VIDEO_SUCCESS,
    payload: response,
  };
};

export const linkVideoFailure = (error) => {
  return {
    type: LINK_VIDEO_FAILURE,
    payload: error,
  };
};

/**
 * Get all topics.
 */
export const fetchFullTopics = () => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(fetchTopicRequest())
    axios
        .get(config.API_BASE_URL + '/course/get-topics')
        .then(response => {
          const result = response.data;
          dispatch(fetchTopicSuccess(result));
    })
    .catch(error => {
        dispatch(fetchTopicFailure(error.message));
    });
  };
};

export const fetchTopicRequest = () => {
  return {
    type: FETCH_TOPIC_REQUEST,
  };
};

export const fetchTopicSuccess = (response) => {
  return {
    type: FETCH_TOPIC_SUCCESS,
    payload: response,
  };
};

export const fetchTopicFailure = (error) => {
  return {
    type: FETCH_TOPIC_FAILURE,
    payload: error,
  };
};

/**
 * Update/Add video notes.
 */
export const updateVideoNote = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(videoNoteRequest());
    axios
      .patch(config.API_BASE_URL + "/video/video-note", data)
      .then((response) => {
        const result = response.data;
        if (result.success === true) {
          toast.success("Successfully updated video notes.");
          dispatch(videoNoteSuccess(result));
        } else {
          toast.error("Failed to update vide note. Something went wrong!.");
          dispatch(videoNoteFailure(result));
        }
      })
      .catch((error) => {
        toast.error("Failed to update vide note. Something went wrong!.");
        dispatch(videoNoteFailure(error.message));
      });
  };
};

export const videoNoteRequest = () => {
  return {
    type: ADD_VIDEO_NOTE_REQUEST,
  };
};

export const videoNoteSuccess = (response) => {
  return {
    type: ADD_VIDEO_NOTE_SUCCESS,
    payload: response,
  };
};

export const videoNoteFailure = (error) => {
    return {
        type: ADD_VIDEO_NOTE_FAILURE,
        payload: error,
    }
}

// Create Recommended video.
export const createRecommendedVideo = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem("jwtToken")}`;
    dispatch(createRecommendedVideoRequest());
    axios
      .post(config.API_BASE_URL + "/video/recommended-videos", data)
      .then((response) => {
        if (response.data.success) {
          toast.success("Successfuly created the recommended video.");
          dispatch(createRecommendedVideoSuccess(response.data));
        } else {
          toast.error("Failed to create recommended video. Something went wrong");
          dispatch(createRecommendedVideoFailure(response.data));
        }
      })
      .catch((error) => {
        toast.error(error.message);
        dispatch(createRecommendedVideoFailure(error.message));
      });
  };
};

export const createRecommendedVideoRequest = () => {
    return {
        type: CREATE_RECOMMENDED_VIDEO_REQUEST
    };
};

export const createRecommendedVideoSuccess = result => {
    return {
        type: CREATE_RECOMMENDED_VIDEO_SUCCESS,
        payload: result
    };
};

export const createRecommendedVideoFailure = error => {
    return {
        type: CREATE_RECOMMENDED_VIDEO_FAILURE,
        payload: error
    };
  };
/**
 * Fetch recommended videos
 */
export const fetchRecommendedVideo = (data) => {
    return (dispatch) => {
      axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem("jwtToken")}`;
        dispatch(recommendedVideoRequest())
        axios
            .get(config.API_BASE_URL + '/video/recommended-video-list', {
                params: {
                    skip: data.skip,
                    limit: data.limit,
                    keyword: data.keyword,
                }
            })
            .then(response => {
                const result = response.data
                dispatch(recommendedVideoSuccess(result));
            })
            .catch(error => {
                toast.error("Failed to fetch recommended video. Something went wrong!.");
                dispatch(recommendedVideoFailure(error.message));
            });
    };
};

export const recommendedVideoRequest = () => {
    return {
        type: FETCH_RECOMMENDED_VIDEO_REQUEST,
    };
};

export const recommendedVideoSuccess = (response) => {
    return {
        type: FETCH_RECOMMENDED_VIDEO_SUCCESS,
        payload: response,
    };
};

export const recommendedVideoFailure = (error) => {
    return {
        type: FETCH_RECOMMENDED_VIDEO_FAILURE,
        payload: error,
    }
}


/**
 * Delete recommended video
 */
export const deleteRecommendedVideo = (videoId) => {
    return (dispatch) => {
        axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem( "jwtToken")}`;
        dispatch(deleteRecommendedVideoRequest());
        axios
            .delete(config.API_BASE_URL + '/video/recommended/' + videoId)
            .then(response => {
                const result = response.data
                if(result.success === true){
                    toast.success('Successfully deleted recommended video.');
                     dispatch(deleteRecommendedVideoSuccess(result));
                }else{
                    toast.error('Failed to delete recommended video. Something went wrong!.');
                    dispatch(deleteRecommendedVideoFailure(result));
                }
            })
            .catch(error => {
                toast.error('Failed to delete recommended video. Something went wrong!.');
                dispatch(deleteRecommendedVideoFailure(error.message));
            });
    };
};

export const deleteRecommendedVideoRequest = () => {
    return {
        type: DELETE_RECOMMENDED_VIDEO_REQUEST,
    };
};

export const deleteRecommendedVideoSuccess = (response) => {
    return {
        type: DELETE_RECOMMENDED_VIDEO_SUCCESS,
        payload: response,
    };
};

export const deleteRecommendedVideoFailure = (error) => {
    return {
        type: DELETE_RECOMMENDED_VIDEO_FAILURE,
        payload: error,
    }
}

  export const deleteVideo=(params)=>{
    return (dispatch)=>{
     axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem( "jwtToken")}`;
    dispatch(deleteVideoRequest());
    axios
    .delete(config.API_BASE_URL + `/video/delete-video/${params.videoId}`)
    .then((response)=>{
       if(response.data.success){
         toast.success("deleted successfully");
         dispatch(deleteVideoSuccess(response.data))
       }
       else{
         toast.error("Something went wrong!!");
         dispatch(deleteVideoSuccess(response.data))
       }
    })
    .catch((err)=>{
      dispatch(deleteVideoFailure(err.message));
      toast.error(err.message);
    })
    }
  }

  export const deleteVideoRequest=()=>{
     return{
       type:DELETE_VIDEO_REQUEST,
     }
  }

  export const deleteVideoSuccess=(data)=>{
    return{
      type: DELETE_VIDEO_SUCCESS,
      payload:data
    }
  }

  export const deleteVideoFailure=(data)=>{
    return{
      type : DELETE_VIDEO_FAILURE,
      payload:data
    }
  }

  export const videoStatus = (data) => {
    return (dispatch) => {
      axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        "jwtToken"
      )}`;
      dispatch(videoStatusRequest());
      axios
        .patch(config.API_BASE_URL + "/video/change-status", data)
        .then((response) => {
          const result = response.data;
          if (result.success === true) {
            toast.success("Successfully updated video status.");
            dispatch(videoStatusSuccess(result));
          } 
        })
        .catch((error) => {
          toast.error("Failed to update video status. Something went wrong!.");
          dispatch(videoStatusFailure(error.message));
        });
    };
  };
  
  export const videoStatusRequest = () => {
    return {
      type: STATUS_UPDATE_VIDEO_REQUEST,
    };
  };
  
  export const videoStatusSuccess = (response) => {
    return {
      type: STATUS_UPDATE_VIDEO_SUCCESS,
      payload: response,
    };
  };
  
  export const videoStatusFailure = (error) => {
      return {
          type: STATUS_UPDATE_VIDEO_FAILURE,
          payload: error,
      }
  }

  export const editVideo = (video) =>
  axios
    .patch(config.API_BASE_URL + "/video/change-status", video, {
      headers: { Authorization: `Bearer ${localStorage.getItem("jwtToken")}` },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
      toast.error("Failed to update video. Something went wrong");
  });

  export const getVideoQuiz = (id) =>{
    return (dispatch) => {
      axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem("jwtToken")}`;
        dispatch(videoQuizRequest())
        axios
            .get(config.API_BASE_URL + '/video/quiz-data', {params:{videoId: id}})
            .then((response) => {
                const result = response.data
                dispatch(videoQuizSuccess(result));
            })
            .catch(error => {
                toast.error("Failed to fetch video quiz. Something went wrong!.");
                dispatch(videoQuizFailure(error.message));
            });
    };
  }

  export const videoQuizRequest = () => {
    return {
      type: VIDEO_QUIZ_REQUEST,
    };
  };
  
  export const videoQuizSuccess = (response) => {
    return {
      type: VIDEO_QUIZ_SUCCESS,
      payload: response,
    };
  };
  
  export const videoQuizFailure = (error) => {
      return {
          type: VIDEO_QUIZ_FAILURE,
          payload: error,
      }
  }