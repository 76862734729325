import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
const SubMenu = ({ data, subMenuLink, closeSubMenu }) => {
  const [link, setLink] = useState(0);
  let { pathname } = useLocation();
  pathname = pathname.split("/");
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          data = data.map((item) => {
            if (item && item.to.includes(pathname[2])) {
              setLink(item.id);
              closeSubMenu(event, item.parent, item.id);
            }
          });
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  useEffect(() => {
    return data.map((item) => {
      if (item.to.includes(pathname[2])) {
        setLink(item.id);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  return (
    <div
      className="sub-menu default-transition sub-menu-animation"
      ref={wrapperRef}
    >
      <div className="scroll ps">
        <ul className="list-unstyled d-block" data-link="dashboard">
          {data.map((list) => {
            if (!list.isDropdown) {
              return (
                <React.Fragment key={list.id}>
                  <li
                    onClick={(e) => closeSubMenu(e, list.parent, list.id)}
                    className={
                      list.id === subMenuLink || list.id === link
                        ? "active"
                        : ""
                    }
                  >
                    <Link exact to={`${list.to}`}>
                      <i className={list.icon}></i>{" "}
                      <span className="d-inline-block">{list.name}</span>
                    </Link>
                    {list.id === subMenuLink}
                  </li>
                </React.Fragment>
              );
            } else {
              return (
                <li>
                  <Link
                    exact
                    to={list.to}
                    data-toggle="collapse"
                    data-target="#collapseAuthorization"
                    aria-expanded="true"
                    aria-controls="collapseAuthorization"
                    className="rotate-arrow-icon opacity-50"
                  >
                    <i className={list.icon}></i>{" "}
                    <span className="d-inline-block">{list.name}</span>
                  </Link>
                  <div id="collapseAuthorization" className="collapse show">
                    <ul className="list-unstyled inner-level-menu">
                      {list.dropdownArr.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <li>
                              <a href="Pages.Auth.Login.html">
                                <i className={item.class}></i>{" "}
                                <span className="d-inline-block">
                                  {item.name}
                                </span>
                              </a>
                            </li>
                          </React.Fragment>
                        );
                      })}
                    </ul>
                  </div>
                </li>
              );
            }
          })}
        </ul>
      </div>
    </div>
  );
};
SubMenu.propTypes = {
  data: PropTypes.array.isRequired,
  subMenuLink: PropTypes.any.isRequired,
  closeSubMenu: PropTypes.func.isRequired,
};

export default SubMenu;
