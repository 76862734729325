export const FETCH_VIDEO_REQUEST = 'FETCH_VIDEO_REQUEST'
export const FETCH_VIDEO_SUCCESS = 'FETCH_VIDEO_SUCCESS'
export const FETCH_VIDEO_FAILURE = 'FETCH_VIDEO_FAILURE'

export const LINK_VIDEO_REQUEST = 'LINK_VIDEO_REQUEST'
export const LINK_VIDEO_SUCCESS = 'LINK_VIDEO_SUCCESS'
export const LINK_VIDEO_FAILURE = 'LINK_VIDEO_FAILURE'

export const FETCH_TOPIC_REQUEST = 'FETCH_TOPIC_REQUEST'
export const FETCH_TOPIC_SUCCESS = 'FETCH_TOPIC_SUCCESS'
export const FETCH_TOPIC_FAILURE = 'FETCH_TOPIC_FAILURE'

export const ADD_VIDEO_NOTE_REQUEST = 'ADD_VIDEO_NOTE_REQUEST'
export const ADD_VIDEO_NOTE_SUCCESS = 'ADD_VIDEO_NOTE_SUCCESS'
export const ADD_VIDEO_NOTE_FAILURE = 'ADD_VIDEO_NOTE_FAILURE'

export const CREATE_RECOMMENDED_VIDEO_REQUEST = 'CREATE_RECOMMENDED_VIDEO_REQUEST'
export const CREATE_RECOMMENDED_VIDEO_SUCCESS = 'CREATE_RECOMMENDED_VIDEO_SUCCESS'
export const CREATE_RECOMMENDED_VIDEO_FAILURE = 'CREATE_RECOMMENDED_VIDEO_FAILURE'

export const FETCH_RECOMMENDED_VIDEO_REQUEST = 'FETCH_RECOMMENDED_VIDEO_REQUEST'
export const FETCH_RECOMMENDED_VIDEO_SUCCESS = 'FETCH_RECOMMENDED_VIDEO_SUCCESS'
export const FETCH_RECOMMENDED_VIDEO_FAILURE = 'FETCH_RECOMMENDED_VIDEO_FAILURE'

export const DELETE_RECOMMENDED_VIDEO_REQUEST = 'DELETE_RECOMMENDED_VIDEO_REQUEST'
export const DELETE_RECOMMENDED_VIDEO_SUCCESS = 'DELETE_RECOMMENDED_VIDEO_SUCCESS'
export const DELETE_RECOMMENDED_VIDEO_FAILURE = 'DELETE_RECOMMENDED_VIDEO_FAILURE'

export const DELETE_VIDEO_REQUEST = "DELETE_VIDEO_REQUEST";
export const DELETE_VIDEO_SUCCESS = "DELETE_VIDEO_SUCCESS";
export const DELETE_VIDEO_FAILURE = "DELETE_VIDEO_FAILURE";

export const STATUS_UPDATE_VIDEO_REQUEST = "STATUS_UPDATE_VIDEO_REQUEST";
export const STATUS_UPDATE_VIDEO_SUCCESS = "STATUS_UPDATE_VIDEO_SUCCESS";
export const STATUS_UPDATE_VIDEO_FAILURE = "STATUS_UPDATE_VIDEO_FAILURE";

export const VIDEO_QUIZ_REQUEST = "VIDEO_QUIZ_REQUEST";
export const VIDEO_QUIZ_SUCCESS = "VIDEO_QUIZ_SUCCESS";
export const VIDEO_QUIZ_FAILURE = "VIDEO_QUIZ_FAILURE";