const initialState = {
    loading: true,
    subjects: {},
    error: ''
}

const fetchSubjectReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SUBJECT_LIST_REQUEST':
            return {
                ...state,
                loading: true
            }
        case 'SUBJECT_LIST_SUCCESS':
            return {
                loading: false,
                subjects: action.payload,
                error: ''
            }
        case 'SUBJECT_LIST_FAILURE':
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

const createSubjectReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CREATE_SUBJECT_REQUEST':
            return {
                ...state,
                loading: true
            }
        case 'CREATE_SUBJECT_SUCCESS':
            return {
                loading: false,
                result: action.payload,
                error: ''
            }
        case 'CREATE_SUBJECT_FAILURE':
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}


export {fetchSubjectReducer, createSubjectReducer};
