import React from "react";
import { withRouter } from "react-router";
const VideoQuiz = () => {
  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Video-Quiz</h1>
            <nav
              className="breadcrumb-container d-none d-sm-block d-lg-inline-block"
              aria-label="breadcrumb"
            >
              <ol className="breadcrumb pt-0">
                <li className="breadcrumb-item">
                  <a href="/">Menu</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/library">Library</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <a href="/data">Data</a>
                </li>
              </ol>
            </nav>
            <div className="separator mb-5"></div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default withRouter(VideoQuiz);
