export const FETCH_DAILY_TEST_REQUEST = "FETCH_DAILY_TEST_REQUEST";
export const FETCH_DAILY_TEST_SUCCESS = "FETCH_DAILY_TEST_SUCCESS";
export const FETCH_DAILY_TEST_FAILURE = "FETCH_DAILY_TEST_FAILURE";

export const UPDATE_DAILY_TEST_STATUS_REQUEST =
  "UPDATE_DAILY_TEST_STATUS_REQUEST";
export const UPDATE_DAILY_TEST_STATUS_SUCCESS =
  "UPDATE_DAILY_TEST_STATUS_SUCCESS";
export const UPDATE_DAILY_TEST_STATUS_FAILURE =
  "UPDATE_DAILY_TEST_STATUS_FAILURE";

export const EDIT_DAILY_TEST_REQUEST = "EDIT_DAILY_TEST_REQUEST";
export const EDIT_DAILY_TEST_SUCCESS = "EDIT_DAILY_TEST_SUCCESS";
export const EDIT_DAILY_TEST_FAILURE = "EDIT_DAILY_TEST_FAILURE";

export const FETCH_PRELIMS_TESTS_REQUEST = "FETCH_PRELIMS_TESTS_REQUEST";
export const FETCH_PRELIMS_TESTS_SUCCESS = "FETCH_PRELIMS_TESTS_SUCCESS";
export const FETCH_PRELIMS_TESTS_FAILURE = "FETCH_PRELIMS_TESTS_FAILURE";

export const UPLOAD_PRELIM_TEST_SYLLABUS_REQUEST = "UPLOAD_PRELIM_TEST_SYLLABUS_REQUEST";
export const UPLOAD_PRELIM_TEST_SYLLABUS_SUCCESS = "UPLOAD_PRELIM_TEST_SYLLABUS_SUCCESS";
export const UPLOAD_PRELIM_TEST_SYLLABUS_FAILURE = "UPLOAD_PRELIM_TEST_SYLLABUS_FAILURE";

export const STATUS_PRELIMS_TESTS_REQUEST = "STATUS_PRELIMS_TESTS_REQUEST";
export const STATUS_PRELIMS_TESTS_SUCCESS = "STATUS_PRELIMS_TESTS_SUCCESS";
export const STATUS_PRELIMS_TESTS_FAILURE = "STATUS_PRELIMS_TESTS_FAILURE";

export const FETCH_QUESTIONS_REQUEST = "FETCH_QUESTIONS_REQUEST";
export const FETCH_QUESTIONS_SUCCESS = "FETCH_QUESTIONS_SUCCESS";
export const FETCH_QUESTIONS_FAILURE = "FETCH_QUESTIONS_FAILURE";

export const LINK_VIMEO_ID_REQUEST = "LINK_VIMEO_ID_REQUEST ";
export const LINK_VIMEO_ID_SUCCESS = "LINK_VIMEO_ID_SUCCESS";
export const LINK_VIMEO_ID_FAILURE = "LINK_VIMEO_ID_FAILURE";
export const DELETE_VIDEO_ID = "DELETE_VIDEO_ID";

export const GET_DAILY_EXAM_DETAILS_REQUEST = "GET_DAILY_EXAM_DETAILS_REQUEST";
export const GET_DAILY_EXAM_DETAILS_SUCCESS = "GET_DAILY_EXAM_DETAILS_SUCCESS";
export const GET_DAILY_EXAM_DETAILS_FAILURE = "GET_DAILY_EXAM_DETAILS_FAILURE";

export const CREATE_EXAM_REQUEST = "CREATE_EXAM_REQUEST";
export const CREATE_EXAM_SUCCESS = "CREATE_EXAM_SUCCESS";
export const CREATE_EXAM_FAILURE = "CREATE_EXAM_FAILURE";

export const GET_PRELIM_EXAM_DETAILS_REQUEST = "GET_PRELIM_EXAM_DETAILS_REQUEST";
export const GET_PRELIM_EXAM_DETAILS_SUCCESS = "GET_PRELIM_EXAM_DETAILS_SUCCESS";
export const GET_PRELIM_EXAM_DETAILS_FAILURE = "GET_PRELIM_EXAM_DETAILS_FAILURE";
