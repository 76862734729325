import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { connect } from "react-redux";
import { fetchRecommendedVideo, deleteRecommendedVideo } from '../../redux/video/video-action';
import propTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Pagination from "@material-ui/lab/Pagination";
import DeleteIcon from "@material-ui/icons/Delete";
import config from '../../config';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import  CreateRecommendedVideo  from '../../components/popup/create-recommended-video';
import { useSelector } from "react-redux";


let data = {
    skip: 0,
    limit: 10,
    keyword: "",
};

let deleteVideoId = '';

const RecommendedVideoList = ({ fetchRecommendedVideo, videos, deleteRecommendedVideo }) => {
    const [page, setPage] = useState(1);
    const [rowCount, setRowCount] = useState(10);
    const [dialogue, setOpen] = useState({ open: false, video: '' });
    const { open } = dialogue;
    const [openCreateVideoModal, setCreateVideoModal] = useState(false);
    const statusChange = useSelector((state) => state.createRecommendedVideoReducer);
    const deleteStatus = useSelector((state) => state.deleteRecommendedVideoReducer);

    useEffect(() => {
        fetchRecommendedVideo(data);
    }, [statusChange, deleteStatus]); // eslint-disable-line react-hooks/exhaustive-deps

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
        data.skip = (newPage - 1) * rowCount;
        fetchRecommendedVideo(data);
    };

    const searchVideos = (event) => {
        data.skip = 0;
        data.keyword = event.target.value;
        setPage(1);
        fetchRecommendedVideo(data);
    };

    const handleRowsPerPage = (event) => {
        setRowCount(event.target.value);
        setPage(1);
        data.limit = event.target.value;
        data.skip = 0;
        fetchRecommendedVideo(data);
    };

    const deleteVideo = (row) => {
        setOpen({ open: true, video: row.videoId });
    }

    const handleClose = () => {
        setOpen({ open: false });
    };

    const handleAgree = () => {
        setOpen({ open: false });
        deleteVideoId = dialogue.video;
        deleteRecommendedVideo(deleteVideoId);
    }

    const createRecommendedVideo = () => {
        setCreateVideoModal(true);
    }

    const closeVideoModal = () => {
        setCreateVideoModal(false);
        setTimeout(() => {
          fetchRecommendedVideo(data);
        }, 1000);
    }

    return (
        <main>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <h1>Recommended Videos</h1>
                        <nav
                            className="breadcrumb-container d-none d-sm-block d-lg-inline-block"
                            aria-label="breadcrumb"
                        >
                            <ol className="breadcrumb pt-0">
                                <li className="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                            </ol>
                        </nav>
                        <div className="separator mb-5"></div>
                        <div className="file-upload mb-3 ml-2">
                            <button
                                className="btn btn-outline-primary"
                                type="button" onClick={createRecommendedVideo}>
                                CREATE RECOMMENDED VIDEO
                             </button>
                        </div>
                        {openCreateVideoModal ? <CreateRecommendedVideo onClose={closeVideoModal} open={openCreateVideoModal} /> : null}
                        <div className="tableBody">
                            <div className="table-filter-div">
                                <Grid item xs={6}>
                                    <div className="search-div">
                                        <input
                                            type="text"
                                            className="search"
                                            onKeyUp={searchVideos}
                                            placeholder="Search videos.."
                                        ></input>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="rowCount-div">
                                        <label>Videos per page : </label>
                                        <select className="selection"
                                            onChange={handleRowsPerPage}>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </Grid>
                            </div>
                            <Paper className="paperBody">
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="tableHead" align="center">Sl.No</TableCell>
                                                <TableCell className="tableHead">Vimeo Id</TableCell>
                                                <TableCell className="tableHead">Image</TableCell>
                                                <TableCell className="tableHead">Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {videos &&
                                                videos.video &&
                                                videos.video.map((row, index) => {
                                                    return (
                                                        <TableRow hover key={row.videoId}>
                                                            <TableCell
                                                                className="tableColData"
                                                                align="center"
                                                            >
                                                                {rowCount * (page - 1) + index + 1}
                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                {row.vimeoId}
                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                <img className="videoImage" src={config.SPACE_URL + row.imageKey} />
                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                <DeleteIcon titleAccess="Delete" onClick={() => deleteVideo(row)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <div className="paginationLabel">
                                    Showing {(page - 1) * rowCount + 1} to{" "}
                                    {(page - 1) * rowCount +
                                        (videos && videos.video
                                            ? videos.video.length
                                            : 0)}{" "}
                                     of{" "}
                                    {videos.totalVideos
                                        ? videos.totalVideos
                                        : 0}{" "} entries
                                </div>
                                <div className="root">
                                    <Pagination
                                        count={
                                            videos && videos.totalVideos
                                                ? Math.ceil(videos.totalVideos / rowCount)
                                                : 0
                                        }
                                        page={page}
                                        onChange={handlePageChange}
                                        variant="outlined"
                                    />
                                </div>
                            </Paper>
                        </div>

                        <Dialog
                            open={open}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">Do you want to delete this video ?</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    By clicking yes button this video will be disappear from the recommended video list in aspirant app.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="secondary">
                                    NO
                                </Button>
                                <Button onClick={handleAgree} color="primary">
                                    YES
                                </Button>
                            </DialogActions>
                        </Dialog>

                    </div>
                </div>
            </div>
        </main>
    );
};
RecommendedVideoList.propTypes = {
    fetchRecommendedVideo: propTypes.func,
    videos: propTypes.object,
    deleteRecommendedVideo: propTypes.func
};

const mapStateToProps = (state) => {
    return {
        videos: state.recommendedVideoReducer.videoList
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchRecommendedVideo: () => dispatch(fetchRecommendedVideo(data)),
        deleteRecommendedVideo: () => dispatch(deleteRecommendedVideo(deleteVideoId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecommendedVideoList);
