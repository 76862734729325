import React, { useState,useEffect } from "react";
import {useSelector, connect} from 'react-redux';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {fetchTopics} from '../../redux/topic/topic-action';
import {Capitalize} from '../../util/helper';
import config from '../../config';
import Pagination from "@material-ui/lab/Pagination";
import TopicPopup from '../../components/popup/topic-popup';
import EditIcon from "@material-ui/icons/Edit";
import propTypes from "prop-types";

let data = {
  skip: 0,
  limit: 10,
  keyword: "",
};
const Topics = ({ fetchTopics, topicResult, createTopicResult }) => {
  const [page, setPage] = useState(1);
  const [rowCount, setRowCount] = useState(10);
  const [topicModal,setModal] = useState({open: false, edit: false});
  const [editData, setEditData] = useState({});

  useEffect(() => {
   fetchTopics(data);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createTopicResult])

  const searchTopics = (e) => {
    data.skip = 0;
    data.keyword = e.target.value;
    setPage(1);
    fetchTopics(data);
  };

  const handleRowsPerPage = (e) => {
    setRowCount(e.target.value);
    setPage(1);
    data.limit = e.target.value;
    data.skip = 0;
    fetchTopics(data);
  };

  const handlePageChange = (event, newPage) => {
        setPage(newPage);
        data.skip = (newPage - 1) * rowCount;
        fetchTopics(data);
  };

  const createTopicModal=()=>{
    setModal({open: true, edit: false})
  }

  const closeTopic=()=>{
    setModal({open: false, edit: false})
  }

  const handleEdit = (row) => {
    setModal({open: true, edit: true})
    setEditData(row);
  }

  const fetchTopicState = useSelector(state => state.fetchTopicReducer)
  return (
    <main className="default-transition">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Create Topic</h1>
            <nav
              className="breadcrumb-container d-none d-sm-block d-lg-inline-block"
              aria-label="breadcrumb"
            >
              <ol className="breadcrumb pt-0">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
              </ol>
            </nav>
            <div className="separator mb-5"></div>
            <div className="file-upload mb-3 ml-2">
              <button
                className="btn btn-outline-primary"
                type="button"
                onClick={createTopicModal}
              >
                CREATE TOPIC
              </button>
              {topicModal.open ? (
                <TopicPopup
                  open={topicModal}
                  onClose={closeTopic}
                  isEditTopic={topicModal.edit}
                  editTopicData={editData}
                />
              ) : null}
            </div>
            <div className="tableBody">
              <div className="table-filter-div">
                <Grid item xs={6}>
                  <div className="search-div">
                    <input
                      type="text"
                      className="search"
                      onKeyUp={searchTopics}
                      placeholder="Search topics.."
                    ></input>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="rowCount-div">
                    <label>Topics per page : </label>
                    <select className="selection" onChange={handleRowsPerPage}>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </Grid>
              </div>
              <Paper className="paperBody">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="tableHead" align="center">
                          Sl.No
                        </TableCell>
                        <TableCell className="tableHead">Name</TableCell>
                        <TableCell className="tableHead">Type</TableCell>
                        <TableCell className="tableHead">
                          ThumbNail
                        </TableCell>
                        <TableCell className="tableHead">Subject Name</TableCell>
                        <TableCell className="tableHead" align="center">Number of Videos</TableCell>
                        <TableCell className="tableHead" align="center">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        topicResult.data.topics && topicResult.data.topics.length > 0 ?
                        topicResult.data.topics.map((row, index) => {
                          return (
                            <TableRow hover key={row._id}>
                              <TableCell
                                className="tableColData"
                                align="center"
                              >
                                {rowCount * (page - 1) + index + 1}
                              </TableCell>
                              <TableCell className="tableColData">
                                {row.name}
                              </TableCell>
                              <TableCell className="tableColData">
                                {Capitalize(row.type)}
                              </TableCell>
                              <TableCell className="tableColData">
                                <img className="thumbnail" src={config.SPACE_URL + row.imageKey} />
                              </TableCell>
                              <TableCell className="tableColData">
                                {row.subjectName}
                              </TableCell>
                              <TableCell className="tableColData" align="center">
                                {row.noOfVideos}
                              </TableCell>
                              <TableCell className="tableColData">
                                <EditIcon className="edit" fontSize="small" titleAccess="Edit" onClick={() => handleEdit(row)}/>
                              </TableCell>
                            </TableRow>
                          );
                        }):
                        <TableRow hover>
                        <TableCell colSpan="6" align="center">
                        No Data to Display
                        </TableCell>
                        </TableRow>
                       }
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className="paginationLabel">
                  Showing {(page - 1) * rowCount + 1} to{" "}
                  {(page - 1) * rowCount +
                    (fetchTopicState && fetchTopicState.data.topics ? fetchTopicState.data.topics.length : 0)}{" "}
                  of {fetchTopicState.data.totalTopics ? fetchTopicState.data.totalTopics : 0} entries
                </div>
                <div className="root">
                  <Pagination
                    count={
                      fetchTopicState.data && fetchTopicState.data.totalTopics
                        ? Math.ceil(fetchTopicState.data.totalTopics / rowCount)
                        : 0
                    }
                    page={page}
                    onChange={handlePageChange}
                    variant="outlined"
                  />
                </div>
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

Topics.propTypes = {
  fetchTopics: propTypes.func,
  topicResult: propTypes.object,
  createTopicResult: propTypes.object
};

const mapStateToProps = (state) => {
  return {
      topicResult: state.fetchTopicReducer,
      createTopicResult: state.createTopicReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
      fetchTopics: () => dispatch(fetchTopics(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Topics);
