const initialState = {
  loading: true,
  prelim: {},
  error: "",
};

const initialStateOfCommonResponse = {
  loading: true,
  status: {},
  error: "",
};

const initialPrelimTestResponse = {
  loading: true,
  test: [],
  error: "",
};

const prelimReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_PRELIM_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_PRELIM_SUCCESS":
      return {
        loading: false,
        prelim: action.payload,
        error: "",
      };
    case "FETCH_PRELIM_FAILURE":
      return {
        loading: false,
        prelim: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const prelimStatusUpdateReducer = (
  state = initialStateOfCommonResponse,
  action
) => {
  switch (action.type) {
    case "UPDATE_PRELIM_STATUS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "UPDATE_PRELIM_STATUS_SUCCESS":
      return {
        loading: false,
        status: action.payload,
        error: "",
      };
    case "UPDATE_PRELIM_STATUS_FAILURE":
      return {
        loading: false,
        status: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const createPrelimReducer = (state = initialStateOfCommonResponse, action) => {
  switch (action.type) {
    case "CREATE_PRELIM_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "CREATE_PRELIM_SUCCESS":
      return {
        loading: false,
        status: action.payload,
        error: "",
      };
    case "CREATE_PRELIM_FAILURE":
      return {
        loading: false,
        status: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const editPrelimReducer = (state = initialStateOfCommonResponse, action) => {
  switch (action.type) {
    case "EDIT_PRELIM_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "EDIT_PRELIM_SUCCESS":
      return {
        loading: false,
        status: action.payload,
        error: "",
      };
    case "EDIT_PRELIM_FAILURE":
      return {
        loading: false,
        status: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const getPrelimTestReducer = (state = initialPrelimTestResponse, action) => {
  switch (action.type) {
    case "FETCH_PRELIM_TEST_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_PRELIM_TEST_SUCCESS":
      return {
        loading: false,
        test: action.payload,
        error: "",
      };
    case "FETCH_PRELIM_TEST_FAILURE":
      return {
        loading: false,
        test: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const addPrelimTestReducer = (state = initialStateOfCommonResponse, action) => {
  switch (action.type) {
    case "ADD_PRELIM_TEST_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "ADD_PRELIM_TEST_SUCCESS":
      return {
        loading: false,
        error: "",
      };
    case "ADD_PRELIM_TEST_FAILURE":
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const fetchPrelimPurchasedUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_PURCHASED_USERS_REQUEST":
      return {
        loading: true,
      };
    case "FETCH_PURCHASED_USERS_SUCCESS":
      return {
        ...state,
        loading: false,
        prelim: action.payload,
      };
    case "FETCH_PURCHASED_USERS_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  addPrelimTestReducer,
  prelimReducer,
  prelimStatusUpdateReducer,
  createPrelimReducer,
  editPrelimReducer,
  getPrelimTestReducer,
  fetchPrelimPurchasedUserReducer,
};
