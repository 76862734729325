import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Table,
  TableContainer,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import EditIcon from "@material-ui/icons/Edit";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import {
  fetchArticles,
  createArticle,
} from "../../redux/article/article-action";
import { connect } from "react-redux";
import { Capitalize } from "../../util/helper";
import config from "../../config";
import propTypes from "prop-types";
import moment from "moment";
import CreateArticle from "./create-article";

let data = {
  skip: 0,
  limit: 10,
  searchKeyword: "",
};

const Article = ({
  articleResult,
  fetchAllArticles,
  editArticleStatus,
  editArticleResult,
}) => {
  const [page, setPage] = useState(1);
  const [rowCount, setRowCount] = useState(10);
  const [articleModal, setArticleModal] = useState({
    open: false,
    edit: false,
  });
  const [statusPopup, setStatusPopup] = useState(false);
  const [action, setAction] = useState("");
  const [editData, setEditData] = useState({});

  useEffect(() => {
    fetchAllArticles(data);
  }, [editArticleResult]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRows = (event) => {
    setRowCount(event.target.value);
    setPage(1);
    data.limit = event.target.value;
    data.skip = 0;
    fetchAllArticles(data);
  };

  const handlePages = (_, nextPage) => {
    setPage(nextPage);
    data.skip = (nextPage - 1) * rowCount;
    fetchAllArticles(data);
  };

  const handleSearchArticle = (event) => {
    data.searchKeyword = event.target.value;
    data.skip = 0;
    setPage(1);
    fetchAllArticles(data);
  };

  const handleCreateArticle = () => {
    setArticleModal({ open: true, edit: false });
  };

  const handleCloseArticle = () => {
    setArticleModal({ open: false, edit: false });
  };
  const handleEditArticle = (row) => {
    setArticleModal({ open: true, edit: true });
    setEditData(row);
  };

  const handleStatus = (row, action) => {
    setStatusPopup(true);
    setEditData(row);
    setAction(action);
  };

  const closeStatusPopup = () => setStatusPopup(false);

  const handleClose = (e, action) => {
    e.stopPropagation();
    if (action === "published" || action === "draft") {
      let param = {
        status: action,
        _id: editData._id,
        publishedDate: editData.publishedDate
      };
      editArticleStatus(param);
    }
    closeStatusPopup();
  };

  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Articles</h1>
            <nav
              className="breadcrumb-container d-none d-sm-block d-lg-inline-block"
              aria-label="breadcrumb"
            >
              <ol className="breadcrumb pt-0">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
              </ol>
            </nav>
            <div className="separator mb-5">
              <div className=" ml-2 mb-3">
                <button
                  className="btn btn-outline-primary mr-3"
                  type="button"
                  onClick={handleCreateArticle}
                >
                  CREATE ARTICLE
                </button>
              </div>
              {articleModal.open ? (
                <CreateArticle
                  open={articleModal.open}
                  onClose={handleCloseArticle}
                  isEditArticle={articleModal.edit}
                  articleData={editData}
                />
              ) : null}
              <div className="tableBody">
                <div className="table-filter-div">
                  <Grid item xs={6}>
                    <div className="serach-div">
                      <input
                        type="text"
                        className="search"
                        onKeyUp={handleSearchArticle}
                        placeholder="Search article..."
                      ></input>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="rowCount-div">
                      <label>Articles per page : </label>
                      <select className="selection" onChange={handleRows}>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </Grid>
                </div>
                <Paper className="paperBody">
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="tableHead" align="center">
                            Sl.No
                          </TableCell>
                          <TableCell className="tableHead">Title</TableCell>
                          <TableCell className="tableHead">
                            Short Description
                          </TableCell>
                          <TableCell className="tableHead" width="15%">Subject </TableCell>
                          <TableCell className="tableHead">Image</TableCell>
                          <TableCell className="tableHead">
                            Published Date
                          </TableCell>
                          <TableCell className="tableHead">
                            Read time (seconds)
                          </TableCell>
                          <TableCell className="tableHead">Status</TableCell>
                          <TableCell className="tableHead">Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {articleResult &&
                          articleResult.articles &&
                          articleResult.articles.map((row, i) => (
                            <TableRow hover key={row._id}>
                              <TableCell
                                className="tableColData"
                                align="center"
                              >
                                {rowCount * (page - 1) + i + 1}
                              </TableCell>
                              <TableCell className="tableColData">
                                {Capitalize(row.title)}
                              </TableCell>
                              <TableCell className="tableColData">
                                {row.shortDescription}
                              </TableCell>
                              <TableCell className="tableColData">
                                {row.subject.name}
                              </TableCell>
                              <TableCell className="tableColData">
                                <img
                                  className="thumbnail"
                                  src={config.SPACE_URL + row.imageKey}
                                  alt="thumbnail"
                                ></img>
                              </TableCell>
                              <TableCell className="tableColData">
                                {moment(row.publishedDate).format("DD/MM/YYYY")}{" "}
                              </TableCell>
                              <TableCell className="tableColData">
                                {row.readTime}
                              </TableCell>
                              <TableCell className="tableColData">
                                {Capitalize(row.status)}
                              </TableCell>
                              <TableCell className="tableColData">
                                <span className="mr-1">
                                  <EditIcon
                                    titleAccess="Edit"
                                    aria-label="edit"
                                    fontSize="small"
                                    onClick={() => handleEditArticle(row)}
                                  />
                                </span>
                                <span>
                                  {row.status === "draft" ? (
                                    <DoneIcon
                                      className="publish text-success"
                                      fontSize="small"
                                      titleAccess="Publish"
                                      onClick={() =>
                                        handleStatus(row, "published")
                                      }
                                    />
                                  ) : null}
                                  {row.status === "published" ? (
                                    <CloseIcon
                                      className="draft text-danger"
                                      fontSize="small"
                                      titleAccess="Draft"
                                      onClick={() => handleStatus(row, "draft")}
                                    />
                                  ) : null}
                                </span>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div className="paginationLabel">
                    Showing {(page - 1) * rowCount + 1} to{" "}
                    {(page - 1) * rowCount +
                      (articleResult && articleResult.articles
                        ? articleResult.articles.length
                        : 0)}{" "}
                    of{" "}
                    {articleResult.totalArticles
                      ? articleResult.totalArticles
                      : 0}{" "}
                    articles
                  </div>
                  {statusPopup ? (
                    <Dialog
                      open={statusPopup}
                      onClose={closeStatusPopup}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {`Do you want to ${
                          action === "published" ? "publish" : "draft"
                        } this article?`}
                      </DialogTitle>
                      <DialogActions>
                        <Button
                          variant="text"
                          onClick={(e) => handleClose(e, "close")}
                          id="close"
                          color="secondary"
                        >
                          Close
                        </Button>
                        <Button
                          variant="text"
                          id="approve"
                          onClick={(e) => handleClose(e, action)}
                          color="primary"
                        >
                          {action === "published" ? "Publish" : "Draft"}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  ) : null}
                  <div className="root">
                    <Pagination
                      count={
                        articleResult && articleResult.totalArticles
                          ? Math.ceil(articleResult.totalArticles / rowCount)
                          : 0
                      }
                      page={page}
                      onChange={handlePages}
                      variant="outlined"
                    />
                  </div>
                </Paper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

Article.propTypes = {
  fetchAllArticles: propTypes.func,
  editArticleStatus: propTypes.func,
  articleResult: propTypes.object,
  editArticleResult: propTypes.object,
};

const mapStateToProps = (state) => {
  return {
    articleResult: state.fetchArticleReducer.data || {},
    editArticleResult: state.createArticleReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllArticles: () => dispatch(fetchArticles(data)),
    editArticleStatus: (data) => dispatch(createArticle(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Article);
