import React, { useRef } from "react";
import "../../assets/css/dore.light.bluenavy.min.css";
import PropTypes from "prop-types";
const HeaderPopup = (props) => {
  const wrapperRef = useRef(null);
  props.alert(wrapperRef);
  return (
    <div
      className="dropdown-menu dropdown-menu-right mt-3 drop-down-position  position-absolute"
      id="iconMenuDropdown"
      ref={wrapperRef}
    >
      <a href="#" className="icon-menu-item">
        <i className="iconsminds-equalizer d-block"></i>
        <span>Settings</span>
      </a>

      <a href="#" className="icon-menu-item">
        <i className="iconsminds-male-female d-block"></i>
        <span>Users</span>
      </a>

      <a href="#" className="icon-menu-item">
        <i className="iconsminds-puzzle d-block"></i>
        <span>Components</span>
      </a>

      <a href="#" className="icon-menu-item">
        <i className="iconsminds-bar-chart-4 d-block"></i>
        <span>Profits</span>
      </a>

      <a href="#" className="icon-menu-item">
        <i className="iconsminds-file d-block"></i>
        <span>Surveys</span>
      </a>

      <a href="#" className="icon-menu-item">
        <i className="iconsminds-suitcase d-block"></i>
        <span>Tasks</span>
      </a>
    </div>
  );
};
HeaderPopup.propTypes = {
  alert: PropTypes.func.isRequired,
};
export default HeaderPopup;
