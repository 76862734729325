const initialState = {
    loading: true,
    data: {},
    error: "",
};

const gameReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_GAME_REQUEST":
            return {
                ...state,
                loading: true
            }
        case "FETCH_GAME_SUCCESS":
            return {
                loading: false,
                data: action.payload,
                error: ""
            }
        case "FETCH_GAME_FAILURE":
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }

}

const createGameReducer = (state = initialState, action) => {
    switch (action.type) {
        case "CREATE_GAME_REQUEST":
            return {
                ...state,
                loading: true
            }
        case "CREATE_GAME_SUCCESS":
            return {
                loading: false,
                data: action.payload,
                error: ""
            }
        case "CREATE_GAME_FAILURE":
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }

}

export { gameReducer, createGameReducer };
