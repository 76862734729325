import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ProfilePopup from "../popup/profile-popup";
import HeaderPopup from "../popup/header-popup";
import "./header-component.css";
const Header = (props) => {
  const [dropdown, setDropDown] = useState(false);
  const [profile, setProfile] = useState(false);
  function handleClick(e) {
    e.preventDefault();
    setProfile(false);
    setDropDown(!dropdown ? true : false);
  }
  function profileClick(e) {
    e.preventDefault();
    setDropDown(false);
    setProfile(!profile ? true : false);
  }
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          dropdown && setDropDown(false);
          profile && setProfile(false);
        }
      };

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  return (
    <React.Fragment>
      <nav className="navbar fixed-top">
        <div className="d-flex align-items-center navbar-left">
          <Link
            exact
            to="#"
            onClick={props.handleMenu}
            className="menu-button d-none d-md-block cursor-pointer"
          >
            <svg
              className="main"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 9 17"
            >
              <rect x="0.48" y="0.5" width="7" height="1" />
              <rect x="0.48" y="7.5" width="7" height="1" />
              <rect x="0.48" y="15.5" width="7" height="1" />
            </svg>
            <svg
              className="sub"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 18 17"
            >
              <rect x="1.56" y="0.5" width="16" height="1" />
              <rect x="1.56" y="7.5" width="16" height="1" />
              <rect x="1.56" y="15.5" width="16" height="1" />
            </svg>
          </Link>

          <Link
            exact
            to="#"
            className="menu-button-mobile d-xs-block d-sm-block d-md-none"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
              <rect x="0.5" y="0.5" width="25" height="1" />
              <rect x="0.5" y="7.5" width="25" height="1" />
              <rect x="0.5" y="15.5" width="25" height="1" />
            </svg>
          </Link>
        </div>
        <div  className="navbar-logo">
          <img src="/images/logo.png" className="logo-size" />
        </div>

        <div className="navbar-right">
          <div className="header-icons d-inline-block align-middle">
            <div className="position-relative d-none d-sm-inline-block">
              <button
                className="header-icon btn btn-empty"
                type="button"
                id="iconMenuButton"
                onClick={(e) => handleClick(e)}
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="simple-icon-grid"></i>
              </button>
              {dropdown ? <HeaderPopup alert={useOutsideAlerter} /> : null}
            </div>
          </div>

          <div className="user d-inline-block">
            <button
              className="btn btn-empty p-0"
              type="button"
              onClick={(e) => profileClick(e)}
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="name">Lead-IAS-User</span>
              <span>
                <img src="/images/profile-picture.jpg" alt="profile-picture" />
              </span>
            </button>
            {profile ? <ProfilePopup alert={useOutsideAlerter} /> : null}
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
};

Header.propTypes = {
  handleMenu: PropTypes.func.isRequired,
};

export default Header;
