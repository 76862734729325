export const FETCH_PRELIM_REQUEST = "FETCH_PRELIM_REQUEST";
export const FETCH_PRELIM_SUCCESS = "FETCH_PRELIM_SUCCESS";
export const FETCH_PRELIM_FAILURE = "FETCH_PRELIM_FAILURE";

export const UPDATE_PRELIM_STATUS_REQUEST = "UPDATE_PRELIM_STATUS_REQUEST";
export const UPDATE_PRELIM_STATUS_SUCCESS = "UPDATE_PRELIM_STATUS_SUCCESS";
export const UPDATE_PRELIM_STATUS_FAILURE = "UPDATE_PRELIM_STATUS_FAILURE";

export const CREATE_PRELIM_REQUEST = "CREATE_PRELIM_REQUEST";
export const CREATE_PRELIM_SUCCESS = "CREATE_PRELIM_SUCCESS";
export const CREATE_PRELIM_FAILURE = "CREATE_PRELIM_FAILURE";

export const EDIT_PRELIM_REQUEST = "EDIT_PRELIM_REQUEST";
export const EDIT_PRELIM_SUCCESS = "EDIT_PRELIM_SUCCESS";
export const EDIT_PRELIM_FAILURE = "EDIT_PRELIM_FAILURE";

export const FETCH_PRELIM_TEST_REQUEST = "FETCH_PRELIM_TEST_REQUEST";
export const FETCH_PRELIM_TEST_SUCCESS = "FETCH_PRELIM_TEST_SUCCESS";
export const FETCH_PRELIM_TEST_FAILURE = "FETCH_PRELIM_TEST_FAILURE";

export const ADD_PRELIM_TEST_REQUEST = "ADD_PRELIM_TEST_REQUEST";
export const ADD_PRELIM_TEST_SUCCESS = "ADD_PRELIM_TEST_SUCCESS";
export const ADD_PRELIM_TEST_FAILURE = "ADD_PRELIM_TEST_FAILURE";

export const FETCH_PURCHASED_USERS_REQUEST = "FETCH_PURCHASED_USERS_REQUEST";
export const FETCH_PURCHASED_USERS_SUCCESS = "FETCH_PURCHASED_USERS_SUCCESS";
export const FETCH_PURCHASED_USERS_FAILURE = "FETCH_PURCHASED_USERS_FAILURE";
