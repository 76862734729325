import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import PostAddIcon from "@material-ui/icons/PostAdd";
import Pagination from "@material-ui/lab/Pagination";
import { fetchMainsQuestions } from "../../redux/questions/questions-action";
import propTypes from "prop-types";
import { connect, useSelector, useDispatch } from 'react-redux';
import { Capitalize } from "../../util/helper";
import CreateMainsQuestion from "./add-mains-question";
import EditIcon from "@material-ui/icons/Edit";
import MainsStatusPopup from "../../components/popup/update-question-status";
import { RESET_COURSE_TOPIC } from "../../redux/course/course-types"
import AnswerUploadPopup from "../../components/popup/add-sample-answer";

let data = {
    skip: 0,
    limit: 10,
    keyword: "",
};

const MainsQuestions = ({ fetchQuestions, questionsResult }) => {
    const [page, setPage] = useState(1);
    const [rowCount, setRowCount] = useState(10);
    const [questionModal, setModal] = useState(false);
    const [editData, setEditData] = useState({});
    const [edit, setEdit] = useState(false);
    const [show, setShow] = useState(false);
    const [showPopUp, setShowPopUp] = useState(false);
    const [row, setRow] = useState(null);
    const [action, setAction] = useState("");
    const createQuestion = useSelector(state => state.createQuestionReducer);
    const updateQuestionStatus = useSelector(state => state.statusQuestionReducer);
    const addSampleAnswer = useSelector(state => state.addSampleAnswerReducer);
    const dispatch = useDispatch();
    useEffect(() => {
        fetchQuestions(data);
    }, [createQuestion, updateQuestionStatus, addSampleAnswer]); // eslint-disable-line react-hooks/exhaustive-deps
    const handleRowsPerPage = (event) => {
        setRowCount(event.target.value);
        setPage(1);
        data.limit = event.target.value;
        data.skip = 0;
        fetchQuestions(data);
    };
    const searchQuestion = (event) => {
        data.skip = 0;
        data.keyword = event.target.value;
        setPage(1);
        fetchQuestions(data);
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
        data.skip = (newPage - 1) * rowCount;
        fetchQuestions(data);
    };
    const handleEditQuestion = (row) => {
        setEdit(true);
        setEditData(row);
        setModal(true);
    };
    const createQuestionModal = () => {
        setEdit(false);
        setModal(true);
    };
    const closeQuestionHandler = () => {
        dispatch({ type: RESET_COURSE_TOPIC })
        setModal(false);
    }
    const handlePopup = (e, row, action) => {
        setRow(row);
        setAction(action);
        setShow(true);
    };
    const handleClose = () => {
        setShow(false)
    }
    const handlePopUpClose = () => {
        setShowPopUp(false)
    }
    const handleOpenPopup = (e, row) => {
        setRow(row)
        setShowPopUp(true)
    }
    return (
        <main>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <h1>Mains Questions</h1>
                        <nav
                            className="breadcrumb-container d-none d-sm-block d-lg-inline-block"
                            aria-label="breadcrumb"
                        >
                            <ol className="breadcrumb pt-0">
                                <li className="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                            </ol>
                        </nav>
                        <div className="separator mb-5"></div>

                        <div className="file-upload mb-3 ml-2">
                            <button
                                className="btn btn-outline-primary"
                                type="button"
                                onClick={createQuestionModal}
                            >
                                CREATE QUESTION
              </button>
                            {questionModal ? (
                                <CreateMainsQuestion
                                    open={questionModal}
                                    edit={edit}
                                    data={editData}
                                    onClose={closeQuestionHandler}
                                />
                            ) : null}
                        </div>
                        <div className="tableBody">
                            <div className="table-filter-div">
                                <Grid item xs={6}>
                                    <div className="search-div">
                                        <input
                                            type="text"
                                            className="search"
                                            placeholder="Search question.."
                                            onKeyUp={searchQuestion}
                                        ></input>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="rowCount-div">
                                        <label>Questions per page : </label>
                                        <select className="selection" onChange={handleRowsPerPage}>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </Grid>
                            </div>
                            <Paper className="paperBody">
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="tableHead" width="50px" align="center">
                                                    Sl.No
                                                </TableCell>
                                                <TableCell className="tableHead" width="600px">Question</TableCell>
                                                <TableCell className="tableHead" width="200px">Subject Name</TableCell>
                                                <TableCell className="tableHead" width="200px">Topic Name</TableCell>
                                                <TableCell className="tableHead" width="100px">Status</TableCell>
                                                <TableCell className="tableHead" width="250px">Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {questionsResult &&
                                                questionsResult.questions &&
                                                questionsResult.questions.map((row, index) => {
                                                    return (
                                                        <TableRow hover key={row._id}>
                                                            <TableCell
                                                                className="tableColData"
                                                                align="center"
                                                            >
                                                                {rowCount * (page - 1) + index + 1}
                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                {row.title}
                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                {row?.subject?.name}
                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                {row?.topic?.name}
                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                {Capitalize(row.status)}
                                                            </TableCell>
                                                            <TableCell className="tableColData">
                                                                <EditIcon
                                                                    aria-label="edit"
                                                                    titleAccess="Edit"
                                                                    fontSize="small"
                                                                    data={row}
                                                                    edit="true"
                                                                    onClick={() => handleEditQuestion(row)}
                                                                />
                                                                {row.status === "draft" ? (
                                                                    <DoneIcon
                                                                        className="publish text-success"
                                                                        fontSize="small"
                                                                        titleAccess="Publish"
                                                                        onClick={(e) => handlePopup(e, row, "published")}
                                                                    />
                                                                ) : null}
                                                                {row.status === "published" ? (
                                                                    <CloseIcon
                                                                        className="draft text-danger"
                                                                        fontSize="small"
                                                                        titleAccess="Draft"
                                                                        onClick={(e) => handlePopup(e, row, "draft")}
                                                                    />
                                                                ) : null}
                                                                <PostAddIcon
                                                                    fontSize="small"
                                                                    color="primary"
                                                                    titleAccess="Sample Answer"
                                                                    onClick={(e) => handleOpenPopup(e, row)}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                }
                                                )}
                                            {show ? (
                                                <MainsStatusPopup
                                                    open={show}
                                                    onClose={handleClose}
                                                    row={row}
                                                    action={action}
                                                />
                                            ) : null}
                                            {
                                                showPopUp ? (
                                                    <AnswerUploadPopup
                                                        open={showPopUp}
                                                        onClose={handlePopUpClose}
                                                        row={row}
                                                    />
                                                ) : null
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <div className="paginationLabel">
                                    Showing {(page - 1) * rowCount + 1} to{" "}
                                    {(page - 1) * rowCount +
                                        (questionsResult && questionsResult.questions
                                            ? questionsResult.questions.length
                                            : 0)}{" "}
                  of{" "}
                                    {questionsResult.totalQuestions
                                        ? questionsResult.totalQuestions
                                        : 0}{" "}
                  entries
                </div>
                                <div className="root">
                                    <Pagination
                                        count={
                                            data && questionsResult.totalQuestions
                                                ? Math.ceil(questionsResult.totalQuestions / rowCount)
                                                : 0
                                        }
                                        page={page}
                                        onChange={handlePageChange}
                                        variant="outlined"
                                    />
                                </div>
                            </Paper>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

MainsQuestions.propTypes = {
    fetchQuestions: propTypes.func,
    questionsResult: propTypes.object,
};
const mapStateToProps = (state) => {
    return {
        questionsResult: state.fetchMainsQuestionReducer.data
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchQuestions: (data) => dispatch(fetchMainsQuestions(data)),

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(MainsQuestions);